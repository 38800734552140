import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import PrivateRoute from '../helper/PrivateRoute';

import LandingPage from '../landing/components/Landing';
import SignIn from '../auth/SignIn';
import Root from './Root';
import UnAuthorized from './UnAuthorized';
import { Provider,useDispatch} from 'react-redux';
import { store } from '../configure-store';

import {getVenuesWithAreas} from '../actions/venuesActions'
import { getSelectedOrg } from '../firebase/auth';

import AppPrivacyPolicy from './AppPrivacyPolicy';
import { createTheme, ThemeProvider, experimental_sx as sx } from '@mui/material/styles';

import * as routes from '../constants/routes';



const theme = createTheme({
	components: {
		// Name of the component
		// MuiOutlinedInput: {
		// 	styleOverrides: {
		// 		input: {
		// 			padding: '9px'
		// 		}
		// 	}
		// }
		// MuiButton: {
		// 	styleOverrides: {
		// 		root: {
		// 			color: 'white'
		// 		}
		// 	}
		// }
		MuiButton: {
			defaultProps: {
				size: 'small'
			}
		},
		MuiFilledInput: {
			defaultProps: {
				margin: 'dense'
			}
		},
		MuiFormControl: {
			defaultProps: {
				margin: 'dense'
			}
		},
		MuiFormHelperText: {
			defaultProps: {
				margin: 'dense'
			}
		},
		MuiIconButton: {
			defaultProps: {
				size: 'small'
			}
		},
		MuiInputBase: {
			defaultProps: {
				margin: 'dense'
			}
		},
		MuiInputLabel: {
			defaultProps: {
				margin: 'dense'
			}
		},
		MuiListItem: {
			defaultProps: {
				dense: true
			}
		},
		MuiOutlinedInput: {
			defaultProps: {
				margin: 'dense'
			}
		},
		MuiFab: {
			defaultProps: {
				size: 'small'
			}
		},
		MuiTable: {
			defaultProps: {
				size: 'small'
			}
		},
		MuiTextField: {
			defaultProps: {
				margin: 'dense'
			}
		}
		// MuiToolbar: {
		// 	defaultProps: {
		// 		variant: 'dense'
		// 	}
		// }
	},
	palette: {
		primary: {
			main: '#00ADEF'
		},
		secondary: {
			main: '#e91e63'
		},
		background: {
			default: '#F9FAFC'
		}
	}
});

store.dispatch(getVenuesWithAreas(getSelectedOrg()))


const App = () => (
	<ThemeProvider theme={theme}>
		<Provider store={store}>
			<Router>
				<Switch>
					<Route exact path={routes.SIGNIN} component={SignIn} />
					<Route exact path={routes.APPPRIVACYPOLICY} component={AppPrivacyPolicy} />
					<Route exact path={routes.UNAUTHORIZED} component={UnAuthorized} />
					<PrivateRoute path={'/'} component={Root} />
				</Switch>
			</Router>
		</Provider>
	</ThemeProvider>
);

export default App;
