import React, { forwardRef } from 'react';
import MaterialTable from '@material-table/core';
import AddBox from '@mui/icons-material/AddBox';
import ArrowUpward from '@mui/icons-material/ArrowUpward';
import Check from '@mui/icons-material/Check';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';
import Clear from '@mui/icons-material/Clear';
import DeleteOutlined from '@mui/icons-material/DeleteOutline';
import Edit from '@mui/icons-material/Edit';
import Delete from '@mui/icons-material/Delete';
import FilterList from '@mui/icons-material/FilterList';
import FirstPage from '@mui/icons-material/FirstPage';
import LastPage from '@mui/icons-material/LastPage';
import Remove from '@mui/icons-material/Remove';
import SaveAlt from '@mui/icons-material/SaveAlt';
import Search from '@mui/icons-material/Search';
import QrCodeIcon from '@mui/icons-material/QrCode';
import ViewColumn from '@mui/icons-material/ViewColumn';

import { useDispatch } from 'react-redux'
import { deleteAreasOnVenueForOrg } from '../../../actions/venuesActions'
import { getSelectedOrg } from '../../../firebase/auth';

import UUID from 'uuid/v4';

import { Typography, Grid, Button, Tooltip } from '@mui/material';

import { exportGeneratedXLSX } from './ExportTemplate';
import { parseUploadedTemplateFIle } from './ParseUploadedTemplate';

const tableIcons = {
	Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
	Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
	Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
	Delete: forwardRef((props, ref) => <Delete {...props} ref={ref} />),
	DeleteOutlined: forwardRef((props, ref) => <DeleteOutlined {...props} ref={ref} />),
	DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
	Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
	Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
	Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
	FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
	LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
	NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
	PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
	ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
	Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
	SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref} />),
	ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
	ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

function getCategoriesIdAndName(categories) {
	var temp = {};
	Object.keys(categories).map((key) => (temp[key] = categories[key].name));
	return temp;
}

export default function EditableAreasTableList({ venue,areasToEdit,setAreasToEdit, setDataChanged }) {
	const dispatch = useDispatch()
	const [ columns ] = React.useState([
		{
			field: 'qrIdList',
			title: 'QR kódok',
			filtering: false,
			editable: false,
			render: (rowData) =>
				rowData.qrIdList &&
				rowData.qrIdList.length > 0 && (
					<Tooltip title={rowData.qrIdList.join(', ')} followCursor>
						<div>
							<QrCodeIcon /> {rowData.qrIdList.length}x
						</div>
					</Tooltip>
				)
		},
		{ title: 'Épület', field: 'building' },
		{ title: 'Szint', field: 'floor', type: 'numeric' },
		{ title: 'Terület neve*', field: 'name', validate: (rowData) => Boolean(rowData.name) },
		{ title: 'Száma', field: 'number' },
		{
			title: 'Kategória',
			field: 'category',
			lookup: getCategoriesIdAndName(venue.categories)
		},
		{ title: 'Méret (m2)', field: 'size', type: 'numeric', filtering: false }
	]);
	const [ data, setData ] = React.useState([]);
	const [ venueId, setVenueId ] = React.useState('');
	const [ selectedFile, setSelectedFile ] = React.useState();
	const [ isFilePicked, setIsFilePicked ] = React.useState(false);

	const changeHandler = (event) => {
		if (event.target.files[0] != null) {
			setSelectedFile(event.target.files[0]);
			setIsFilePicked(true);
		} else {
			setIsFilePicked(false);
		}
		setSelectedFile(event.target.files[0]);
		setIsFilePicked(true);
	};

	const handleSubmission = () => {
		parseUploadedTemplateFIle(selectedFile, venue.categories).then((result) => {
			result.map((element) => {
				areasToEdit[element.id] = element;
			});
			setData([ ...data ].concat(result));
			setDataChanged(true);
		});
	};

	const handleDownloadExcelTemplate = (event) => {
		exportGeneratedXLSX(venue);
	};

	const updateAreasOnVenue = (newData, oldData) => {
		return new Promise((resolve) => {
			const dataUpdate = [ ...data ];
			const index = oldData.tableData.id;
			dataUpdate[index] = newData;
			setData([ ...dataUpdate ]);
			 let key = newData.key;
			// delete newData.key
			delete newData.tableData;
			areasToEdit[key] = newData;
			setAreasToEdit(areasToEdit);
			setDataChanged(true);
			resolve();
		});
	};

	const addAreaOnVenue = (area) => {
		return new Promise((resolve) => {
			// setTimeout(() => {
			resolve();
			let key = UUID();
			areasToEdit[key] = area;
			const areas = {};
			areas[key] = { ...area };
			area.key = key;
			setData([ ...data, area ]);
			setDataChanged(true);
		});
	};

	const handleDeleteRows = (evt, rowData) => {
		return new Promise((resolve) => {
			var dataToDelete = [];
			let _data = [ ...data ];
			rowData.forEach((rd) => {
				_data = _data.filter((t) => t.id !== rd.tableData.id);
				if (rd.key != null) {
					delete areasToEdit[rd.key];
					dataToDelete.push(rd.key);
				}
			});
			if (dataToDelete.length > 0) {
				dispatch(deleteAreasOnVenueForOrg(getSelectedOrg(),venue.id,dataToDelete))
			}
			setData(_data);
			
			resolve();
		});
	};

	const generateDataFromVenue = () => {
		if (venueId != venue.id || (data.length < 1 && Object.keys(areasToEdit).length > 0)) {
			var rows = [];
			var index = 0;
			Object.keys(areasToEdit).forEach(function(key) {
				let area = areasToEdit[key];
				rows.push({
					building: area.building,
					name: area.name,
					floor: area.floor,
					number: area.number,
					qrIdList: area.qrId_list,
					category: area.category,
					size: area.size,
					id: index,
					key: key
				});
				index++;
			});
			setVenueId(venue.id);
			setData(rows);
		}
	};

	return (
		<Grid container spacing={1}>
			{generateDataFromVenue()}
			<Grid item xs={12}>
				<MaterialTable
					icons={tableIcons}
					title="Termek"
					columns={columns}
					data={data}
					options={{
						paging: false,
						filtering: true,
						addRowPosition: 'first',
						selection: true,
						padding: 'dense',
						grouping: true
					}}
					editable={{
						onRowAdd: (newData) => addAreaOnVenue(newData),
						onRowUpdate: (newData, oldData) => updateAreasOnVenue(newData, oldData)
					}}
					actions={[
						{
							icon: () => <Delete />,
							tooltip: 'Törlés',
							onClick: (evt, data) => handleDeleteRows(evt, data)
						}
					]}
				/>
			</Grid>
			<Grid item xs={12}>
				<Typography variant="h6" color="secondary">
					Lehetőség van területek feltöltésére egy excel sablon segítségével. Ehhez kattintson az "Excel
					Sablon letöltése" gombra, az utasítások alapján töltse ki a sablont, majd töltse fel és kattintson a
					"Sablon feltöltése" gombra.
				</Typography>
			</Grid>
			<Grid item xs={12}>
				<Button color="primary" onClick={handleDownloadExcelTemplate}>
					Excel sablon letöltése
				</Button>
			</Grid>
			<Grid item xs={12}>
				<input type="file" name="file" onChange={changeHandler} />
				{isFilePicked && (
					<Button onClick={handleSubmission} color="primary">
						Sablon feltöltése
					</Button>
				)}
			</Grid>
		</Grid>
	);
}
