/* eslint-disable no-console */

import React from 'react';
import { Chart } from 'react-google-charts';

export default function ReportsStackedChart({ data }) {
	// const rows = createRows(input.data);

	function getOptions(columns) {
		var options = {
			isStacked: true,
			legend: 'bottom',
			title: 'Riportok osszesites',
			hAxis: {
				title: 'Datum'
			},
			vAxis: { title: '%', viewWindow: { min: 0, max: 100 } },
			series: {}
		};
		return options;
	}

	return (
		<Chart
			width="100%"
			height={300}
			chartType="ColumnChart"
			loader={<div>Loading Chart</div>}
			columns={data.columns}
			rows={data.rows}
			options={getOptions(data.columns)}
			legendToggle
		/>
	);
}
