import React, {useEffect} from 'react';

import { Line } from 'react-chartjs-2';

import { Chart, registerables } from 'chart.js';

import { Box, Button, Card, CardContent, CardHeader, Divider, useTheme } from '@mui/material';


Chart.register(...registerables);


export const WorklogsTrend = ({weeklyMetricsLast7Weeks}) => {
  const theme = useTheme();
  var isMounted = true;
	const [ data, setData ] = React.useState({});

  useEffect(() => {
		if (weeklyMetricsLast7Weeks != null && data.datasets == null)  parseMetrics();
		return () => {
			isMounted = false;
		};
  });

  const parseMetrics = () => {
    var datasets = [];
    var labels = [];
    Object.keys(weeklyMetricsLast7Weeks).map(key => {
      var data = [];
      labels = Object.keys(weeklyMetricsLast7Weeks[key])
      Object.values(weeklyMetricsLast7Weeks[key]).map(element=>{
          data.push(element.worklogs.worklogsCount)
        })
      datasets.push({label:key,data:data,borderColor: dynamicColors()});
    })
    setData({datasets:datasets,labels:labels})
    console.log(weeklyMetricsLast7Weeks)
  }

  const dynamicColors = () => {
    var r = Math.floor(Math.random() * 255);
    var g = Math.floor(Math.random() * 255);
    var b = Math.floor(Math.random() * 255);
    return "rgb(" + r + "," + g + "," + b + ")";
};



  
  const options = {
    animation: false,
    cornerRadius: 20,
    layout: { padding: 0 },
    legend: { display: false },
    maintainAspectRatio: false,
    responsive: true,
    xAxes: [
      {
        ticks: {
          fontColor: theme.palette.text.secondary
        },
        gridLines: {
          display: false,
          drawBorder: false
        }
      }
    ],
    yAxes: [
      {
        ticks: {
          fontColor: theme.palette.text.secondary,
          beginAtZero: true,
          min: 0
        },
        gridLines: {
          borderDash: [2],
          borderDashOffset: [2],
          color: theme.palette.divider,
          drawBorder: false,
          zeroLineBorderDash: [2],
          zeroLineBorderDashOffset: [2],
          zeroLineColor: theme.palette.divider
        }
      }
    ],
    tooltips: {
      backgroundColor: theme.palette.background.paper,
      bodyFontColor: theme.palette.text.secondary,
      borderColor: theme.palette.divider,
      borderWidth: 1,
      enabled: true,
      footerFontColor: theme.palette.text.secondary,
      intersect: false,
      mode: 'index',
      titleFontColor: theme.palette.text.primary
    }
  };

  return (
    <Card>
      <CardHeader
        title="Naplóbejegyzések hétről hétre"
      />
      <Divider />
      <CardContent>
        <Box
          sx={{
            height: 250,
            position: 'relative'
          }}
        >
          {data.datasets != null && <Line
            data={data}
            options={options}
          />}
        </Box>
      </CardContent>
    </Card>
  );
};
