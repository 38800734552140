
export default function (state = false, action) {
    switch (action.type) {
      case "TURN-ON-PROGRESS-BAR":
        return true;
      case "TURN-OFF-PROGRESS-BAR":
        return false;
      default:
        return state;
    }
  };