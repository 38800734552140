import React from 'react';

import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import Navigation from './Navigation';
import PrivateRoute from '../helper/PrivateRoute';

import HomePage from './Home';
import Customer from './Customer';
import Report from '../reports/components/ReportViewDialog';
import AdminReportsOnVenue from '../reports/admin/AdminReportsOnVenue';
import ManageAdHocTasksOnVenue from '../ad-hoc tasks/ManageAdHocTasksOnVenue';
import AdHocTasks from '../ad-hoc tasks/AdHocTasks';
import Reports from '../reports/Reports';
import CustomerReportsOnVenue from '../reports/customer/CustomerReportsOnVenue';
import Templates from '../templates/components/Templates';
import WorkLogs from '../worklogs/WorkLogs';
import Venues from '../venues/components/Venues';
import MasterDataManager from '../masterdata/MasterDataManager';
import Venue from '../venues/components/Venue';
import AccountPage from './Account';
import * as routes from '../constants/routes';
import WorkLogsOnVenue from '../worklogs/WorkLogsOnVenue';

export default class Root extends React.Component {
	render() {
		return (
			<Navigation>
				<Switch>
					{/* Home */}
					<PrivateRoute
						exact
						path='/'
						component={HomePage}
						roles={[ 'admin', 'agent', 'customer' ]}
					/>
					<PrivateRoute
						exact
						path={routes.HOME}
						component={HomePage}
						roles={[ 'admin', 'agent', 'customer' ]}
					/>
					{/* Reports */}
					<PrivateRoute exact path={routes.REPORTS} component={Reports} roles={[ 'admin', 'agent' ]} />
					<PrivateRoute
						exact
						path={`${routes.REPORTS}/:venue`}
						component={AdminReportsOnVenue}
						roles={[ 'admin', 'agent' ]}
					/>
					{/* Ad-hoc tasks */}
					<PrivateRoute exact path={routes.ADHOCTASKS} component={AdHocTasks} roles={[ 'admin', 'agent', 'customer' ]} />
					<PrivateRoute
						exact
						path={`${routes.ADHOCTASKS}/:venue`}
						component={ManageAdHocTasksOnVenue}
						roles={[ 'admin', 'agent', 'customer' ]}
					/>
					{/* Customer Reports */}
					<PrivateRoute
						exact
						path={routes.CUSTOMERREPORTS}
						component={Reports}
						roles={[ 'admin', 'agent', 'customer' ]}
					/>
					<PrivateRoute
						exact
						path={`${routes.CUSTOMERREPORTS}/:venue`}
						component={CustomerReportsOnVenue}
						roles={[ 'admin', 'agent', 'customer' ]}
					/>
					{/*Customer Report */}
					<PrivateRoute
						exact
						path={`${routes.CUSTOMERREPORTS}/:venue/report/:reportId`}
						component={Report}
						roles={[ 'admin', 'agent', 'customer' ]}
					/>
					{/*Report */}
					<PrivateRoute
						exact
						path={`${routes.REPORTS}/:venue/report/:reportId`}
						component={Report}
						roles={[ 'admin', 'agent' ]}
					/>
					{/* Templates */}
					<PrivateRoute exact path={routes.TEMPLATES} component={Templates} roles={[ 'admin' ]} />
					{/* Venues */}
					<PrivateRoute exact path={routes.VENUES} component={Venues} roles={[ 'admin' ]} />
					<PrivateRoute exact path={routes.VENUE} component={Venue} roles={[ 'admin' ]} />

					{/* Master Data*/}
					<PrivateRoute exact path={routes.MASTERDATA} component={MasterDataManager} roles={[ 'admin' ]} />

					{/* WorkLogs */}
					<PrivateRoute exact path={routes.WORKLOGS} component={WorkLogs} roles={[ 'admin', 'agent', 'customer' ]} />
					<PrivateRoute
						exact
						path={`${routes.WORKLOGS}/:venue`}
						component={WorkLogsOnVenue}
						roles={[ 'admin', 'agent','customer' ]}
					/>
					{/* Account */}
					<PrivateRoute exact path={routes.ACCOUNT} component={AccountPage} roles={[ 'admin', 'agent' ]} />
					{/* Customer */}
					<PrivateRoute
						exact
						path={routes.CUSTOMER}
						component={Customer}
						roles={[ 'admin', 'agent', 'customer' ]}
					/>
				</Switch>
			</Navigation>
		);
	}
}
