import React from 'react';
import EditableAreasTableList from './EditableAreasTableList';
import SaveIcon from '@mui/icons-material/Save';
import MuiAlert from '@mui/material/Alert';
import {  useDispatch } from 'react-redux'
import { updateOrCreateAreasOnOrgAndVenue } from '../../../actions/venuesActions'
import { getSelectedOrg } from '../../../firebase/auth';


import { useLocation, useHistory } from 'react-router-dom';

import {
	Typography,
	Grid,
	Button,
} from '@mui/material';

const Alert = React.forwardRef(function Alert(props, ref) {
	return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function ManageAreas({ venue }) {
	const [ dataChanged, setDataChanged ] = React.useState(false);
	const dispatch = useDispatch()
	const [areasToEdit,setAreasToEdit] = React.useState({...venue.areas});
	const location = useLocation();
	const history = useHistory();

	const updateAreasOnVenue = () => {

		dispatch(updateOrCreateAreasOnOrgAndVenue(getSelectedOrg(),venue.id,areasToEdit))
		setDataChanged(false);

	};

	return (
		<Grid container spacing={1}>
			{dataChanged ? (
				<Grid item xs={12}>
					<Grid container spacing={1} direction="row" alignItems="center">
						<Grid item>
							<Typography variant="h6" color="secondary">
								Módosítások mentéséhez kattints a mentés gombra!
							</Typography>
						</Grid>
						<Grid item>
							<Button
								startIcon={<SaveIcon />}
								size="large"
								variant="contained"
								disabled={!dataChanged}
								color="primary"
								onClick={updateAreasOnVenue}
							>
								Mentés
							</Button>
						</Grid>
					</Grid>
				</Grid>
			) : (
				''
			)}
			<Grid item xs={12}>
				<EditableAreasTableList
					venue={venue}
					areasToEdit={areasToEdit}
					setAreasToEdit={setAreasToEdit}
					setDataChanged={setDataChanged}
				/>
			</Grid>

			<Grid item xs={12} />
			
		</Grid>
	);
}

export default ManageAreas;
