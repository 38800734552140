import React from 'react';
import styled from 'styled-components';
import moment from 'moment';

import { useTable, useSortBy, useFilters } from 'react-table';

import matchSorter from 'match-sorter';

const Styles = styled.div`
	padding: 1rem;
	table {
		border-spacing: 0;
		border: 1px solid black;
		tr {
			:last-child {
				td {
					border-bottom: 0;
				}
			}
		}
		th,
		td {
			margin: 0;
			padding: 0.5rem;
			border-bottom: 1px solid black;
			border-right: 1px solid black;
			:last-child {
				border-right: 0;
			}
		}
	}
`;

// Define a default UI for filtering
function DefaultColumnFilter({ column: { filterValue, preFilteredRows, setFilter } }) {
	const count = preFilteredRows.length;

	return (
		<input
			value={filterValue || ''}
			onChange={(e) => {
				setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
			}}
			placeholder={`Search ${count} records...`}
		/>
	);
}

// This is a custom filter UI for selecting
// a unique option from a list
function SelectColumnFilter({ column: { filterValue, setFilter, preFilteredRows, id } }) {
	// Calculate the options for filtering
	// using the preFilteredRows
	const options = React.useMemo(
		() => {
			const options = new Set();
			preFilteredRows.forEach((row) => {
				options.add(row.values[id]);
			});
			return [ ...options.values() ];
		},
		[ id, preFilteredRows ]
	);

	// Render a multi-select box
	return (
		<select
			value={filterValue}
			onChange={(e) => {
				setFilter(e.target.value || undefined);
			}}
		>
			<option value="">All</option>
			{options.map((option, i) => (
				<option key={i} value={option}>
					{option}
				</option>
			))}
		</select>
	);
}

// This is a custom filter UI that uses a
// slider to set the filter value between a column's
// min and max values
function SliderColumnFilter({ column: { filterValue, setFilter, preFilteredRows, id } }) {
	// Calculate the min and max
	// using the preFilteredRows

	const [ min, max ] = React.useMemo(
		() => {
			let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
			let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
			preFilteredRows.forEach((row) => {
				min = Math.min(row.values[id], min);
				max = Math.max(row.values[id], max);
			});
			return [ min, max ];
		},
		[ id, preFilteredRows ]
	);

	return (
		<div>
			<input
				type="range"
				min={min}
				max={max}
				value={filterValue || min}
				onChange={(e) => {
					setFilter(parseInt(e.target.value, 10));
				}}
			/>
			<button onClick={() => setFilter(undefined)}>Off</button>
		</div>
	);
}

// This is a custom UI for our 'between' or number range
// filter. It uses two number boxes and filters rows to
// ones that have values between the two
function NumberRangeColumnFilter({ column: { filterValue = [], preFilteredRows, setFilter, id } }) {
	const [ min, max ] = React.useMemo(
		() => {
			let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
			let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
			preFilteredRows.forEach((row) => {
				min = Math.min(row.values[id], min);
				max = Math.max(row.values[id], max);
			});
			return [ min, max ];
		},
		[ id, preFilteredRows ]
	);

	return (
		<div
			style={{
				display: 'flex'
			}}
		>
			<input
				value={filterValue[0] || ''}
				type="number"
				onChange={(e) => {
					const val = e.target.value;
					setFilter((old = []) => [ val ? parseInt(val, 10) : undefined, old[1] ]);
				}}
				placeholder={`Min (${min})`}
				style={{
					width: '70px',
					marginRight: '0.5rem'
				}}
			/>
			to
			<input
				value={filterValue[1] || ''}
				type="number"
				onChange={(e) => {
					const val = e.target.value;
					setFilter((old = []) => [ old[0], val ? parseInt(val, 10) : undefined ]);
				}}
				placeholder={`Max (${max})`}
				style={{
					width: '70px',
					marginLeft: '0.5rem'
				}}
			/>
		</div>
	);
}

function fuzzyTextFilterFn(rows, id, filterValue) {
	return matchSorter(rows, filterValue, { keys: [ (row) => row.values[id] ] });
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = (val) => !val;

export default function WorkLogsTableList({ data }) {
	const columns = React.useMemo(
		() => [
			{
				Header: 'Operátor',
				columns: [
					{
						Header: 'Név',
						accessor: (d) => `${d.user.lastName} ${d.user.firstName}`
					},
					{
						Header: 'Email',
						accessor: (d) => `${d.user.email}`
					}
				]
			},
			{
				Header: 'Bejegyzés',
				columns: [
					{
						Header: 'Létesítmény',
						accessor: (d) => `${d.venueName}`,
						Filter: SelectColumnFilter,
						filter: 'includes'
					},
					{
						Header: 'Terület',
						accessor: (d) => {
							return d.area
								? `${d.area.building} épület, ${d.area.floor}. emelet ${d.area.name} ${d.area.number}.`
								: 'area error';
						}
					},
					{
						Header: 'Időbélyeg',
						accessor: (d) => `${moment(d.createdAt).format('HH:mm')}`
					}
				]
			}
		],
		[]
	);

	const filterTypes = React.useMemo(
		() => ({
			// Add a new fuzzyTextFilterFn filter type.
			fuzzyText: fuzzyTextFilterFn,
			// Or, override the default text filter to use
			// "startWith"
			text: (rows, id, filterValue) => {
				return rows.filter((row) => {
					const rowValue = row.values[id];
					return rowValue !== undefined
						? String(rowValue).toLowerCase().startsWith(String(filterValue).toLowerCase())
						: true;
				});
			}
		}),
		[]
	);

	const defaultColumn = React.useMemo(
		() => ({
			// Let's set up our default Filter UI
			Filter: DefaultColumnFilter
		}),
		[]
	);

	const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, state } = useTable(
		{
			columns,
			data,
			defaultColumn, // Be sure to pass the defaultColumn option
			filterTypes
		},
		useFilters // useFilters!
	);

	// We don't want to render all of the rows for this example, so cap
	// it for this use case
	const firstPageRows = rows.slice(0, 10);

	return (
		<Styles>
			{rows.length > 0 ? (
				<div>
					<div>
						<pre>
							<code>{JSON.stringify(state.filters, null, 2)}</code>
						</pre>
					</div>
					<table {...getTableProps()}>
						<thead>
							{headerGroups.map((headerGroup) => (
								<tr {...headerGroup.getHeaderGroupProps()}>
									{headerGroup.headers.map((column) => (
										<th {...column.getHeaderProps()}>
											{column.render('Header')}
											{/* Render the columns filter UI */}
											<div>{column.canFilter ? column.render('Filter') : null}</div>
										</th>
									))}
								</tr>
							))}
						</thead>
						<tbody {...getTableBodyProps()}>
							{firstPageRows.map((row, i) => {
								prepareRow(row);
								return (
									<tr {...row.getRowProps()}>
										{row.cells.map((cell) => {
											return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
										})}
									</tr>
								);
							})}
						</tbody>
					</table>
					<br />
					<div>Showing the first 20 results of {rows.length} rows</div>
				</div>
			) : (
				'No data to show'
			)}
		</Styles>
	);
}

// Define a custom filter filter function!
function filterGreaterThan(rows, id, filterValue) {
	return rows.filter((row) => {
		const rowValue = row.values[id];
		return rowValue >= filterValue;
	});
}

// This is an autoRemove method on the filter function that
// when given the new filter value and returns true, the filter
// will be automatically removed. Normally this is just an undefined
// check, but here, we want to remove the filter if it's not a number
filterGreaterThan.autoRemove = (val) => typeof val !== 'number';
