import React from 'react';
import {  LinearProgress } from '@mui/material';


export const HeaderProgressBarComponent = ({ isProgressOn }) => 
   {if(isProgressOn){
       return <LinearProgress/>
   } else {
       return ''
   }
 } 