import React, { useEffect, forwardRef, useRef } from 'react';
import MaterialTable, { MTableToolbar } from '@material-table/core';
import Check from '@mui/icons-material/Check';
import Clear from '@mui/icons-material/Clear';

import AddBox from '@mui/icons-material/AddBox';
import ArrowUpward from '@mui/icons-material/ArrowUpward';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';
import DeleteOutlined from '@mui/icons-material/DeleteOutline';
import Edit from '@mui/icons-material/Edit';
import Delete from '@mui/icons-material/Delete';
import FilterList from '@mui/icons-material/FilterList';
import FirstPage from '@mui/icons-material/FirstPage';
import LastPage from '@mui/icons-material/LastPage';
import Remove from '@mui/icons-material/Remove';
import SaveAlt from '@mui/icons-material/SaveAlt';
import Search from '@mui/icons-material/Search';
import ViewColumn from '@mui/icons-material/ViewColumn';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Tooltip from '@mui/material/Tooltip';
import { ExportCsv, ExportPdf } from '@material-table/exporters';


import moment from 'moment';


import { Button, Grid, Box, Dialog, Typography } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux'
import { useLocation, useHistory } from 'react-router-dom';

const toggleButtons = [
	<ToggleButton value="todo" key="left">
		<Tooltip title="Új">
			<div>Ú</div>
		</Tooltip>
	</ToggleButton>,
	<ToggleButton value="inprogress" key="center">
		<Tooltip title="Folyamatban">
			<div>F</div>
		</Tooltip>
	</ToggleButton>,
	<ToggleButton value="done" key="right">
		<Tooltip title="Kész">
			<div>K</div>
		</Tooltip>	
	</ToggleButton>,
	<ToggleButton value="accepted" key="justify">
		<Tooltip title="Elfogadva">
			<div>E</div>
		</Tooltip>
	</ToggleButton>
	
  ];
const columns = [
	{
		
			field: 'state',
			title: 'Státusz',
			width: 100,
			lookup: { "todo": 'Új', "inprogress": 'Folyamatban',"done": 'Kész',"accepted": 'Elfogadva' },
			render: rowData => 
				<ToggleButtonGroup size="small" value={rowData.state} exclusive={true}>
        			{toggleButtons}
      			</ToggleButtonGroup>	
	},
    {
		title: 'Feladat',
		field: 'description',
		width: 400
	},
	{
		title: 'Létrehozva',
		field: 'createdAt',
		width: 200
	},
	{
		title: 'Létrehozta',
		field: 'createdBy',
		width: 200
	},
	{
		title: 'Takarító',
		field: 'assignedTo',
		width: 200
	},
	{ 
		title: 'Befejezés',
		field: 'completedAt',
		width: 200
	},
	{ 
		title: 'Átfutási idő (perc)',
		field: 'duration',
		width: 50
	},
	{
		title: 'Terület',
		field: 'area',
		width: 200,
		render: rowData =>
			<div>
				<Typography variant="subtitle2">
					{rowData.areaObject && rowData.areaObject.building ? rowData.areaObject.building + ' épület' : ''}{' '}
					{rowData.areaObject && rowData.areaObject.floor ? rowData.areaObject.floor + '. szint ' : ''}
				</Typography>
				<Typography variant="subtitle2">
					{rowData.areaObject && rowData.areaObject.name ? rowData.areaObject.name + ' ' : ''}
					{rowData.areaObject && rowData.areaObject.number ? rowData.areaObject.number : ''}
				</Typography>
			</div>
	}
];

const tableIcons = {
	Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
	Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
	Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
	Delete: forwardRef((props, ref) => <Delete {...props} ref={ref} />),
	DeleteOutlined: forwardRef((props, ref) => <DeleteOutlined {...props} ref={ref} />),
	DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
	Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
	Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
	Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
	FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
	LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
	NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
	PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
	ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
	Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
	SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref} />),
	ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
	ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

export default function AllAdHocTasks({allAdHocTasksRows}) {
	const tableRef = useRef();

	return (
		<div style={{ height: '100%', width: '100%' }}>
			<MaterialTable
				tableRef={tableRef}
				icons={tableIcons}
				columns={columns}
				data={allAdHocTasksRows}
				options={{
					showTitle: false,
					exportButton: true,
					exportMenu: [
						{
							label: 'Export PDF',
							exportFunc: (cols, datas) => ExportPdf(cols, datas, 'eseti-feladatok')
						},
						{
							label: 'Export CSV',
							exportFunc: (cols, datas) => ExportCsv(cols, datas, 'eseti-feladatok',';')
						}
					],
					filtering: true,
					sorting: true,
					search: false,
					toolbar: true,        
					paging: false,
					padding: 'dense'
					
				}}
			/>
		</div>
	);
}
