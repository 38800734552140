import React from 'react';
import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import { Paper, Divider } from '@mui/material';

const useStyles = makeStyles((theme) => ({
	root: {
		margin: 'auto'
	},
	paper: {
		width: 200,
		height: 230,
		overflow: 'auto'
	},
	button: {
		margin: useTheme().spacing(0.5, 0)
	}
}));

function not(a, b) {
	return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
	return a.filter((value) => b.indexOf(value) !== -1);
}

export default function TransferAreasList({ venue, groupkey, updateVenue, onClose }) {
	const classes = useStyles();
	const [ checked, setChecked ] = React.useState([]);
	const [ left, setLeft ] = React.useState(generateLeft());
	const [ right, setRight ] = React.useState(venue.groups[groupkey].areas);

	function generateLeft() {
		var array = [];
		Object.keys(venue.areas).forEach(function(key) {
			if (!venue.groups[groupkey].areas.includes(key)) array.push(key);
		});
		return array;
	}

	const updateGroupsonVenue = () => {
		venue.groups[groupkey].areas = right;
		onClose();
		updateVenue(venue);
	};

	const leftChecked = intersection(checked, left);
	const rightChecked = intersection(checked, right);

	const handleToggle = (value) => () => {
		const currentIndex = checked.indexOf(value);
		const newChecked = [ ...checked ];

		if (currentIndex === -1) {
			newChecked.push(value);
		} else {
			newChecked.splice(currentIndex, 1);
		}

		setChecked(newChecked);
	};

	const handleAllRight = () => {
		setRight(right.concat(left));
		setLeft([]);
	};

	const handleCheckedRight = () => {
		setRight(right.concat(leftChecked));
		setLeft(not(left, leftChecked));
		setChecked(not(checked, leftChecked));
	};

	const handleCheckedLeft = () => {
		setLeft(left.concat(rightChecked));
		setRight(not(right, rightChecked));
		setChecked(not(checked, rightChecked));
	};

	const handleAllLeft = () => {
		setLeft(left.concat(right));
		setRight([]);
	};

	const customList = (items) => (
		<Paper className={classes.paper}>
			<List dense component="div" role="list">
				{items.map((value) => {
					const labelId = `transfer-list-item-${value}-label`;

					return (
						<ListItem key={value} role="listitem" button onClick={handleToggle(value)}>
							<ListItemIcon>
								<Checkbox
									checked={checked.indexOf(value) !== -1}
									tabIndex={-1}
									disableRipple
									inputProps={{ 'aria-labelledby': labelId }}
								/>
							</ListItemIcon>
							<ListItemText
								id={labelId}
								primary={venue.areas[value] ? venue.areas[value].name : 'Error: not found'}
							/>
						</ListItem>
					);
				})}
				<ListItem />
			</List>
		</Paper>
	);

	return (
		<Grid container spacing={2} justifyContent="center" alignItems="center" className={classes.root}>
			<Grid item>{customList(left)}</Grid>
			<Grid item>
				<Grid container direction="column" alignItems="center">
					<Button
						variant="outlined"
						size="small"
						className={classes.button}
						onClick={handleAllRight}
						disabled={left.length === 0}
						aria-label="move all right"
					>
						≫
					</Button>
					<Button
						variant="outlined"
						size="small"
						className={classes.button}
						onClick={handleCheckedRight}
						disabled={leftChecked.length === 0}
						aria-label="move selected right"
					>
						&gt;
					</Button>
					<Button
						variant="outlined"
						size="small"
						className={classes.button}
						onClick={handleCheckedLeft}
						disabled={rightChecked.length === 0}
						aria-label="move selected left"
					>
						&lt;
					</Button>
					<Button
						variant="outlined"
						size="small"
						className={classes.button}
						onClick={handleAllLeft}
						disabled={right.length === 0}
						aria-label="move all left"
					>
						≪
					</Button>
				</Grid>
			</Grid>
			<Grid item>{customList(right)}</Grid>
			<Grid item>
				<Divider variant="fullWidth" />
			</Grid>
			<Grid>
				{' '}
				<Button onClick={updateGroupsonVenue} color="primary">
					Mentés
				</Button>
			</Grid>
		</Grid>
	);
}
