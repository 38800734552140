import React from 'react';

import { Avatar, Card, CardContent, Grid, Typography, Box } from '@mui/material';
import NoteAltIcon from '@mui/icons-material/NoteAlt';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';

const calculateChange = (v1, v2) => {
	if (v1 == 0) {
		return v2 * 100;
	}
	if (v2 == 0) {
		return -(v1 * 100);
	}
	return Math.round((v2 - v1) / Math.abs(v1) * 100);
};

export const TotalReports = ({ countInLast7Days, countInLast7to14Days }) => (
	<Card sx={{ height: '100%' }}>
		<CardContent>
			<Grid container spacing={3} sx={{ justifyContent: 'space-between' }}>
				<Grid item>
					<Typography color="textSecondary" gutterBottom variant="overline">
						Riportok - elmúlt 7 napban
					</Typography>
					<Typography color="textPrimary" variant="h4">
						{countInLast7Days}
					</Typography>
				</Grid>
				<Grid item>
					<Avatar
						sx={{
							backgroundColor: 'primary.main',
							height: 56,
							width: 56
						}}
					>
						<NoteAltIcon />
					</Avatar>
				</Grid>
			</Grid>
			<Box
				sx={{
					pt: 2,
					display: 'flex',
					alignItems: 'center'
				}}
			>
				{calculateChange(countInLast7to14Days, countInLast7Days) < 0 && (
					<div>
						{' '}
						<ArrowDownwardIcon color="error" />
					</div>
				)}
				{calculateChange(countInLast7to14Days, countInLast7Days) > 0 && (
					<div>
						{' '}
						<ArrowUpwardIcon color="success" />
					</div>
				)}

				<Typography
					color={calculateChange(countInLast7to14Days, countInLast7Days) < 0 ? 'error' : 'success.main'}
					sx={{
						mr: 1
					}}
					variant="body2"
				>
					{calculateChange(countInLast7to14Days, countInLast7Days)}%
				</Typography>
				<Typography color="textSecondary" variant="caption">
					Előző 7 naphoz képest
				</Typography>
			</Box>
		</CardContent>
	</Card>
);
