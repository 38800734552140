import React, { useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { getSelectedOrg } from '../../firebase/auth';

import { getAreaName } from '../../utilities/StringHelper';

import {  Typography, LinearProgress } from '@mui/material';

import {  getReportWithArea } from '../../api/manager';

import moment from 'moment';
import FormTable from './FormTable';

import TopNavigationBar from '../../utilities/TopNavigationBar';

const useStyles = makeStyles((theme) => ({
	root: {
		padding: 10
	},
	appBar: {
		position: 'relative',
		background: '#FFFFFF',
		color: '#000000'
	}
}));

export default function ReportViewDialog() {
	const [ report, setReport ] = React.useState(useLocation().state ? useLocation().state.report : {});
	const [ isLoading, setIsLoading ] = React.useState(true);
	const classes = useStyles();
	const location = useLocation();
	const locationQuery = new URLSearchParams(useLocation().search);

	useEffect(() => {
		if (report.id != location.pathname.split('/').pop()) {
			if (location.state) {
				setReport(location.state.report);
			} else {
				fetchReport(locationQuery.get('id'), location.pathname.split('/').pop());
			}
		} else {
			setIsLoading(false);
		}
	});

	const fetchReport = (venueId, reportId) => {
		getReportWithArea(getSelectedOrg(), venueId, reportId)
			.then((result) => {
				const tempReport = result;
				setReport(tempReport);
			})
			.catch((error) => {
				console.log('Error happened in Venues.js while retriving Venues' + error);
				setIsLoading(false);
			});
	};

	return report.id == null ? (
		<LinearProgress
			classes={{
				colorPrimary: classes.colorPrimary,
				barColorPrimary: classes.barColorPrimary,
				margin: 10
			}}
		/>
	) : (
		<div>
			<TopNavigationBar title={getAreaName(report.area)} />
			<div className={classes.root}>
				<Typography align="center" variant="h3">
					{report.score}%
				</Typography>
				<p>
					Terület: <b>{getAreaName(report.area)}</b>
				</p>
				<p>
					Űrlap: <b>{report.name}</b>
				</p>
				<p>
					Létrehozva: <b>{moment(new Date(report.createdAt)).format('YYYY-MM-DD HH:mm:ss')}</b>
				</p>
				<p>
					Készítette: <b>{report.createdBy}</b>
				</p>
			</div>
			<FormTable report={report} />
		</div>
	);
}
