const XlsxPopulate = require('xlsx-populate');
const FileSaver = require('file-saver');

function generateXLSX(venue) {
	// Load a new blank workbook
	return XlsxPopulate.fromBlankAsync().then((workbook) => {
		const categoriesCount = Object.keys(venue.categories).length;
		// Modify the workbook.

		var inputWorkSheet = workbook.sheet('Sheet1');

		//Set column sizes
		inputWorkSheet.column('A').width(25);
		inputWorkSheet.column('B').width(25);
		inputWorkSheet.column('C').width(25);
		inputWorkSheet.column('D').width(25);
		inputWorkSheet.column('E').width(25);
		inputWorkSheet.column('F').width(25);

		//Gray background
		inputWorkSheet.range('A1:F' + (13 + categoriesCount).toString()).style('fill', 'F2F2F2');

		//Header text and style
		inputWorkSheet.cell('A1').value('CleanSpect Takarítás Ellenőrző és Naplózó rendszer területfeltöltő SABLON');
		inputWorkSheet.range('A1:F1').style('fill', '039be5');
		inputWorkSheet.range('A1:F1').style('bold', true);
		inputWorkSheet.range('A1:F1').style('fontSize', 16);
		inputWorkSheet.range('A1:F1').style('fontColor', 'FFFFFF');
		inputWorkSheet.range('A1:F1').merged();

		//Venue info
		const venueNameCell = inputWorkSheet.cell('A3');
		venueNameCell.value('Létesítmény');
		venueNameCell.style('fill', '039be5');
		venueNameCell.style('bold', true);
		venueNameCell.style('fontSize', 12);
		venueNameCell.style('fontColor', 'FFFFFF');

		//Venue info style
		inputWorkSheet.cell('A4').value(venue.name);
		inputWorkSheet.cell('A4').style('bold', true);

		//Categories info
		inputWorkSheet.cell('A6').value('Kategóriák');
		inputWorkSheet.cell('A7').value('id');
		inputWorkSheet.cell('B7').value('kategória neve');

		//Categories style
		inputWorkSheet.range('A6:B6').merged();
		inputWorkSheet.range('A6:B6').style('fill', '039be5');
		inputWorkSheet.range('A6:B6').style('bold', true);
		inputWorkSheet.range('A6:B6').style('fontSize', 12);
		inputWorkSheet.range('A6:B6').style('fontColor', 'FFFFFF');

		inputWorkSheet.cell('A7').style('fill', '039be5');
		inputWorkSheet.cell('A7').style('fontSize', 12);
		inputWorkSheet.cell('A7').style('fontColor', 'FFFFFF');
		inputWorkSheet.cell('B7').style('fill', '039be5');
		inputWorkSheet.cell('B7').style('fontSize', 12);
		inputWorkSheet.cell('B7').style('fontColor', 'FFFFFF');

		inputWorkSheet.row(6).hidden(true);
		inputWorkSheet.row(7).hidden(true);

		//ADD CATEGORIES
		Object.keys(venue.categories).map((key, index) => {
			inputWorkSheet.row(index + 8).cell(1).value(key);

			inputWorkSheet.row(index + 8).cell(2).value(venue.categories[key].name);
			inputWorkSheet.row(index + 8).cell(2).style('bold', true);

			inputWorkSheet.row(index + 8).hidden(true);
		});

		// IMPORTANT informations
		inputWorkSheet.row(categoriesCount + 8).cell(1).value('FONTOS');
		inputWorkSheet.row(categoriesCount + 8).cell(1).style('bold', true);
		inputWorkSheet.row(categoriesCount + 8).cell(1).style('fontSize', 14);
		inputWorkSheet.row(categoriesCount + 8).cell(1).style('fontColor', 'C00000');

		inputWorkSheet
			.row(categoriesCount + 9)
			.cell(1)
			.value(
				'1. Területek feltöltéséhez kérem az alábbi táblázatban adja meg a feltölteni kívánt területek adatait.'
			);
		inputWorkSheet.row(categoriesCount + 9).cell(1).style('fontColor', 'C00000');

		inputWorkSheet
			.row(categoriesCount + 10)
			.cell(1)
			.value(
				'2. Kérem a területadatok megadásán kívük semmit se változtasson ebben ay excel dokumentumban, hogy a program helyesen fel tudja dolgozni a megadott adatokat'
			);
		inputWorkSheet.row(categoriesCount + 10).cell(1).style('fontColor', 'C00000');

		inputWorkSheet
			.row(categoriesCount + 11)
			.cell(1)
			.value(
				'3. Terület megnevezése az egyetlen kötelezően kitöltendő mező. Amennyiben ez nem kerül kitöltésre, a program nem fogja tudni feldolgozni.'
			);
		inputWorkSheet.row(categoriesCount + 11).cell(1).style('fontColor', 'C00000');

		inputWorkSheet
			.row(categoriesCount + 12)
			.cell(1)
			.value(
				'4. Kategória megadásához válasszon a legördülő menüből. Amennyiben a megadni kívánt kategória nem szerepel a listában,'
			);
		inputWorkSheet.row(categoriesCount + 12).cell(1).style('fontColor', 'C00000');
		inputWorkSheet
			.row(categoriesCount + 13)
			.cell(1)
			.value(
				'győződjön meg róla, hogy a CleanSpect portálon a létesítmény alatt szerepel, majd töltse le újra a Területfeltöltő sablont!'
			);
		inputWorkSheet.row(categoriesCount + 13).cell(1).style('fontColor', 'C00000');

		//Input table header
		inputWorkSheet.cell(`A${14 + categoriesCount}`).value('Épület');
		inputWorkSheet.cell(`B${14 + categoriesCount}`).value('Szint');
		inputWorkSheet.cell(`C${14 + categoriesCount}`).value('Terület megnevezése (kötelező megadni)');
		inputWorkSheet.cell(`D${14 + categoriesCount}`).value('Terület száma');
		inputWorkSheet.cell(`E${14 + categoriesCount}`).value('Kategória');
		inputWorkSheet.cell(`F${14 + categoriesCount}`).value('Terület (m2)');

		//Input table header style
		var inputHederTableRange = inputWorkSheet.range(`A${14 + categoriesCount}:F${14 + categoriesCount}`);
		inputHederTableRange.style('fill', '039be5');
		inputHederTableRange.style('bold', true);
		inputHederTableRange.style('fontSize', 12);
		inputHederTableRange.style('fontColor', 'FFFFFF');

		//Set data validation on categories
		inputWorkSheet.range(`E${15 + categoriesCount}:E${500 + categoriesCount}`).dataValidation({
			type: 'list',
			allowBlank: true,
			// showInputMessage: true,
			// prompt: false,
			// promptTitle: 'String',
			showErrorMessage: true,
			errorTitle: 'Megadott kategória nem létezik',
			error:
				'Amennyiben a megadni kívánt kategória nem szerepel a listában, kérem győződjön meg róla, hogy a CleanSpect portálon a létesítmény alatt szerepel, majd töltse le újra a Területfeltöltő sablont!',
			// operator: 'String',
			formula1: `$B$${8}:$B$${8 + categoriesCount - 1}`, //Required
			formula2: 'String'
		});

		///Export
		workbook.outputAsync().then(function(blob) {
			if (window.navigator && window.navigator.msSaveOrOpenBlob) {
				// If IE, you must uses a different method.
				window.navigator.msSaveOrOpenBlob(blob, `${venue.name} terület feltöltő SABLON.xlsx`);
			} else {
				var url = window.URL.createObjectURL(blob);
				var a = document.createElement('a');
				document.body.appendChild(a);
				a.href = url;
				a.download = `${venue.name} terület feltöltő SABLON.xlsx`;
				a.click();
				window.URL.revokeObjectURL(url);
				document.body.removeChild(a);
			}
		});
	});
}

export function exportGeneratedXLSX(venue) {
	generateXLSX(venue);
}
