import React from 'react';
import { makeStyles, withStyles } from '@mui/styles';
import { Grid, Button, Switch, FormControlLabel, Divider } from '@mui/material';
import {deleteVenue, saveVenueSettingsChange} from './../../../actions/venuesActions'
import { useDispatch } from 'react-redux'
import { getSelectedOrg } from '../../../firebase/auth';


const useStyles = makeStyles((theme) => ({
	deleteButton: {
		backgroundColor: '#E30000',
		color: '#FFFFFF'
	}
}));

export default function ManageVenue({ venue }) {
	const classes = useStyles();
	const dispatch = useDispatch()
	const [isChanged,setIsChanged] = React.useState(false)
	// const [forceCloseTaskBeforeNew, setForceCloseTaskBeforeNew] = React.useState(venue.settings.forceCloseTaskBeforeNew);
	// const [trackCloseTimeOnWorkLogs, setTrackCloseTimeOnWorkLogs] = React.useState(venue.settings.trackCloseTimeOnWorkLogs);
	const [settings, setSettings] = React.useState(venue.settings)

	const deleteVenueAction = () => {
		venue.isActive = false;
		dispatch(deleteVenue(getSelectedOrg(),venue))
		window.history.back()
	}
	const saveVenueSettingsChangeAction = () => {
		if(!venue.settings){
			venue.settings = {}
		}
		venue.settings.forceCloseTaskBeforeNew = settings.forceCloseTaskBeforeNew;
		venue.settings.trackCloseTimeOnWorkLogs = settings.trackCloseTimeOnWorkLogs;
		dispatch(saveVenueSettingsChange(getSelectedOrg(),venue.id,{settings:venue.settings}))
		window.history.back()
	}
	const handleChangeForceCloseTaskBeforeNew = (event) => {

		setSettings((setting) => ({ ...settings, forceCloseTaskBeforeNew: event.target.checked }))
		setIsChanged(true)
	}
	const handleChangeTrackCloseTimeOnWorkLogs = (event) => {
		setSettings((setting) => ({ ...settings, trackCloseTimeOnWorkLogs: event.target.checked }))
		setIsChanged(true)
	}


	return (
		<Grid container
		direction="column"
		justifyContent="flex-start"
		alignItems="flex-start">
			<Grid item xs={3}>
				<FormControlLabel control={<Switch checked={settings?settings.forceCloseTaskBeforeNew:false} onChange={handleChangeForceCloseTaskBeforeNew}  inputProps={{ 'aria-label': 'controlled' }}/>} label="Takarító naplóbejegyzés lezárása új kezdése előtt" />
			</Grid>
			<Grid item xs={3}>
				<FormControlLabel control={<Switch  checked={settings?settings.trackCloseTimeOnWorkLogs:false} onChange={handleChangeTrackCloseTimeOnWorkLogs}  inputProps={{ 'aria-label': 'controlled' }}/>} label="Naplóbejegyzés lezárása új QR kód beolvasással" />
			</Grid>
			<Grid item xs={3}>
				{isChanged ? (<Button onClick={saveVenueSettingsChangeAction}>
					Mentés
				</Button>) : ""
				}
			</Grid>
			<Grid item xs={3}>
			<Divider orientation="vertical" flexItem />
			</Grid>
			<Grid item xs={3}>
				<Button className={classes.deleteButton} onClick={(event) => deleteVenueAction()}>
					Létesítmény törlése
				</Button>
			</Grid>

		</Grid>
		// <FormGroup>
		// 	<Button className={classes.deleteButton} onClick={(event) => deleteVenueAction()}>
		// 			Létesítmény törlése
	 	// 	</Button>
		// 	<FormControlLabel control={<Switch defaultChecked />} label="Label" />
		// 	<FormControlLabel required control={<Switch />} label="Required" />
		// 	<FormControlLabel disabled control={<Switch />} label="Disabled" />
	  	// </FormGroup>
	);
}
