import UUID from 'uuid/v4';

const XlsxPopulate = require('xlsx-populate');

export function parseUploadedTemplateFIle(file, categories) {
	return XlsxPopulate.fromDataAsync(file).then(function(workbook) {
		var sheet = workbook.sheet(0);
		var rows = sheet._rows;

		var startInputRowNumber = 0;
		var endInputRowNumber = 0;

		var inputResults = [];
		rows.forEach(function(row) {
			if (row.cell(1).value() == 'input-areas-table') {
				startInputRowNumber = row._node.attributes.r + 2;
			} else if (startInputRowNumber < row._node.attributes.r && row.cell(3).value() != null) {
				var category = null;
				Object.keys(categories).map((key) => {
					if (categories[key].name == row.cell(5).value()) {
						category = key;
					}
				});
				let key = UUID();
				inputResults.push({
					id: key,
					building: row.cell(1).value(),
					floor: row.cell(2).value(),
					name: row.cell(3).value(),
					number: row.cell(4).value(),
					category: category,
					size: row.cell(6).value()
				});
			}
		});
		return inputResults;
	});
}
