import moment from 'moment';

function aggreageteReportsByDateAndName(reports) {
	const reportsData = {};
	reports.map((report) => {
		if (report.score != null) {
			const tempDate = moment(report.createdAt).format('YYYY-MM-DD');
			if (reportsData[tempDate] != null && reportsData[tempDate][report.name] != null) {
				reportsData[tempDate][report.name]['score'].push(
					report.score + (report.scoreFixed ? report.scoreFixed : 0)
				);
				reportsData[tempDate][report.name]['scoreFixed'].push(report.scoreFixed);
				reportsData[tempDate][report.name]['scoreTrue'].push(report.score);
			} else {
				if (!reportsData[tempDate]) {
					reportsData[tempDate] = {};
				}
				reportsData[tempDate][report.name] = { scoreTrue: [], scoreFixed: [], score: [] };
				reportsData[tempDate][report.name]['score'].push(
					report.score + (report.scoreFixed ? report.scoreFixed : 0)
				);
				reportsData[tempDate][report.name]['scoreFixed'].push(report.scoreFixed ? report.scoreFixed : 0);
				reportsData[tempDate][report.name]['scoreTrue'].push(report.score);
			}
		}
	});
	return reportsData;
}

function aggreageteReportsByDate(reports) {
	const reportsData = {};
	reports.map((report) => {
		if (report.score != null) {
			const tempDate = moment(report.createdAt).format('YYYY-MM-DD');
			if (reportsData[tempDate] != null) {
				reportsData[tempDate]['scoreTrue'].push(report.score - (report.scoreFixed ? report.scoreFixed : 0));
				reportsData[tempDate]['scoreFixed'].push(report.scoreFixed);
			} else {
				if (!reportsData[tempDate]) {
					reportsData[tempDate] = { scoreTrue: [], scoreFixed: [] };
				}
				reportsData[tempDate]['scoreTrue'].push(report.score - (report.scoreFixed ? report.scoreFixed : 0));
				reportsData[tempDate]['scoreFixed'].push(report.scoreFixed ? report.scoreFixed : 0);
			}
		}
	});
	return reportsData;
}

function getReportsTypes(reports) {
	const reportTypesArray = [];

	reports.map((report) => {
		if (reportTypesArray.indexOf(report.name) == -1) {
			reportTypesArray.push(report.name);
		}
	});
	return reportTypesArray;
}

function getCombinedByNameReportsChartsData(combinedReportsData, reportTypesArray) {
	const columns = [ 'Datum' ].concat(reportTypesArray).concat([ 'Atlag' ]);

	var result = { columns: columns, rows: [] };

	Object.keys(combinedReportsData).map((date) => {
		const resultData = Array(reportTypesArray.length + 1);
		resultData[0] = moment(date, 'YYYY-MM-DD').format('MMM-DD');
		var avarage = 0;
		var size = 0;
		Object.keys(combinedReportsData[date]).map((name) => {
			var baseTrue = 0;
			var baseFixed = 0;
			var baseScore = 0;
			combinedReportsData[date][name]['scoreTrue'].map((score) => {
				baseTrue += score;
			});
			combinedReportsData[date][name]['scoreFixed'].map((score) => {
				baseFixed += score;
			});
			combinedReportsData[date][name]['score'].map((score) => {
				baseScore += score;
			});
			const score = Math.round(baseScore / combinedReportsData[date][name]['score'].length);
			resultData[reportTypesArray.indexOf(name) + 1] = score;
			avarage += score;
			size++;
		});
		resultData[columns.length - 1] = Math.round(avarage / size);
		result.rows.push(resultData);
	});
	return result;
}

function getStackedReportsChartsData(combinedReportsData, reportTypesArray) {
	const columns = [ 'Datum', 'Jo', 'Javitott' ];

	var result = { columns: columns, rows: [] };

	Object.keys(combinedReportsData).map((date) => {
		const resultData = Array(reportTypesArray.length + 1);
		resultData[0] = moment(date, 'YYYY-MM-DD').format('MMM-DD');

		var baseTrue = 0;
		var baseFixed = 0;

		combinedReportsData[date]['scoreTrue'].map((score) => {
			baseTrue += score;
		});
		combinedReportsData[date]['scoreFixed'].map((score) => {
			baseFixed += score;
		});

		resultData[1] = Math.round(baseTrue + baseFixed / combinedReportsData[date]['scoreTrue'].length);
		resultData[2] = Math.round(baseFixed / combinedReportsData[date]['scoreFixed'].length);

		result.rows.push(resultData);
	});
	return result;
}

export function getReportsDataArrayToChartJSSeries(reports) {
	var result = {
		combo: getCombinedByNameReportsChartsData(
			aggreageteReportsByDateAndName(reports.combined),
			getReportsTypes(reports.combined)
		)
	};

	Object.keys(reports.groupedReports).map((group) => {
		result[group] = getStackedReportsChartsData(
			aggreageteReportsByDate(reports.groupedReports[group]),
			getReportsTypes(reports.groupedReports[group])
		);
	});

	return result;
}
