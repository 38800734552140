import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { getWorkLogsOnVenue } from '../api/manager';
import { getSelectedOrg } from '../firebase/auth';

import PropTypes from 'prop-types';

import { getAreaName } from '../utilities/StringHelper';

import DayPicker from './datepickers/DayPicker';
import WeekPicker from './datepickers/MonthPicker';
import MonthPicker from './datepickers/MonthPicker';

import TopNavigationBar from '../utilities/TopNavigationBar';
import WorkLogsTableList from './WorkLogsTableList';
import WorkLogsTimeLine from './WorkLogsTimeLine';

import { Timeline, TimelineItem } from 'vertical-timeline-component-for-react';

import { Paper, Grid, Button, Divider, Tab, AppBar, Box, Tabs, LinearProgress, Typography } from '@mui/material';

import moment from 'moment';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flexGrow',
		flexWrap: 'wrap',
		padding: 10
	}
}));

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<Typography
			component="div"
			role="tabpanel"
			hidden={value !== index}
			id={`scrollable-auto-tabpanel-${index}`}
			aria-labelledby={`scrollable-auto-tab-${index}`}
			{...other}
		>
			<Box p={0}>{children}</Box>
		</Typography>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired
};

function a11yProps(index) {
	return {
		id: `scrollable-auto-tab-${index}`,
		'aria-controls': `full-width-tabpanel-${index}`
	};
}

export default function WeeklyWorkLogsOnVenue({ venue, venueId }) {
	const classes = useStyles();
	const [ workLogs, setWorkLogs ] = React.useState([]);
	const [ timelineWorkLogs, setTimelineWorkLogs ] = React.useState({});
	const [ day, setDay ] = React.useState(new Date());
	const [ isLoading, setIsLoading ] = React.useState(true);
	const [ value, setValue ] = React.useState(0);

	useEffect(() => {
		if (workLogs.length < 1) fetchWorkLogs(day);
	}, []);

	const handleDayChange = (newDay) => {
		setDay(newDay);
		fetchWorkLogs(newDay);
	};

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	const fetchWorkLogs = (day) => {
		setIsLoading(true);
		getWorkLogsOnVenue(getSelectedOrg(), venueId, day)
			.then((result) => {
				var worklogsObject = {};
				var timelineObjects = {};
				result.data.results.forEach(function(worklog, index) {
					//Add to worklogsobject
					if (worklogsObject[worklog.areaId] != null) {
						worklogsObject[worklog.areaId].count++;
						worklogsObject[worklog.areaId].history.push({
							duration: moment(worklog.endTime)
								.diff(moment(worklog.startTime), 'minutes', true)
								.toFixed(1),
							name: worklog.user.lastName,
							time: moment(worklog.createdAt).format('HH:mm')
						});
					} else {
						worklogsObject[worklog.areaId] = {
							area: getAreaName(worklog.area),
							count: 1,
							history: [
								{
									duration: moment(worklog.endTime)
										.diff(moment(worklog.startTime), 'minutes', true)
										.toFixed(1),
									name: worklog.user.lastName + worklog.user.firstName,
									time: moment(worklog.createdAt).format('HH:mm')
								}
							]
						};
					}

					//Add to timelineObjects
					if (timelineObjects[worklog.createdBy] != null) {
						timelineObjects[worklog.createdBy].workLogs.push({
							area: worklog.area,
							startTime: worklog.startTime,
							endTime: worklog.endTime,
							duration: moment(worklog.endTime)
								.diff(moment(worklog.startTime), 'minutes', true)
								.toFixed(1)
						});
					} else {
						timelineObjects[worklog.createdBy] = {
							user: worklog.user,
							workLogs: [
								{
									area: worklog.area,
									startTime: worklog.startTime,
									endTime: worklog.endTime,
									duration: moment(worklog.endTime)
										.diff(moment(worklog.startTime), 'minutes', true)
										.toFixed(1)
								}
							]
						};
					}
				});
				setTimelineWorkLogs(timelineObjects);
				setWorkLogs(Object.values(worklogsObject));
				setIsLoading(false);
			})
			.catch((error) => {
				console.log('Error happened in Venues.js while retriving Venues' + error);
				setIsLoading(false);
			});
	};

	return (
		<div>
			<img
				className={classes.fit}
				src="https://cdn.xingosoftware.com/elektor/images/fetch/dpr_1/https%3A%2F%2Fwww.elektormagazine.com%2Fassets%2Fupload%2Fimages%2F8%2F20190629113654_Elektor-Under-Construction.png"
			/>
		</div>
		// <div>
		// 	{isLoading ? (
		// 		<LinearProgress />
		// 	) : (
		// 		<div className={classes.root}>
		// 			<MonthPicker day={day} updateDay={handleDayChange} />
		// 			<DayPicker day={day} updateDay={handleDayChange} />
		// 			<AppBar position="static" color="default">
		// 				<Tabs
		// 					value={value}
		// 					onChange={handleChange}
		// 					indicatorColor="secondary"
		// 					variant="fullWidth"
		// 					textColor="secondary"
		// 					aria-label="full width tabs example"
		// 				>
		// 					<Tab label="Táblázat" {...a11yProps(0)} />
		// 					<Tab label="Idővonal" {...a11yProps(1)} />
		// 				</Tabs>
		// 			</AppBar>
		// 			<TabPanel value={value} index={0}>
		// 				<CollapsibleTable data={workLogs} />
		// 			</TabPanel>
		// 			<TabPanel value={value} index={1}>
		// 				<WorkLogsTimeLine data={timelineWorkLogs} />
		// 			</TabPanel>
		// 		</div>
		// 	)}
		// </div>
	);
}
