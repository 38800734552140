import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material/styles';
import { Paper, Grid, Button, Divider, Tab, AppBar, Box, Tabs, Typography } from '@mui/material';
import MasterCleaningActivitiesManager from './mastercleaningactivities/MasterCleaningActivitiesManager';

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<Typography
			component="div"
			role="tabpanel"
			hidden={value !== index}
			id={`scrollable-auto-tabpanel-${index}`}
			aria-labelledby={`scrollable-auto-tab-${index}`}
			{...other}
		>
			{value === index && <Box p={3}>{children}</Box>}
		</Typography>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired
};

function a11yProps(index) {
	return {
		id: `scrollable-auto-tab-${index}`,
		'aria-controls': `scrollable-auto-tabpanel-${index}`
	};
}

// const useStyles = makeStyles(() => ({
// 	root: {
// 		felxGrow: 1
// 	},
// 	formControl: {
// 		margin: useTheme().spacing(1),
// 		minWidth: 120
// 	},
// 	selectEmpty: {
// 		marginTop: useTheme().spacing(2)
// 	},
// 	deleteButton: {
// 		backgroundColor: '#E30000',
// 		color: '#FFFFFF'
// 	}
// }));

function MasterDataManager() {
	const [ value, setValue ] = React.useState(0);
	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	return (
		<Grid container>
			<Grid item xs={12}>
				<AppBar position="static" color="default">
					<Tabs
						value={value}
						onChange={handleChange}
						variant="scrollable"
						scrollButtons="auto"
						aria-label="scrollable auto tabs example"
					>
						<Tab label="Takarítási Feladatok" {...a11yProps(0)} />
					</Tabs>
				</AppBar>
				<TabPanel value={value} index={0} hidden={value !== 0}>
					<MasterCleaningActivitiesManager />
				</TabPanel>
			</Grid>
		</Grid>
	);
}

export default MasterDataManager;
