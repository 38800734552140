import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import IconButton from '@mui/material/IconButton';
import BackIcon from '@mui/icons-material/ArrowBack';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexWrap: 'wrap',
		padding: 10,
		backgroundColor: '#FFFFFF'
	}
}));

export default function TopNavigationBar({ title, isBackButtonVisible }) {
	const history = useHistory();
	const classes = useStyles();

	const handleClick = () => {
		history.goBack();
	};

	return (
		<Box className={classes.root} boxShadow={3}>
			<Grid container direction="row" justifyContent="flex-start">
				<Grid key="back-button" item>
					<IconButton aria-label="back" size="small" onClick={handleClick}>
						<BackIcon fontSize="small" />
					</IconButton>
				</Grid>
				<Grid key="venue-title" item>
					<Typography component="span" variant="subtitle1" color="primary" display="initial" align="left">
						{title}
					</Typography>
				</Grid>
			</Grid>
		</Box>
	);
}
