/* eslint-disable no-console */

import React from 'react';
import { Chart } from 'react-google-charts';

export default function ReportsCombinedChart({ data }) {
	// const rows = createRows(input.data);

	function getOptions(columns) {
		var options = {
			seriesType: 'bars',
			legend: 'bottom',
			title: 'Riportok osszesites',

			subtitle: 'Osszesitet atlag ertek kategoriankent napi bontasban',
			hAxis: {
				title: 'Datum'
			},
			vAxis: { title: '%', viewWindow: { min: 0, max: 120 } },
			series: {}
		};
		options.series[columns.length - 2] = { type: 'line' };
		return options;
	}

	return (
		<Chart
			width="100%"
			height={300}
			chartType="ComboChart"
			loader={<div>Loading Chart</div>}
			columns={data.columns}
			rows={data.rows}
			options={getOptions(data.columns)}
			legendToggle
		/>
	);
}
