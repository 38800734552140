import React, { forwardRef, useEffect } from 'react';
import MaterialTable from '@material-table/core';
import AddBox from '@mui/icons-material/AddBox';
import ArrowUpward from '@mui/icons-material/ArrowUpward';
import Check from '@mui/icons-material/Check';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';
import Clear from '@mui/icons-material/Clear';
import DeleteOutlined from '@mui/icons-material/DeleteOutline';
import Edit from '@mui/icons-material/Edit';
import Delete from '@mui/icons-material/Delete';
import FilterList from '@mui/icons-material/FilterList';
import FirstPage from '@mui/icons-material/FirstPage';
import LastPage from '@mui/icons-material/LastPage';
import Remove from '@mui/icons-material/Remove';
import SaveAlt from '@mui/icons-material/SaveAlt';
import Search from '@mui/icons-material/Search';
import ViewColumn from '@mui/icons-material/ViewColumn';
import UUID from 'uuid/v4';
import CheckmarkIcon from './checkmark-icon.png';
import { getAreaName } from '../../../utilities/StringHelper';

const tableIcons = {
	Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
	Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
	Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
	Delete: forwardRef((props, ref) => <Delete {...props} ref={ref} />),
	DeleteOutlined: forwardRef((props, ref) => <DeleteOutlined {...props} ref={ref} />),
	DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
	Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
	Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
	Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
	FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
	LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
	NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
	PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
	ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
	Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
	SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref} />),
	ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
	ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

function getCategoriesIdAndName(categories) {
	var temp = {};
	Object.keys(categories).map((key) => (temp[key] = categories[key].name));
	return temp;
}

export default function AreasAndRulesTableList({ venue, worksheetId }) {
	const [ columns ] = React.useState([
		{
			title: 'Terület',
			field: 'area',
			width: '40%'
		},
		{
			title: 'Kategória',
			field: 'category',
			width: '39%'
		},
		{
			title: 'Pozíció',
			field: 'position',
			width: '39%'
		}
	]);
	const [ data, setData ] = React.useState([]);
	const [ venueId, setVenueId ] = React.useState('');

	useEffect(() => {
		generateDataFromVenue();
	}, []);

	const generateDataFromVenue = () => {
		if (venueId != venue.id || data.length < 1) {
			var rows = [];
			Object.values(venue.positions).forEach((position) => {
				Object.keys(position.areas).forEach((areaKey) => {
					if (Object.keys(position.areas[areaKey]).includes(worksheetId)) {
						if (venue.areas[areaKey]) {
							let area = venue.areas[areaKey];
							rows.push({
								area: getAreaName(area),
								category: area.category != null ? venue.categories[area.category].name : '',
								position: position.name
							});
						}
					}
				});
			});
			setVenueId(venue.id);
			setData(rows);
		}
	};

	return (
		<div>
			<MaterialTable
				icons={tableIcons}
				columns={columns}
				data={data}
				options={{
					paging: false,
					grouping: true,
					padding: 'dense'
				}}
			/>
		</div>
	);
}
