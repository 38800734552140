import React from 'react';
import { withStyles } from '@mui/styles';
import { useTheme } from '@mui/material/styles';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import MuiDialogTitle from '@mui/material/DialogTitle';
import MuiDialogContent from '@mui/material/DialogContent';
import MuiDialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import TransferTemplatesList from './TransferTemplatesList';
const styles = (theme) => ({
	root: {
		margin: 0,
		padding: useTheme().spacing(2)
	},
	closeButton: {
		position: 'absolute',
		right: useTheme().spacing(1),
		top: useTheme().spacing(1),
		color: useTheme().palette.grey[500]
	}
});

const DialogTitle = withStyles(styles)((props) => {
	const { children, classes, onClose, ...other } = props;
	return (
		<MuiDialogTitle disableTypography className={classes.root} {...other}>
			<Typography variant="h6">{children}</Typography>
			{onClose ? (
				<IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
					<CloseIcon />
				</IconButton>
			) : null}
		</MuiDialogTitle>
	);
});

const DialogContent = withStyles((theme) => ({
	root: {
		padding: useTheme().spacing(2)
	}
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
	root: {
		margin: 0,
		padding: useTheme().spacing(1)
	}
}))(MuiDialogActions);

export default function AddTemplateToCategoryDialog({
	onClose,
	open,
	venue,
	categoryKey,
	updateBrowserHistory,
	templatesMap
}) {
	return (
		<div>
			<Dialog onClose={onClose} aria-labelledby="customized-dialog-title" open={open}>
				<DialogTitle id="customized-dialog-title" onClose={onClose}>
					{venue.categories[categoryKey].name}
				</DialogTitle>
				<DialogContent dividers>
					<TransferTemplatesList
						venue={venue}
						categoryKey={categoryKey}
						templatesMap={templatesMap}
						updateBrowserHistory={updateBrowserHistory}
						onClose={onClose}
					/>
				</DialogContent>
			</Dialog>
		</div>
	);
}
