import React, { useEffect } from 'react';
import { makeStyles } from '@mui/styles';

import PlusImage from './plus.png';

import { Avatar, Grid, Typography, ButtonBase } from '@mui/material';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexWrap: 'wrap',
		minWidth: 300,
		width: '100%'
	},
	base: {
		border: '0px solid #32a1ce',
		borderRadius: 25,
		height: 180,
		// backgroundColor: theme.palette.common.white,
		// [theme.breakpoints.down('xs')]: {
		// 	width: '100% !important', // Overrides inline-style
		// 	height: 100
		// },
		'&:hover, &$focusVisible': {
			border: '0px solid',
			opacity: 0.9
		}
	},
	focusVisible: {}
}));

export default function AddNewVenueCard({ venue, onClick }) {
	const classes = useStyles();

	return (
		<ButtonBase
			focusRipple
			onClick={() => onClick(venue)}
			className={classes.base}
			focusVisibleClassName={classes.focusVisible}
			style={{
				width: 150
			}}
		>
			<Grid container direction="column" justifyContent="space-between" alignItems="center">
				<Grid item>
					<Avatar variant="square" alt="AddNewVenue" src={PlusImage} sx={{ width: 56, height: 56 }} />
				</Grid>
				<Grid item>
					<Typography component="span" variant="subtitle2" color="default">
						Új hozzáadása
					</Typography>
				</Grid>
			</Grid>
		</ButtonBase>
	);
}
