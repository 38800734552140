import React, { useEffect, forwardRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { getWorkLogsOnVenueForReport } from '../api/manager';
import { getSelectedOrg } from '../firebase/auth';

import MaterialTable from '@material-table/core';
import AddBox from '@mui/icons-material/AddBox';
import ArrowUpward from '@mui/icons-material/ArrowUpward';
import Check from '@mui/icons-material/Check';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';
import Clear from '@mui/icons-material/Clear';
import DeleteOutlined from '@mui/icons-material/DeleteOutline';
import Edit from '@mui/icons-material/Edit';
import Delete from '@mui/icons-material/Delete';
import FilterList from '@mui/icons-material/FilterList';
import FirstPage from '@mui/icons-material/FirstPage';
import LastPage from '@mui/icons-material/LastPage';
import Remove from '@mui/icons-material/Remove';
import SaveAlt from '@mui/icons-material/SaveAlt';
import Search from '@mui/icons-material/Search';
import ViewColumn from '@mui/icons-material/ViewColumn';
import { ExportCsv, ExportPdf } from '@material-table/exporters';

import { getAreaName,getUserName } from '../utilities/StringHelper';

import DateRangePicker from './datepickers/DateRangePicker';

import { Grid, LinearProgress } from '@mui/material';

import moment from 'moment';

const tableIcons = {
	Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
	Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
	Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
	Delete: forwardRef((props, ref) => <Delete {...props} ref={ref} />),
	DeleteOutlined: forwardRef((props, ref) => <DeleteOutlined {...props} ref={ref} />),
	DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
	Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
	Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
	Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
	FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
	LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
	NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
	PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
	ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
	Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
	SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref} />),
	ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
	ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'block',
		flexWrap: 'wrap',
		padding: 10
	}
}));


export default function MonthlyWorkLogsOnVenue({ venue, venueId}) {
	const classes = useStyles();
	const [ workLogs, setWorkLogs ] = React.useState([]);
	const [ isLoading, setIsLoading ] = React.useState(true);
	const [fromDate,setFromDate] = React.useState("2024-01-01");
	const [endDate,setEndDate] = React.useState(moment().format('YYYY-MM-DD'));

	const [data,setData] = React.useState([]);
	const [ columns ] = React.useState([
		{title: 'Létesítmény', field: 'venueName'},
		{title: 'Területek', field: 'area'},
		{title: 'Területek kategória', field: 'areaCategory'},
		{title: 'Felhasználó', field: 'user'},
		{title: 'Kezdés dátum', field: 'startDate'},
		{title: 'Kezdés idő', field: 'startTime'},
		{title: 'Befejezés Dátum', field: 'endDate'},
		{title: 'Befejezés Idő', field: 'endTime'},
		{title: 'Munka hossza (perc)', field: 'duration'},
		{title: 'Terület m2', field: 'areaSize'},
		{title: 'Feltöltve', field: 'createdAt'}
	]);

	useEffect(() => {
		if (workLogs.length < 1) fetchWorkLogs();
	}, []);


	const handleFilterButtonClickAction = () => {
		fetchWorkLogs()
		// refreshReportsByVenue(venue);
	};

	const setFromDateAction = (value) => {
		// history.push({
		// 	pathname: `${history.location.pathname}`,
		// 	search: `?id=${venue.id}&fromDate=${value}&toDate=${toDate}`,
		// 	state: { venue: venue }
		// });
		setFromDate(value);
	};

	const setToDateAction = (value) => {
		// history.push({
		// 	pathname: `${history.location.pathname}`,
		// 	search: `?id=${venue.id}&fromDate=${fromDate}&toDate=${value}`,
		// 	state: { venue: venue }
		// });
		setEndDate(value);
	};


	const fetchWorkLogs = () => {
		setIsLoading(true);
		getWorkLogsOnVenueForReport(getSelectedOrg(), venueId,fromDate,endDate)
			.then((result) => {
				var rows = [];
				result.data.results.forEach(function(worklog, index) {
					//Add to worklogsobject
					rows.push({
						area: worklog.area != null ? getAreaName(worklog.area) : "Törölt terület",
						createdAt: moment(worklog.createdAt).format("YYYY-MM-DD HH:mm:ss"),
						areaId: worklog.areaId,
						createdBy: worklog.createdBy,
						org: worklog.org,
						areaSize: worklog.area  && worklog.areaId ? venue.areas[worklog.areaId].size :"",
						areaCategory: worklog.area && worklog.area.category != null ? venue.categories[worklog.area.category].name : "",
						venueId: worklog.venueId,
						// startTime: moment(worklog.startTime).format("YYYY-MM-DD HH:mm:ss"),
						startDate: moment(worklog.startTime).format("YYYY-MM-DD"),
						startTime: moment(worklog.startTime).format("HH:mm:ss"),
						endDate: worklog.endTime != null ? moment(worklog.endTime).format("YYYY-MM-DD"):"",
						endTime: worklog.endTime != null ? moment(worklog.endTime).format("HH:mm:ss"):"",
						duration: worklog.endTime != null ? Math.round(moment.duration(moment(worklog.endTime).diff(moment(worklog.startTime))).asMinutes()):"",
						venueName: worklog.venueName,
						user:  getUserName(worklog.user),
						id: index,
						key: index	
					});
				});
				const test = 0
				setData(rows);
				setIsLoading(false);
			})
			.catch((error) => {
				console.log('Error happened in Venues.js while retriving Venues' + error);
				setIsLoading(false);
			});
	};


	return (
		<div className={classes.root}>
			{isLoading ? (
				<LinearProgress />
			) : (
				<div>
					<Grid justifyContent="space-between" container spacing={24}>
						<Grid item>
							<h2>Naplóbejegyzések</h2>
						</Grid>
						<Grid item>
							<DateRangePicker
								fromDate={fromDate}
								toDate={endDate}
								setFromDate={setFromDateAction}
								setToDate={setToDateAction}
								selectFilterAction={handleFilterButtonClickAction}
							/>
						</Grid>
					</Grid>
					<br />
					{data.length == 0 ? "Nincsa adat":
					<MaterialTable
					icons={tableIcons}
					title="Naplóbejegyzések"
					columns={columns}
					data={data}
					options={{
						exportButton: true,
						toolbar: true,
						size: 'small',
						paging: false,
						filtering: true,
						// addRowPosition: 'first',
						padding: 'dense',
						exportMenu: [
							{
								label: 'Export PDF',
								exportFunc: (cols, datas) => ExportPdf(cols, datas, 'Naplóbejegyzések')
							},
							{
								label: 'Export CSV',
								exportFunc: (cols, datas) => ExportCsv(cols, datas, 'Naplóbejegyzések')
							}
						]
					}}
					/>
				}
				</div>
			) }
		</div>
	);
}
