import React from 'react';

export default class UnAuthorized extends React.Component {
	render() {
		const style = {
			height: 300,
			display: 'flex',
			alignItems: 'center'
		};
		return (
			<div>
				<div style={style}>
					<div>
						<div className={'container '}>
							<h5 className={'teal-text'}>Hopsz.. Nincs jogod megnezni ezt az oldalt. </h5>
							<p>Lorep ipsum dolor sit...</p>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
