import React, { useEffect } from 'react';
import { makeStyles } from '@mui/styles';

import { getAreaName, getAreaNameWithoutBuilding } from '../StringHelper';
import { Grid, Box, Typography, ButtonBase, Card } from '@mui/material';

export default function VenueCard({ workLog, isOpenWorkLog, venueSettings }) {
    const getDateTextBasedOnVenueSettings = (venueSettings,worklog) => {
		var returndatetext = worklog.startTime
		if(venueSettings != null && venueSettings.trackCloseTimeOnWorkLogs == true){
			if(worklog.endTime){
				returndatetext += " - " + worklog.endTime + "  (" + worklog.duration +" perc)"
			} else {
				returndatetext += " -"
			}
		}
		return returndatetext
	}
	return (
		<Card style={{height:'150px', width:'120px'}}>
             <Box sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}>
                <Grid container spacing={2} direction="column">
                    <Grid item>
                        {/* <Typography variant="button" display="block">
                        {workLog.area != null && workLog.area.building != null ? `${workLog.area.building} épület` : 'TÖRÖLT TERÜLET'}
                        </Typography> */}
                        <Typography variant="button" display="block">
                            {workLog.area && getAreaName(workLog.area)}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography component="span" variant="subtitle2" color="primary" style={{fontSize:"16px"}}>
                            {getDateTextBasedOnVenueSettings(venueSettings,workLog)}
                        </Typography>
                    </Grid>
                    <Grid item>
                        {isOpenWorkLog && (<Typography component="span" color='secondary' variant="button" >
                            FOLYAMATBAN
                        </Typography>)}
                        
                    </Grid>
                </Grid>
            </Box>
		</Card>
	);
}
