import React, { useEffect } from 'react';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

import UUID from 'uuid/v4';

import DateRangeOutlinedIcon from '@mui/icons-material/DateRangeOutlined';
import {
	getWorksheetsForOrgOnVenue,
	getWorkerPositionsForOrgOnVenue,
	updateAreasOnWorkerPosition,
	updateWorkerPosition,
	deleteWorkerPosition
} from '../../../api/manager';

import { getSelectedOrg } from '../../../firebase/auth';

import { Grid, Typography, IconButton, Select, MenuItem, Paper, InputLabel, Divider, Box, Button } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import AreasAndActivityList from './AreasAndActivityList';
import AddNewAreasToWorkerPosition from './Edit/AddNewAreasToWorkerPosition';
import PositionSchedule from './PositionSchedule';

function ManageWorkerPositions({ venue,cleaningActivities }) {
	const [ worksheets, setWorksheets ] = React.useState([]);
	const [ isWorksheetsLoading, setIsWorksheetsLoading ] = React.useState(true);
	const [ isPositionsLoading, setIsPositionsLoading ] = React.useState(true);
	const [ selectedPosition, setSelectedPosition ] = React.useState();
	const [ isOpenDialog, setIsOpenDialog ] = React.useState(false);
	const [ newPositionId, setNewPositionId ] = React.useState({});

	useEffect(() => {
		if (venue.worksheets == null) {
			fetchWorksheets();
		} else {
			setWorksheets(venue.worksheets);
			setIsWorksheetsLoading(false);
		}
		if (venue.positions == null) {
			fetchWorkerPositions();
		} else {
			// setWorkerPositions(venue.positions);
			setIsPositionsLoading(false);
			setSelectedPosition(Object.keys(venue.positions)[0]);
		}
	}, []);

	const fetchWorksheets = () => {
		setIsWorksheetsLoading(true);
		getWorksheetsForOrgOnVenue(getSelectedOrg(), venue.id)
			.then((result) => {
				setWorksheets(result);
				setIsWorksheetsLoading(false);
				venue.worksheets = result;
			})
			.catch((error) => {
				console.log('Error happened in ManageWorkerPosition.js while retriving worksheets' + error);
			});
	};

	const addNewPosition = () => {
		var id = UUID();
		venue.positions[id] = {
			name: 'undefined',
			areas: {},
			schedule: {},
			id: id
		};
		setNewPositionId(id);
		setSelectedPosition(id);
		setIsOpenDialog(true);
	};

	const saveNewAreasOnWorkerPosition = (workerPositionId, areas) => {
		setIsPositionsLoading(true);
		venue.positions[workerPositionId].areas = areas;
		updateWorkerPosition(getSelectedOrg(), venue.id, workerPositionId, venue.positions[workerPositionId])
			.then((result) => {
				setIsPositionsLoading(false);

				// setWorkerPositions(venue.positions);
			})
			.catch((error) => {
				console.log('Error happened in ManageWorkerPositions.js while update workerposition' + error);
			});
	};

	const deletePosition = () => {
		var positionToDelete = selectedPosition;
		setIsPositionsLoading(true);
		delete venue.positions[positionToDelete];
		setSelectedPosition(Object.keys(venue.positions)[0]);
		deleteWorkerPosition(getSelectedOrg(), venue.id, positionToDelete)
			.then((result) => {
				if (Object.keys(venue.positions).length > 0) {
					setSelectedPosition(Object.keys(venue.positions)[0]);
				} else {
					setSelectedPosition(null);
				}
				setIsPositionsLoading(false);
			})
			.catch((error) => {
				console.log('Error happened in ManageWorkerPositions.js while deleting workerposition' + error);
			});
	};

	const fetchWorkerPositions = () => {
		setIsPositionsLoading(true);
		getWorkerPositionsForOrgOnVenue(getSelectedOrg(), venue.id)
			.then((result) => {
				venue.positions = result;
				setSelectedPosition(Object.keys(venue.positions)[0]);
				setIsPositionsLoading(false);
			})
			.catch((error) => {
				console.log('Error happened in ManageWorkerPositions.js while retriving templates' + error);
			});
	};

	const handleChange = (event) => {
		setSelectedPosition(event.target.value);
	};

	return (
		<div>
			{isOpenDialog && (
				<AddNewAreasToWorkerPosition
					venue={venue}
					position={venue.positions[selectedPosition]}
					newPositionId={newPositionId}
					setIsOpenDialog={setIsOpenDialog}
					isOpenDialog={isOpenDialog}
					saveNewAreasOnWorkerPosition={saveNewAreasOnWorkerPosition}
					worksheets={worksheets}
					cleaningActivities={cleaningActivities}
				/>
			)}
			<Grid  container alignItems="flex-start" justifyContent="flex-end">
				<Grid item xs={10}>
					<Typography align="left" variant="h6">
						Pozíció
					</Typography>
				</Grid>
				{/* <Grid item xs={1}>
					<IconButton
						onClick={() => {
							// openCalendarView(true);
						}}
						aria-label="open"
					>
						<DateRangeOutlinedIcon />
					</IconButton>
				</Grid> */}
				<Grid item xs={2}>
					<Button
						variant="contained"
						onClick={() => {
							addNewPosition();
						}}
					>
						Új Pozíció
					</Button>
				</Grid>
				<Grid item xs={12}>
					{isPositionsLoading || isWorksheetsLoading ? (
						<Paper>
							<React.Fragment>
								<Skeleton
									animation="wave"
									variant="rect"
									width="100%"
									height={100}
									style={{ marginBottom: 15 }}
								/>
							</React.Fragment>
						</Paper>
					) : venue.positions && Object.keys(venue.positions).length > 0 ? (
						// <Paper>
						<div>
						<Paper>
							<Grid
								container
								key={venue.positions}
								spacing={2}
								alignItems="flex-start"
								justifyContent="center"
							>
								<Grid item xs={2}>
									<Box m={1}>
										<InputLabel htmlFor="age-native-simple">Pozíció</InputLabel>
										<Select
											labelId="positions-select-label"
											id="positions-select"
											value={selectedPosition}
											onChange={handleChange}
											inputProps={{
												name: 'Pozíció',
												id: 'worker-position'
											}}
										>
											{Object.keys(venue.positions).map((key) => (
												<MenuItem key={key} value={key}>
													{venue.positions[key].name}
												</MenuItem>
											))}
										</Select>
									</Box>
								</Grid>
								<Grid item xs={8}>
									<Grid
										container
										direction="column"
										spacing={1}
										alignItems="center"
										justifyContent="center"
									>
										<Grid item>
											<Typography color="textSecondary" align="center" variant="body2">
												Beosztás
											</Typography>
										</Grid>
										<Grid item>
											<PositionSchedule position={venue.positions[selectedPosition]} />
										</Grid>{' '}
									</Grid>
								</Grid>
								<Grid item xs={1}>
									<Box m={1}>
										{selectedPosition ? (
											<IconButton
												onClick={() => {
													setIsOpenDialog(true);
												}}
												aria-label="edit"
											>
												<EditIcon />
											</IconButton>
										) : (
											''
										)}
									</Box>
								</Grid>
								<Grid item xs={1}>
									<Box m={1}>
										{selectedPosition ? (
											<IconButton
												onClick={() => {
													if (window.confirm('Biztosan törölni szeretné a Pozíciót?'))
														deletePosition();
												}}
												aria-label="delete"
											>
												<DeleteIcon />
											</IconButton>
										) : (
											''
										)}
									</Box>
								</Grid>
								<Grid item xs={12}>
									<Divider />
								</Grid>
								<Grid item xs={12}>
									<Box m={1}>
										{selectedPosition && (
											<AreasAndActivityList
												positionAreas={venue.positions[selectedPosition].areas}
												worksheets={worksheets}
												cleaningActivities={cleaningActivities}
												venue={venue}
											/>
										)}
									</Box>
								</Grid>
							</Grid>
							</Paper>
							</div>
					) : (
						<Box m={3}>
							<Typography align="center" variant="h5">
								Jelenleg nincs pozíció létrehozva. Új pozíció létrehozásához kattints a + gombra a jobb
								felső sarokban!
							</Typography>
						</Box>
					)}
				</Grid>
				<Grid item xs={12} />
			</Grid>
		</div>
	);
}

export default ManageWorkerPositions;
