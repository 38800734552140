import React, { useEffect } from 'react';
import UUID from 'uuid/v4';
import EditableMasterCleaningActivitiesTableList from './EditableMasterCleaningActivitiesTableList';
import {
	deleteMasterCleaningActivitiesForOrg,
	updateMasterCleaningActivitiesForOrg,
	getCleaningActivitiesForOrg
} from '../../api/manager';
import { getSelectedOrg } from '../../firebase/auth';
import LoadingDialog from '../../utilities/LoadingDialog';
import Snackbar from '@mui/material/Snackbar';
import { useLocation, useHistory } from 'react-router-dom';

import { Grid, Button } from '@mui/material';
import MuiAlert from '@mui/material/Alert';

// function CustomAlert(props) {
// 	return <Alert elevation={6} variant="filled" {...props} />;
// }
const Alert = React.forwardRef(function Alert(props, ref) {
	return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function MasterCleaningActivitiesManager() {
	const [ dataChanged, setDataChanged ] = React.useState(false);
	const [ masterCleaningActivities, setMasterCleaningActivities ] = React.useState({});
	const [ masterCleaningActivitiesToUpdate, setMasterCleaningActivitiesToUpdate ] = React.useState({});
	const [ openLoadingDialog, setOpenLoadingDialog ] = React.useState(false);
	const [ snackBar, setSnackBar ] = React.useState({
		snackOpen: false,
		snackMessage: '',
		severity: 'error'
	});
	const location = useLocation();
	const history = useHistory();

	useEffect(() => {
		fetchMasterCleaningActivities();
	}, []);

	const updateMasterCleaningActivities = () => {
		setOpenLoadingDialog(true);
		updateMasterCleaningActivitiesForOrg(getSelectedOrg(), masterCleaningActivitiesToUpdate)
			.then((result) => {
				setOpenLoadingDialog(false);
				setDataChanged(false);

				updateBrowserHistory();
				setMasterCleaningActivitiesToUpdate({});
				setSnackBar({
					snackOpen: true,
					snackMessage: 'Sikeresen mentve!',
					severity: 'success'
				});
			})
			.catch((error) => {
				setOpenLoadingDialog(false);
				setSnackBar({
					snackOpen: true,
					snackMessage: error.toString(),
					severity: 'error'
				});
				console.log('Error happened while saving Areas' + error);
			});
	};

	const deleteCleaningActivitiesOnOrg = (cleaningActivitiesToDelete) => {
		setOpenLoadingDialog(true);
		deleteMasterCleaningActivitiesForOrg(getSelectedOrg(), cleaningActivitiesToDelete)
			.then((result) => {
				setOpenLoadingDialog(false);
				setDataChanged(false);
				updateBrowserHistory();

				setSnackBar({
					snackOpen: true,
					snackMessage: 'Sikeresen törölve!',
					severity: 'success'
				});
			})
			.catch((error) => {
				setOpenLoadingDialog(false);
				setSnackBar({
					snackOpen: true,
					snackMessage: error.toString(),
					severity: 'error'
				});
				console.log('Error happened while deleting Areas' + error.toString());
			});
	};

	const updateBrowserHistory = () => {
		const tempState = location.state;
		history.replace({ pathname: location.pathname, search: location.search, state: tempState });
	};

	const handleClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}

		setSnackBar({ snackOpen: false });
	};

	const renderSnackBar = () => {
		return (
			<Snackbar
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'center'
				}}
				open={snackBar.snackOpen}
				autoHideDuration={6000}
				onClose={handleClose}
			>
				<Alert onClose={handleClose} severity={snackBar.severity}>
					{snackBar.snackMessage}
				</Alert>
			</Snackbar>
		);
	};

	const addMasterCleaningActivitiesToUpdateList = (cleaningActivityToAdd, key) => {
		var tempMasterCleaningActivitiesToUpdate = { ...masterCleaningActivitiesToUpdate };
		delete cleaningActivityToAdd.id;
		delete cleaningActivityToAdd.tableData;
		tempMasterCleaningActivitiesToUpdate[key] = cleaningActivityToAdd;

		setMasterCleaningActivitiesToUpdate(tempMasterCleaningActivitiesToUpdate);
	};

	const fetchMasterCleaningActivities = () => {
		setOpenLoadingDialog(true);
		getCleaningActivitiesForOrg(getSelectedOrg())
			.then((result) => {
				setOpenLoadingDialog(false);
				setMasterCleaningActivities(result);
			})
			.catch((error) => {
				setOpenLoadingDialog(false);
				setSnackBar({
					snackOpen: true,
					snackMessage:
						'Error happened in MasterCleaningActivitiesManager.js while retriving Master Cleaning Activities' +
						error.toString(),
					severity: 'error'
				});
				console.log(
					'Error happened in MasterCleaningActivitiesManager.js while retriving Master Cleaning Activities' +
						error
				);
			});
	};

	return (
		<Grid container alignItems="flex-start" justifyContent="flex-end">
			{renderSnackBar()}
			<LoadingDialog open={openLoadingDialog} title="Töltés folyamatban..." />
			<Grid item xs={12}>
				<h4>Módosítások mentéséhez kattints a mentés gombra!</h4>{' '}
				<Button disabled={!dataChanged} color="primary" onClick={updateMasterCleaningActivities}>
					Mentés
				</Button>
			</Grid>
			<Grid item xs={12}>
				<EditableMasterCleaningActivitiesTableList
					masterCleaningActivities={masterCleaningActivities}
					addMasterCleaningActivitiesToUpdateList={addMasterCleaningActivitiesToUpdateList}
					setDataChanged={setDataChanged}
					deleteCleaningActivitiesOnOrg={deleteCleaningActivitiesOnOrg}
				/>
			</Grid>
		</Grid>
	);
}

export default MasterCleaningActivitiesManager;
