import React, { useEffect, forwardRef, useRef } from 'react';
import MaterialTable, { MTableToolbar } from '@material-table/core';
import Check from '@mui/icons-material/Check';
import Clear from '@mui/icons-material/Clear';

import AddBox from '@mui/icons-material/AddBox';
import ArrowUpward from '@mui/icons-material/ArrowUpward';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';
import DeleteOutlined from '@mui/icons-material/DeleteOutline';
import Edit from '@mui/icons-material/Edit';
import Delete from '@mui/icons-material/Delete';
import FilterList from '@mui/icons-material/FilterList';
import FirstPage from '@mui/icons-material/FirstPage';
import LastPage from '@mui/icons-material/LastPage';
import Remove from '@mui/icons-material/Remove';
import SaveAlt from '@mui/icons-material/SaveAlt';
import Search from '@mui/icons-material/Search';
import ViewColumn from '@mui/icons-material/ViewColumn';
import { Button, Grid, Box, Dialog, Typography } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux'


const columns = [
	{
		title: 'Terület',
		field: 'area',
		width: 200
	},
	{
		title: 'Feladat',
		field: 'description',
		width: 100
	},
];

const tableIcons = {
	Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
	Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
	Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
	Delete: forwardRef((props, ref) => <Delete {...props} ref={ref} />),
	DeleteOutlined: forwardRef((props, ref) => <DeleteOutlined {...props} ref={ref} />),
	DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
	Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
	Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
	Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
	FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
	LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
	NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
	PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
	ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
	Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
	SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref} />),
	ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
	ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

export default function ToDoAdHocTasks({ venueId }) {
	const [ rows, setRows ] = React.useState([]);
	// const dispatch = useDispatch()
	const allAdHocTasks = useSelector((state) => state.adhocTasks[venueId])

	const tableRef = useRef();

	useEffect(() => {
		// generateAreasWithActivities();
		generateDataFromTasks();
	}, []);


	const generateDataFromTasks = () => {
		var rows = [];
		// Object.keys(areasWithActivities).forEach(function(key) {
		// 	let area = venue.areas[key];
		// 	rows.push({
		// 		name: `${area.building ? area.building + ' épület' : ''} ${area.floor
		// 			? area.floor + '. emelet'
		// 			: ''} ${area.name} ${area.number}`,
		// 		category: venue.categories[area.category] ? venue.categories[area.category].name : '',
		// 		worksheets: worksheets,
		// 		positionArea: areasWithActivities[key],
		// 		id: key
		// 	});
		// });
		setRows(rows);
	};

	return (
		<div style={{ height: '100%', width: '100%' }}>
			<MaterialTable
				tableRef={tableRef}
				icons={tableIcons}
				columns={columns}
				data={rows}
				options={{
					// cellStyle: { padding: '0.3em' },
					// headerStyle: { padding: '0.3em' },
					showTitle: false,
					filtering: true,
					sorting: true,
					search: false,
					paging: false,
					padding: 'dense',
				}}
				// components={{
				// 	Toolbar: (props) => (
				// 		<div>
				// 			<MTableToolbar {...props} />
				// 			<div style={{ padding: '0px 5px' }}>
				// 				{selectedRows.length > 0 ? (
				// 					<Button
				// 						variant="contained"
				// 						color="secondary"
				// 						onClick={() => {
				// 							addCleaningActivites();
				// 						}}
				// 					>
				// 						Feladatlista hozzárendelése
				// 					</Button>
				// 				) : (
				// 					''
				// 				)}
				// 			</div>
				// 		</div>
				// 	)
				// }}
			/>
		</div>
	);
}
