import { firebaseAuth, googleProvider } from './firebase';
import { getMe } from '../api/manager';

const firebaseAuthKey = 'firebaseAuthInProgress';
const firebaseUserKey = 'firebaseUserKey';
const userKey = 'cleanspectUser';
const orgKey = 'cleanspectOrg';

export function loginWithEmailAndPassword(email, password) {
	return firebaseAuth()
		.signInWithEmailAndPassword(email, password)
		.then((result) => {
			localStorage.setItem(firebaseAuthKey, '1');
			localStorage.setItem(firebaseUserKey, result.user.email);

			return getMe();
		})
		.then((result) => {
			saveLoggedInUserData(result);
			setSelectedOrg(result.org[0]);
			return;
		});
}

export function logout() {
	localStorage.removeItem(firebaseAuthKey);
	return firebaseAuth().signOut();
}

export function isLoggedIn(roles) {
	var authorized = false;
	var user;
	try {
		user = JSON.parse(localStorage.getItem('cleanspectUser'));
		if (user && localStorage.getItem(firebaseAuthKey) === '1') {
			if (roles) {
				roles.forEach((role) => {
					if (user.roles[role]) {
						authorized = true;
					}
				});
			} else {
				authorized = true;
			}
		}
	} catch (e) {
		// alert(e); // error in the above string (in this case, yes)!
	}
	return authorized;
}

export function getLoggedInUserRoles() {
	var user = JSON.parse(localStorage.getItem('cleanspectUser'));
	return user.roles;
}

export function getLoggedInUserEmail() {
	return localStorage.getItem(firebaseUserKey);
}

export function saveLoggedInUserData(user) {
	localStorage.setItem(userKey, JSON.stringify(user));
	console.log(JSON.parse(localStorage.getItem(userKey)).org[0]);
}

// export function getLoggedInUserOrg() {
// 	return new Promise(function(resolve) {
// 		getLoggedInUser().then((result) => {
// 			resolve(getSelectedOrg());
// 		});
// 	});
// }
export function getLoggedInUserOrgs() {
	return getLoggedInUser().org;
}

export function getLoggedInUser() {
	return JSON.parse(localStorage.getItem(userKey));
}

export function getSelectedOrg() {
	return localStorage.getItem(orgKey);
}
export function setSelectedOrg(org) {
	return localStorage.setItem(orgKey, org);
}
