import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

const useRowStyles = makeStyles({
	root: {
		'& > *': {
			borderBottom: 'unset'
		}
	}
});

function Row({ key, row, venueSettings }) {
	const [ open, setOpen ] = React.useState(false);
	const classes = useRowStyles();

	return (
		<React.Fragment>
			<TableRow className={classes.root}>
				<TableCell>
					<IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
						{open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
					</IconButton>
				</TableCell>
				<TableCell component="th" scope="row">
					{row.area}
				</TableCell>
				<TableCell align="right">{row.count}</TableCell>
			</TableRow>
			<TableRow>
				<TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
					<Collapse in={open} timeout="auto" unmountOnExit>
						<Box margin={1}>
							<Table size="small" aria-label="purchases">
								<TableHead>
									<TableRow>
										<TableCell width="5%">Kezdés</TableCell>
										{venueSettings != null && venueSettings.trackCloseTimeOnWorkLogs && <TableCell width="5%">Befejezés</TableCell>}

										<TableCell width="80%">Név</TableCell>
										{venueSettings != null && venueSettings.trackCloseTimeOnWorkLogs && <TableCell width="10%" align="right">Munka hossza</TableCell>}
									</TableRow>
								</TableHead>
								<TableBody>
									{row.history.map((historyRow) => (
										<TableRow key={historyRow.date}>
											<TableCell component="th" scope="row">
												{historyRow.startTime}
											</TableCell>
											{venueSettings != null && venueSettings.trackCloseTimeOnWorkLogs && <TableCell component="th" scope="row">
												{historyRow.endTime}
											</TableCell>}
											<TableCell>{historyRow.name}</TableCell>
											{venueSettings != null && venueSettings.trackCloseTimeOnWorkLogs &&<TableCell align="right">{historyRow.duration} {historyRow.endTime && "perc"}</TableCell>}
										</TableRow>
									))}
								</TableBody>
							</Table>
						</Box>
					</Collapse>
				</TableCell>
			</TableRow>
		</React.Fragment>
	);
}

Row.propTypes = {
	row: PropTypes.shape({
		count: PropTypes.number.isRequired,
		history: PropTypes.arrayOf(
			PropTypes.shape({
				startTime: PropTypes.string.isRequired,
				endTime: PropTypes.string.isRequired,
				name: PropTypes.string.isRequired,
				duration: PropTypes.number.isRequired
			})
		).isRequired,
		name: PropTypes.string.isRequired
	}).isRequired
};

export default function CollapsibleTable({ data, venueSettings }) {
	return (
		<TableContainer component={Paper}>
			<Table aria-label="collapsible table">
				<TableHead>
					<TableRow>
						<TableCell />
						<TableCell>Terület</TableCell>
						<TableCell align="right">Takarítások száma aznap</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{data.length > 0 ? data.map((row) => <Row key={row.id} row={row} venueSettings={venueSettings} />) : 'Nincs adat erre a napra!'}
				</TableBody>
			</Table>
		</TableContainer>
	);
}
