import React from 'react';
import { saveLoggedInUserData, isLoggedIn, loginWithEmailAndPassword } from '../../firebase/auth';
import { getMe } from '../../api/manager';

import { withStyles } from '@mui/styles';
import { useTheme } from '@mui/material/styles';

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormGroup from '@mui/material/FormGroup';
import LoadingDialog from '../../utilities/LoadingDialog';

import { Provider, Heading, Subhead, Small } from 'rebass';
import {
	Hero,
	ScrollDownIndicator,
	Flex,
	Feature,
	Laptop,
	Testimony,
	PricingTier,
	CallToAction,
	Section
} from 'react-landing-page';

import Snackbar from '@mui/material/Snackbar';
import MySnackbarContentWrapper from '../../utilities/MySnackBarContent';

// import { createHashHistory } from 'history';

// export const history = createHashHistory();

const INITIAL_STATE = {
	email: '',
	password: '',
	error: null
};

const CssTextField = withStyles({
	root: {
		'& .MuiFormLabel-root': {
			color: 'white'
		},
		'& label.Mui-focused': {
			color: 'white'
		},
		'& .MuiInput-underline:after': {
			borderBottomColor: 'white'
		},
		'& .MuiOutlinedInput-root': {
			'& fieldset': {
				borderColor: 'red'
			},
			'&:hover fieldset': {
				borderColor: 'yellow'
			},
			'&.Mui-focused fieldset': {
				borderColor: 'green'
			}
		}
	}
})(TextField);

const styles = (theme) => ({
	root: {
		// display: 'flex',
	},
	toolbar: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end',
		// padding: '0 0px',
		...useTheme().mixins.toolbar
	},
	input: {
		color: '#ffffff',
		fontSize: 15
	},
	floatingLabelFocusStyle: {
		color: '#ffffff'
	},
	textField: {
		marginLeft: useTheme().spacing.unit,
		marginRight: useTheme().spacing.unit,
		borderColor: '#ffffff'
	},
	menuButton: {
		marginLeft: 10,
		marginRight: 20,
		borderColor: '#ffffff'
	},
	content: {
		flexGrow: 1,
		paddingleft: useTheme().spacing(3)
	},
	grow: {
		flex: 1
	}
});

class Landing extends React.Component {
	constructor(props) {
		super(props);

		this.state = { ...INITIAL_STATE };
		this.state.openLoading = false;
	}

	showSignInProgressBar = () => {
		this.setState({ openLoading: true });
	};
	hideSignInProgressBar = () => {
		this.setState({ openLoading: false });
	};
	onSignIn = (event) => {
		const { email, password } = this.state;
		this.showSignInProgressBar();
		loginWithEmailAndPassword(email, password)
			.then((result) => {
				return getMe();
			})
			.then((result) => {
				saveLoggedInUserData(result);
				this.hideSignInProgressBar();
				this.onEnter();
			})
			.catch((error) => {
				this.setState({ snackOpen: true, snackMessage: error.toString(), snackType: 'error' });
				console.log('Error: ' + error);
				this.setState({ error: error });
				this.hideSignInProgressBar();
			});
	};

	onEnter = (event) => {
		this.props.history.push('/home');
	};

	handleClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}

		this.setState({ snackOpen: false });
	};
	handleChange = (name) => (event) => {
		this.setState({ [name]: event.target.value });
	};

	renderAuth() {
		const { classes } = this.props;
		if (!isLoggedIn()) {
			return (
				<div>
					<LoadingDialog open={this.state.openLoading} title="Bejelentkezés folyamatban" />
					<FormGroup row>
						<CssTextField
							id="filled-email-input"
							label="Email"
							name="email"
							type="email"
							autoComplete="email"
							className={classes.textField}
							// value={email}
							// onChange={this.onChange}
							value={this.state.email}
							onChange={this.handleChange('email')}
							InputProps={{
								className: classes.input
							}}
						/>
						<CssTextField
							id="standard-name"
							label="Jelszó"
							type="password"
							name="password"
							className={classes.textField}
							// value={password}
							// onChange={this.onChange}
							value={this.state.password}
							onChange={this.handleChange('password')}
							InputProps={{
								className: classes.input,
								style: {
									color: '#FFFFFF'
								}
							}}
						/>
						<Button
							variant="outlined"
							className={classes.menuButton}
							color="inherit"
							onClick={() => {
								this.onSignIn();
							}}
						>
							Bejelentkezés
						</Button>
					</FormGroup>
				</div>
			);
		} else {
			return (
				<Button
					variant="outlined"
					className={classes.menuButton}
					color="inherit"
					onClick={() => {
						this.onEnter();
					}}
				>
					Belépés
				</Button>
			);
		}
	}

	renderSnackBar() {
		return (
			<Snackbar
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left'
				}}
				open={this.state.snackOpen}
				autoHideDuration={6000}
				onClose={this.handleClose}
			>
				<MySnackbarContentWrapper
					onClose={this.handleClose}
					variant={this.state.snackType}
					message={this.state.snackMessage}
				/>
			</Snackbar>
		);
	}
	renderAppBar() {
		const { classes } = this.props;
		return (
			<AppBar position="static">
				<Toolbar>
					<img src={require('../source/favicon.png')} height="42" width="42" marginLeft="10" />
					<Typography variant="h5" color="inherit">
						CleanSpect
					</Typography>
					<div className={classes.grow} />
					{this.renderAuth()}
				</Toolbar>
			</AppBar>
		);
	}
	renderFooter() {
		return (
			<Flex is="footer" alignItems="center" p={3}>
				<Small color="grey" ml="auto">
					© CleanSpect, 2019 v{React.version}
				</Small>
			</Flex>
		);
	}
	renderMain() {
		return (
			<Hero color="black" backgroundImage="https://source.unsplash.com/gz9Ez-H5asU/1600x900">
				{/* <Heading>A tisztaság érték!</Heading>
        <Subhead>CleanSpect takarítás ellenőrző rendszer.</Subhead> */}
				{/* <CallToAction href="/getting-started" mt={3}>Get Started</CallToAction> */}
				<Flex flexWrap="wrap" alignItems="center">
					<Flex alignItems="flex-start" width={[ 1, 1 / 2 ]} p={3}>
						<Laptop
							style={{ transform: 'translate(32px, 0px)' }}
							src={require('../source/cleanspect-screenshot.png')}
						/>
						{/* <Phone src='https://via.placeholder.com/187x406'
                notch style={{transform: 'translate(32px, 64px)'}} /> */}
						{/* <Phone src='https://via.placeholder.com/205x412' color='white'
                style={{transform: 'translate(-32px, 0px)'}} /> */}
					</Flex>
					<Flex width={[ 1, 1 / 2 ]} alignItems="center" flexDirection="column" p={3}>
						<Heading>A tisztaság érték!</Heading>
						<Subhead fontSize={[ 2, 3 ]}>CleanSpect takarítás ellenőrző rendszer.</Subhead>
					</Flex>
				</Flex>
				<ScrollDownIndicator />
			</Hero>
		);
	}

	renderSocialProof() {
		return (
			<Section bg="gray" heading="Mit mondanak rólunk">
				<Flex alignItems="flex-end" justifyContent="space-around">
					<Testimony
						// authorAvatar="https://media.licdn.com/dms/image/C4D03AQHrQu7jWlkkmQ/profile-displayphoto-shrink_200_200/0?e=1538006400&v=beta&t=ByDJmdnXciGKEyYXdfj4mA7WNEXCvTWhGfbFm4imqyI"
						authorName="Lőrincz István"
						authorTitle="Granpol Kft."
					>
						..... ...... ....... ......
					</Testimony>
				</Flex>
			</Section>
		);
	}

	renderPricing() {
		return (
			<Section heading="Árazás">
				<Flex justifyContent="space-around">
					<PricingTier
						bg="silver"
						tierName="Basic"
						price="Free"
						billingType="forever"
						sellingPoints={[ '🔥 Full feature set', '📑 Comprehensive docs' ]}
					>
						<CallToAction bg="black" width={1} mt="auto">
							Download
						</CallToAction>
					</PricingTier>

					<PricingTier
						bg="gold"
						tierName="Premium"
						price="$99"
						billingType="Yearly"
						sellingPoints={[
							'🔥 Full feature set',
							'📑 Comprehensive docs',
							'😌 Future updates',
							'👩‍⚖️ Commercial license'
						]}
					>
						<CallToAction bg="black" width={1}>
							Buy now
						</CallToAction>
					</PricingTier>
				</Flex>
			</Section>
		);
	}

	renderFeatures() {
		return (
			<Section heading="Amit kínálunk">
				<Flex flexWrap="wrap" justifyContent="center">
					<Feature icon="👋" description="Android alkalmazás riportok gyors kitöltéséhez">
						Hero
					</Feature>
					<Feature icon="🔥" description="Weboldal kimutatásokkal">
						Features
					</Feature>
					<Feature icon="📩" description="Saját templatek készítése néhány kattintással">
						Sign Up
					</Feature>
				</Flex>
			</Section>
		);
	}

	render() {
		const { classes } = this.props;

		return (
			<div className={classes.root}>
				{this.renderSnackBar()}
				{this.renderAppBar()}
				<Provider>
					{this.renderMain()}
					{/* {this.renderFeatures()}
    {this.renderSocialProof()}
    {this.renderPricing()} */}
					{this.renderFooter()}
				</Provider>
			</div>
		);
	}
}

export default withStyles(styles, { withTheme: true })(Landing);
