export const LANDING = '/';
export const HOME = '/home';
export const SIGNIN = '/signin';
export const REPORTS = '/reports';
export const ADHOCTASKS = '/ad-hoc-tasks';
export const CUSTOMERREPORTS = '/customerreports';
export const WORKLOGS = '/worklogs';
export const TEMPLATES = '/templates';
export const VENUES = '/venues';
export const MASTERDATA = '/masterdata';
export const VENUE = '/venues/:venue';
export const CUSTOMER = '/customer';
export const ACCOUNT = '/account';
export const UNAUTHORIZED = '/unauthorized';
export const APPPRIVACYPOLICY = '/appprivacypolicy';
