import React, { forwardRef, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
// import { DataGrid } from '@mui/material/data-grid';
import { getAreaNameWithoutBuilding } from '../../utilities/StringHelper';
import moment from 'moment';

import MaterialTable from '@material-table/core';
import AddBox from '@mui/icons-material/AddBox';
import ArrowUpward from '@mui/icons-material/ArrowUpward';
import Check from '@mui/icons-material/Check';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';
import Clear from '@mui/icons-material/Clear';
import DeleteOutlined from '@mui/icons-material/DeleteOutline';
import Edit from '@mui/icons-material/Edit';
import Delete from '@mui/icons-material/Delete';
import FilterList from '@mui/icons-material/FilterList';
import FirstPage from '@mui/icons-material/FirstPage';
import LastPage from '@mui/icons-material/LastPage';
import Remove from '@mui/icons-material/Remove';
import SaveAlt from '@mui/icons-material/SaveAlt';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Search from '@mui/icons-material/Search';
import ViewColumn from '@mui/icons-material/ViewColumn';
import UUID from 'uuid/v4';
import { ExportCsv, ExportPdf } from '@material-table/exporters';

const tableIcons = {
	Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
	Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
	Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
	Delete: forwardRef((props, ref) => <Delete {...props} ref={ref} />),
	DeleteOutlined: forwardRef((props, ref) => <DeleteOutlined {...props} ref={ref} />),
	DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
	Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
	View: forwardRef((props, ref) => <VisibilityIcon {...props} ref={ref} />),
	Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
	Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
	FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
	LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
	NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
	PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
	ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
	Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
	SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref} />),
	ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
	ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

const useStyles = makeStyles({
	root: {
		'& .super-app-theme--cell': {
			backgroundColor: 'rgba(224, 183, 60, 0.55)',
			color: '#1a3e72',
			fontWeight: '600',
			lineHeight: '10'
		},
		'& .super-app.negative': {
			backgroundColor: 'rgba(157, 255, 118, 0.49)',
			color: '#1a3e72',
			fontWeight: '600'
		},
		'& .super-app.positive': {
			backgroundColor: '#d47483',
			color: '#1a3e72',
			fontWeight: '600'
		}
	}
});

const columns = [
	{ field: 'id', hidden: true },
	{
		field: 'createdAt',
		title: 'Létrehozva',
		type: 'datetime',
		render: (rowData) => (
			<div>
				{moment(rowData).format('YYYY-MM-DD')} {moment(rowData).format('hh:MM')}
			</div>
		)
		// width: 115
	},
	{
		field: 'template',
		title: 'Űrlap'
		// renderCell: (params) => params.value,
		// width: 200
	},
	{
		field: 'location',
		title: 'Terület',
		type: 'string'
		// renderCell: (params) => (
		// 	<div>
		// 		{params.value.split(';')[0]}
		// 		{params.value.split(';')[1]}
		// 	</div>
		// ),
		// width: 400
	},
	{
		field: 'createdBy',
		title: 'Beküldő',
		type: 'string'
		// renderCell: (params) => params.value,
		// width: 180
	},
	{
		field: 'scoreTrue',
		title: 'Jó',
		type: 'number'
		// renderCell: (params) => params.value,
		// width: 50
	},
	{
		field: 'scoreFixed',
		title: 'Javított',
		type: 'number'
		// renderCell: (params) => params.value,
		// width: 80
	},
	{
		field: 'score',
		title: 'Összesen',
		type: 'number'
		// renderCell: (params) => <strong>{params.value}</strong>,
		// width: 100
	}
];

function getLocationFromReport(report, venue) {
	if (report.location != null && venue.areas[report.location] != null) {
		var tempLocation = `${venue.areas[report.location].building
			? venue.areas[report.location].building + ' Épület'
			: '-'};${getAreaNameWithoutBuilding(venue.areas[report.location])}`;
		return tempLocation;
	} else {
		return 'N/A';
	}
}

function createRows(data, venue) {
	const rows = [];
	if (data != null && data.length > 0) {
		data.forEach((report) => {
			rows.push({
				id: report.id,
				template: report.name,
				location: getLocationFromReport(report, venue),
				createdAt: moment(new Date(report.createdAt)).toDate(),
				createdBy: report.createdBy,
				scoreTrue: report.scoreTrue,
				scoreFixed: report.scoreFixed,
				score: report.scoreTrue && report.scoreFixed ? report.scoreTrue + report.scoreFixed : report.score,
				rawReport: report
			});
		});
	}
	return rows;
}

export default function DataTable({ data, venue, selectRow }) {
	const rows = createRows(data, venue);

	return (
		<div>
			<MaterialTable
				icons={tableIcons}
				title="Riport"
				columns={columns}
				data={rows}
				options={{
					exportButton: true,
					toolbar: true,
					size: 'small',
					paging: false,
					filtering: true,
					addRowPosition: 'first',
					padding: 'dense',
					exportMenu: [
						{
							label: 'Export PDF',
							exportFunc: (cols, datas) => ExportPdf(cols, datas, 'torzsadatok-takaritasi-feladatok')
						},
						{
							label: 'Export CSV',
							exportFunc: (cols, datas) => ExportCsv(cols, datas, 'torzsadatok-takaritasi-feladatok')
						}
					]
				}}
				actions={[
					{
						icon: VisibilityIcon,
						tooltip: 'view',
						onClick: (event, rowData) => selectRow(rowData.rawReport)
					}
				]}
			/>
		</div>
	);
}
