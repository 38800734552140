export default function (state = [], action) {
    switch (action.type) {
        case 'FETCH_VENUES_SUCCESS':
            return action.payload.venues
        case 'SAVE_VENUE':
            state[action.venue.id] = action.venue
            return {...state}
        case 'DELETE_VENUE':
            delete state[action.venueId]
            return {...state}
        case '....':
                state[action.venueId].settings = action.settings
                return {...state}
        case 'UPDATE_AREAS_ON_VENUE':
            state[action.venueId].areas = action.areas
            return {...state}
        case 'DELETE_AREAS_ON_VENUE':
                action.areas.map(areaId => {
                    if (state[action.venueId].groups) {
                        Object.keys(state[action.venueId].groups).map((key, index) => {
                            if (state[action.venueId].groups[key].areas.indexOf(areaId) > -1) {
                                state[action.venueId].groups[key].areas.splice(state[action.venueId].groups[key].areas.indexOf(areaId), 1);
                            }
                        });
                        }
                    if (state[action.venueId].positions) {
                        Object.keys(state[action.venueId].positions).map((key, index) => {
                            if (state[action.venueId].positions[key].areas[areaId] != null) {
                                delete state[action.venueId].positions[key].areas[areaId];
                            }
                        });
                    }
                    delete state[action.venueId].areas[areaId]
                })
            return {...state}
        default:
            return state;
    }
}