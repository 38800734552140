import React, { useCallback } from 'react';
import { makeStyles } from '@mui/styles';
import SettingsIcon from '@mui/icons-material/Settings';

import {
	Typography,
	Dialog,
	DialogTitle,
	DialogActions,
	DialogContent,
	ListItemText,
	ListItem,
	ListItemIcon,
	List,
	Grid,
	Button,
	Divider,
	Chip,
	Checkbox,
	IconButton
} from '@mui/material';

function ManageTemplates({ venue, templates, updateVenue }) {
	const [ scroll, setScroll ] = React.useState('paper');

	const [ checked, setChecked ] = React.useState(venue.templatesIdList);

	const handleToggle = (value) => () => {
		const currentIndex = checked.indexOf(value);
		const newChecked = [ ...checked ];
		if (currentIndex === -1) {
			newChecked.push(value);
		} else {
			newChecked.splice(currentIndex, 1);
		}
		setChecked(newChecked);
	};

	const handleOpenTemplatesSelector = () => {
		setChecked(venue.templatesIdList);
		setOpenTemplatesSelector(true);
	};

	const [ openTemplatesSelector, setOpenTemplatesSelector ] = React.useState(false);

	const updateVenueWithTemplates = () => {
		venue.templatesIdList = checked;
		setOpenTemplatesSelector(false);
		updateVenue({ id: venue.id, templatesIdList: venue.templatesIdList });
		return;
	};

	return (
		<Grid space="2" container alignItems="flex-start" justifyContent="flex-end">
			<Dialog
				onClose={() => setOpenTemplatesSelector(false)}
				scroll="paper"
				aria-labelledby="scroll-dialog-title"
				open={openTemplatesSelector}
			>
				<DialogTitle id="scroll-dialog-title">Űrlap kiválasztása</DialogTitle>
				<DialogContent dividers={scroll === 'paper'}>
					<List>
						{templates ? (
							templates.map((template) => {
								const labelId = `checkbox-list-label-${template.id}`;

								return (
									<ListItem
										key={template.id}
										role={undefined}
										dense
										button
										onClick={handleToggle(template.id)}
									>
										<ListItemIcon>
											<Checkbox
												edge="start"
												checked={checked.indexOf(template.id) !== -1}
												tabIndex={-1}
												disableRipple
												inputProps={{ 'aria-labelledby': labelId }}
											/>
										</ListItemIcon>
										<ListItemText id={labelId} primary={template.name} />
									</ListItem>
								);
							})
						) : (
							''
						)}
					</List>
				</DialogContent>
				<DialogActions>
					<Button onClick={updateVenueWithTemplates} color="primary">
						Mentes
					</Button>
				</DialogActions>
			</Dialog>
			<Grid item xs={11}>
				<Typography align="left" variant="h6">
					Űrlapok
				</Typography>
			</Grid>
			<Grid item xs={1}>
				<IconButton onClick={handleOpenTemplatesSelector} aria-label="edit">
					<SettingsIcon />
				</IconButton>
			</Grid>
			<Grid item xs={12} />

			<Grid item xs={12}>
				<Grid spacing={1} container alignItems="flex-start" justifyContent="flex-start">
					{templates ? (
						templates.map(
							(template, index) =>
								venue.templatesIdList.includes(template.id) ? (
									<Grid item>
										<Chip label={template.name} />{' '}
									</Grid>
								) : (
									''
								)
						)
					) : (
						''
					)}
				</Grid>
			</Grid>
		</Grid>
	);
}

export default ManageTemplates;
