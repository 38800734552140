import React from 'react';

import { Grid } from '@mui/material';
import CleaningActivityCard from './Card/CleaningActivityCard';

export default function UniqueCleaningActivitiesOnArea({ cleaningActivities, positionArea, editable }) {
	const [ temp, setTemp ] = React.useState(0);
	const deleteUniqueCleaningActivityCardOnPositionArea = (cleaningActivityKey) => {
		delete positionArea.assignedUniqueCleaningActivities[cleaningActivityKey];
		var tempInt = temp;
		tempInt++;
		setTemp(tempInt);
	};
	return (
		<Grid container spacing={1} direction="column" justifyContent="flex-start" alignItems="flex-start">
			{positionArea.assignedUniqueCleaningActivities && Object.keys(positionArea.assignedUniqueCleaningActivities).map((key) => (
				<Grid item key={key}>
					{cleaningActivities ? (
						<CleaningActivityCard
							cleaningActivityOnArea={positionArea.assignedUniqueCleaningActivities[key]}
							editable={editable}
							cleaningActivity={cleaningActivities[key]}
							deleteUniqueCleaningActivityCard={deleteUniqueCleaningActivityCardOnPositionArea}
							cleaningActivityId={key}
						/>
					) : (
						''
					)}
				</Grid>
			))}
		</Grid>
	);
}
