import React, { forwardRef, useEffect } from 'react';

import { withStyles } from '@mui/styles';
import { Grid, TextField, Box, Typography } from '@mui/material';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

function getIntervalByWorksheet(worksheet) {
	if (worksheet.interval != null) {
		return worksheet.interval;
	} else {
		return 1;
	}
}

export default function WeeklyRRulePicker({ worksheet, editable }) {
	const [ interval, setInterval ] = React.useState(getIntervalByWorksheet(worksheet));

	useEffect(() => {
		// generateAreasWithActivities();
		if (worksheet.interval == null) {
			worksheet.interval = 1;
		}
	}, []);

	const handleIntervalChange = (e) => {
		setInterval(e.target.value);
		worksheet.interval = e.target.value;
	};

	return (
					<TextField
						sx={{ 
							'& .MuiOutlinedInput-input': {
							marginTop: 0,
							marginBottom:0,
							paddingTop:0.5,
							paddingBottom:0.5,
							},
							marginTop: 0,
							marginBottom:0,
							paddingLeft: 0.5
						}}
						id="standard-number"
						value={interval}
						onChange={handleIntervalChange}
						type="number"
						min="1"
						size="small"
						disabled={!editable}
					/>
	);
}
