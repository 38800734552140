import React, { forwardRef, useEffect } from 'react';
import MaterialTable, { MTableCell } from '@material-table/core';
import AddBox from '@mui/icons-material/AddBox';
import ArrowUpward from '@mui/icons-material/ArrowUpward';
import Check from '@mui/icons-material/Check';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';
import Clear from '@mui/icons-material/Clear';
import DeleteOutlined from '@mui/icons-material/DeleteOutline';
import Edit from '@mui/icons-material/Edit';
import Delete from '@mui/icons-material/Delete';
import FilterList from '@mui/icons-material/FilterList';
import FirstPage from '@mui/icons-material/FirstPage';
import LastPage from '@mui/icons-material/LastPage';
import Remove from '@mui/icons-material/Remove';
import SaveAlt from '@mui/icons-material/SaveAlt';
import Search from '@mui/icons-material/Search';
import ViewColumn from '@mui/icons-material/ViewColumn';
import SelectCleaningActivityViewDialog from './Edit/SelectCleaningActivityViewDialog';
import { getSelectedOrg } from '../../../firebase/auth';
import { updateWorksheetActivitiesOnVenue } from '../../../api/manager';

import Snackbar from '@mui/material/Snackbar';
import MySnackbarContentWrapper from '../../../utilities/MySnackBarContent';

const tableIcons = {
	Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
	Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
	Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
	Delete: forwardRef((props, ref) => <Delete {...props} ref={ref} />),
	DeleteOutlined: forwardRef((props, ref) => <DeleteOutlined {...props} ref={ref} />),
	DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
	Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
	Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
	Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
	FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
	LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
	NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
	PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
	ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
	Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
	SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref} />),
	ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
	ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

export default function CleaningActivitiesTable({
	venue,
	worksheet,
	cleaningActivities,
	worksheetId,
	setWorksheetIsLoading
}) {
	const [ columns ] = React.useState([
		{
			title: 'Kategória',
			field: 'category',
			width: '100'
		},
		{
			title: 'Feladat',
			field: 'cleaningtask',
			width: '300'
		}
	]);
	const [ data, setData ] = React.useState([]);
	const [ isSelectCleaningActivitiesDialogOpen, setIsSelectCleaningActivitiesDialogOpen ] = React.useState(false);

	const [ venueId, setVenueId ] = React.useState('');

	const [ snackBar, setSnackBar ] = React.useState({
		snackOpen: false,
		snackMessage: '',
		snackType: 'error'
	});

	const saveSelectedCleaningActivities = (newlySelectedCleaningActivities) => {
		setWorksheetIsLoading(true);
		updateWorksheetActivitiesOnVenue(getSelectedOrg(), venue.id, newlySelectedCleaningActivities, worksheetId)
			.then((result) => {
				worksheet.activities = newlySelectedCleaningActivities;
				generateDataFromVenue();
				setWorksheetIsLoading(false);
			})
			.catch((error) => {
				console.log('Error happened in CleaningActivitiesTable.js while retriving templates' + error);
				setWorksheetIsLoading(false);
				setSnackBar({
					snackOpen: true,
					snackMessage: error.message,
					snackType: 'error'
				});
			});
	};

	useEffect(() => {
		generateDataFromVenue();
	}, []);

	// const handleUpdateRows = () => {};

	const generateDataFromVenue = () => {
		if (venueId != venue.id || (data.length < 1 && worksheet.activities.length > 0)) {
			var rows = [];
			if (worksheet.activities && Object.keys(worksheet.activities).length > 0) {
				Object.keys(worksheet.activities).forEach((element) => {
					if (cleaningActivities[element] != null && cleaningActivities[element].description != null) {
						rows.push({
							cleaningtask: cleaningActivities[element].description,
							areas: cleaningActivities[element].areas,
							methods: cleaningActivities[element].methods
						});
					}
				});
			}
			setVenueId(venue.id);
			setData(rows);
		}
	};

	// const getTranslation = (key) => {
	// 	if (key == 'MONTHLY') {
	// 		return 'havonta';
	// 	} else if (key == 'DAILY') {
	// 		return 'naponta';
	// 	} else if (key == 'WEEKLY') {
	// 		return 'hetente';
	// 	} else if (key == 'YEARLY') {
	// 		return 'évente';
	// 	}
	// 	return '';
	// };

	return (
		<div>
			<Snackbar
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'center'
				}}
				open={snackBar.snackOpen}
				autoHideDuration={6000}
				onClose={() => setSnackBar({ snackOpen: false })}
			>
				<MySnackbarContentWrapper
					onClose={() => setSnackBar({ snackOpen: false })}
					variant={snackBar.snackType}
					message={snackBar.snackMessage}
				/>
			</Snackbar>
			{isSelectCleaningActivitiesDialogOpen ? (
				<SelectCleaningActivityViewDialog
					isOpenDialog={isSelectCleaningActivitiesDialogOpen}
					setIsOpenDialog={setIsSelectCleaningActivitiesDialogOpen}
					selectedList={worksheet.activities ? worksheet.activities : {}}
					cleaningActivities={cleaningActivities}
					saveSelectedCleaningActivities={saveSelectedCleaningActivities}
				/>
			) : null}
			<MaterialTable
				icons={tableIcons}
				columns={columns}
				data={data}
				options={{
					paging: false,
					grouping: true,
					padding: 'dense'
				}}
				actions={[
					{
						icon: () => <Edit />,
						tooltip: 'Módosítás',
						isFreeAction: true,
						onClick: (event) => setIsSelectCleaningActivitiesDialogOpen(true)
					}
				]}
			/>
		</div>
	);
}
