import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { useTheme } from '@mui/material/styles';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { padding } from 'polished';

const formValues = {
	none: 'Nem értelmezett',
	true: 'Megfelelt',
	fixed: 'Javított',
	false: 'Nem felelt meg'
};
const formColorforValues = {
	none: '#9C9F9E',
	true: '#90EE90',
	fixed: '#E9D427',
	false: '#FF6961'
};

const styles = (theme) => ({
	root: {
		width: '200',
		marginTop: useTheme().spacing(3),
		margin: '15px',
		padding: '10px'
		// overflowX: 'auto'
	},
	table: {
		minWidth: 700
	}
});

function FormTable(props) {
	const { classes } = props;

	return (
		<Paper className={classes.root}>
			<Table className={classes.table}>
				<TableHead>
					<TableRow>
						<TableCell>Kérdés</TableCell>
						<TableCell align="right">Válasz</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{props.report.form_test.map((row) => (
						<TableRow key={row.hint} style={{ backgroundColor: formColorforValues[row.value] }}>
							<TableCell component="th" scope="row">
								{row.hint}
							</TableCell>
							<TableCell align="right">
								<b>{formValues[row.value]}</b>
							</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
		</Paper>
	);
}

FormTable.propTypes = {
	classes: PropTypes.object.isRequired
};

export default withStyles(styles)(FormTable);
