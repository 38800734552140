import React, { useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { getWorkLogsOnVenue } from '../api/manager';
import { getSelectedOrg } from '../firebase/auth';

import PropTypes from 'prop-types';

import { getAreaName } from '../utilities/StringHelper';

import DayPicker from './datepickers/DayPicker';
import WorkLogsTimeLine from './WorkLogsTimeLine';
import WorkLogsTracker from './WorkLogsTracker';


import CollapsibleTable from './CollapsibleTable';


import { Tab, AppBar, Box, Tabs, LinearProgress, Typography } from '@mui/material';

import moment from 'moment';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flexGrow',
		flexWrap: 'wrap',
		padding: 10
	}
}));

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<Typography
			component="div"
			role="tabpanel"
			hidden={value !== index}
			id={`scrollable-auto-tabpanel-${index}`}
			aria-labelledby={`scrollable-auto-tab-${index}`}
			{...other}
		>
			<Box p={0}>{children}</Box>
		</Typography>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired
};

function a11yProps(index) {
	return {
		id: `scrollable-auto-tab-${index}`,
		'aria-controls': `full-width-tabpanel-${index}`
	};
}

function parseWorklogForHistory(worklog){
	return {
		duration: worklog.endTime != null ? moment(worklog.endTime)
			.diff(moment(worklog.startTime), 'minutes', true)
			.toFixed(1) : 'folyamatban',
		name: worklog.user.lastName + " " + worklog.user.firstName,
		area: worklog.area,
		startTime: moment(worklog.startTime).format('HH:mm'),
		endTime: worklog.endTime != null && moment(worklog.endTime).format('HH:mm')								}
}

export default function DailyWorkLogsOnVenue({ venue,venueId }) {
	const classes = useStyles();
	const [ workLogs, setWorkLogs ] = React.useState([]);
	const [ timelineWorkLogs, setTimelineWorkLogs ] = React.useState({});
	const [ trackerWorkLogs, setTrackerWorkLogs ] = React.useState({});

	const [ day, setDay ] = React.useState(new Date());
	const [ isLoading, setIsLoading ] = React.useState(true);
	const [ value, setValue ] = React.useState(0);

	useEffect(() => {
		if (workLogs.length < 1) fetchWorkLogs(day);
	}, []);

	const handleDayChange = (newDay) => {
		setDay(newDay);
		fetchWorkLogs(newDay);
	};

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	const fetchWorkLogs = (day) => {
		setIsLoading(true);
		getWorkLogsOnVenue(getSelectedOrg(), venueId, day)
			.then((result) => {
				var worklogsObject = {};
				var timelineObjects = {};
				var trackerObjects = {};

				result.data.results.forEach(function(worklog, index) {
					//Add to worklogsobject
					if (worklogsObject[worklog.areaId] != null) {
						worklogsObject[worklog.areaId].count++;
						worklogsObject[worklog.areaId].history.push(								parseWorklogForHistory(worklog)
						);
					} else {
						worklogsObject[worklog.areaId] = {
							area: worklog.area && getAreaName(worklog.area),
							count: 1,
							history: [
								parseWorklogForHistory(worklog)
							]
						};
					}

					//Add to timelineObjects
					if (timelineObjects[worklog.createdBy] != null) {
						timelineObjects[worklog.createdBy].workLogs.push(								parseWorklogForHistory(worklog)
						);
					} else {
						timelineObjects[worklog.createdBy] = {
							user: worklog.user,
							workLogs: [
								parseWorklogForHistory(worklog)
							]
						};
					}

						//Add to trackerObjects
						var tempWorkLog = parseWorklogForHistory(worklog);
						if(trackerObjects[worklog.createdBy] == null){
							trackerObjects[worklog.createdBy] = {
								user: worklog.user,
								closedWorkLogs: [
								],
								openWorkLogs: []
							};
						}
						if (trackerObjects[worklog.createdBy] != null) {
							if(tempWorkLog.endTime == false) {
								trackerObjects[worklog.createdBy].openWorkLogs.unshift(tempWorkLog);
							} else {
								trackerObjects[worklog.createdBy].closedWorkLogs.unshift(tempWorkLog);
							}
						} 
				});
				setTimelineWorkLogs(timelineObjects);
				setTrackerWorkLogs(trackerObjects);
				setWorkLogs(Object.values(worklogsObject));
				setIsLoading(false);
			})
			.catch((error) => {
				console.log('Error happened in Venues.js while retriving Venues' + error);
				setIsLoading(false);
			});
	};

	return (
		<div>
			{isLoading ? (
				<LinearProgress />
			) : (
				<div className={classes.root}>
					<DayPicker day={day} updateDay={handleDayChange} />
					<AppBar position="static" color="default">
						<Tabs
							value={value}
							onChange={handleChange}
							indicatorColor="secondary"
							variant="fullWidth"
							textColor="secondary"
							aria-label="full width tabs example"
						>
							<Tab label="Követés" {...a11yProps(0)} />
							<Tab label="Táblázat" {...a11yProps(1)} />
							<Tab label="Idővonal" {...a11yProps(2)} />
						</Tabs>
					</AppBar>
					<TabPanel value={value} index={0}>
						<WorkLogsTracker data={trackerWorkLogs} venueSettings={venue.settings} />
					</TabPanel>
					<TabPanel value={value} index={1}>
						<CollapsibleTable data={workLogs} venueSettings={venue.settings} />
					</TabPanel>
					<TabPanel value={value} index={2}>
						<WorkLogsTimeLine data={timelineWorkLogs} venueSettings={venue.settings} />
					</TabPanel>
				</div>
			)}
		</div>
	);
}
