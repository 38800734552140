import React from 'react';
import Navigation from './Navigation';

const Customer = () => (
	<div>
		<h2>Customer</h2>
	</div>
);

export default Customer;
