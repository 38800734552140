import React from 'react';
import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EditIcon from '@mui/icons-material/Edit';
import {
	IconButton,
	Button,
	DialogTitle,
	DialogContentText,
	DialogContent,
	DialogActions,
	Dialog,
	Grid,
	TextField,
	Typography,
	Divider,
	AccordionSummary,
	AccordionDetails,
	Accordion,
	Box
} from '@mui/material';
import { getSelectedOrg } from '../../../firebase/auth';

import {renameWorksheet} from '../../../api/manager'
import { getAreaName } from '../../../utilities/StringHelper';
import AreasAndRulesTableList from './AreasAndRulesTable';
import CleaningActivitiesTable from './CleaningActivitiesTable';

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
		padding: 10
	},
	expandable: {
		width: '100%'
	},
	panel1cheader: {
		background: '#039be5'
	},
	heading: {
		fontSize: useTheme().typography.pxToRem(15),
		display: 'block'
	},
	secondaryHeading: {
		fontSize: useTheme().typography.pxToRem(15),
		color: useTheme().palette.text.secondary
	},
	icon: {
		verticalAlign: 'bottom',
		height: 20,
		width: 20
	},
	helper: {
		borderLeft: `2px solid ${useTheme().palette.divider}`,
		padding: useTheme().spacing(1, 2)
	},
	separator: {
		margin: '0px 10px 0px 10px'
	},
	link: {
		color: useTheme().palette.primary.main,
		textDecoration: 'none',
		'&:hover': {
			textDecoration: 'underline'
		}
	}
}));

export default function WorksheetsExpansionView({
	worksheet,
	worksheetId,
	cleaningActivities,
	venue,
	setWorksheetIsLoading
}) {
	const classes = useStyles();
	const [openRenameDialog, setOpenRenameDialog] = React.useState(false);
	
	  const handleClose = () => {
		setOpenRenameDialog(false);
	  };

	  const renderRenameDialog = () => {
		return (
			<Dialog
				open={openRenameDialog}
				onClose={handleClose}
				PaperProps={{
				component: 'form',
				onSubmit: (event) => {
					event.preventDefault();
					const formData = new FormData(event.currentTarget);
					const formJson = Object.fromEntries(formData.entries());
					const newWorkSheetName = formJson.workSheetName;
					if(newWorkSheetName.length > 0) {
						renameWorksheet(getSelectedOrg(),venue.id,worksheetId, newWorkSheetName).then((result) => {
							worksheet.name = newWorkSheetName;
							handleClose();
						})
						.catch((error) => {
							console.log('Error happened in ManageWorksheets.js while renaming worksheet' + error);
							handleClose();
						});
	  				}
				},
				}}
			>
				<DialogTitle>Feladatlista átnevezése</DialogTitle>
				<DialogContent>
					<TextField
						autoFocus
						required
						placeholder={worksheet.name}
						margin="dense"
						id="name"
						name="workSheetName"
						label="Feladatlista név"
						type="text"
						fullWidth
						variant="standard"
					/>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose}>Mégse</Button>
					<Button type="submit">Átnevezés</Button>
				</DialogActions>
			</Dialog>
		);
	};

	const renderAreas = () => {
		return (
			<div>
				<Typography variant="h6" gutterBottom>
					Területekhez hozzárendelve
				</Typography>
				{typeof venue.positions === 'undefined' ? (
					'Missing'
				) : (
					<AreasAndRulesTableList venue={venue} worksheetId={worksheetId} />
				)}
			</div>
		);
	};
	const renderCleaningActivities = () => {
		return (
			<div>
				<Typography variant="h6" gutterBottom>
					Takarítási feladatok
				</Typography>

				<CleaningActivitiesTable
					venue={venue}
					worksheet={worksheet}
					worksheetId={worksheetId}
					cleaningActivities={cleaningActivities}
					setWorksheetIsLoading={setWorksheetIsLoading}
				/>
			</div>
		);
	};

	return (
		<div>
			{renderRenameDialog()}
			<Accordion style={{ flex: 1 }}>
				<AccordionSummary
					expandIcon={<ExpandMoreIcon />}
					aria-controls="panel1c-content"
					id="panel1c-header"
					className="panel1cheader"
				>
					<Grid container className={classes.expandable}>
						<Grid item xs={12}>
							<Grid container justifyContent="space-between" alignItems="center">
								<Grid key={'value'} item>
									<IconButton
										onClick={() => {
												setOpenRenameDialog(true);
										}}
										aria-label="edit"
									>
										<EditIcon />
									</IconButton>
									<Typography variant="h6" gutterBottom sx={{display:"inline"}}>
										{worksheet.name}
									</Typography>
									<Typography variant="subtitle1">{worksheet.description}</Typography>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</AccordionSummary>
				<Divider />
				<AccordionDetails>
					<Box sx={{ flexGrow: 1 }}>
						{/* {renderRecurrence()}
							<br /> */}
						{renderCleaningActivities()}
						<br />
						{renderAreas()}
					</Box>
				</AccordionDetails>
			</Accordion>
		</div>
	);
}
