import React, { useEffect, forwardRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { getWorkLogsOnVenue, getWorkLogsOnVenueForMonth } from '../api/manager';
import { getSelectedOrg } from '../firebase/auth';

import MaterialTable from '@material-table/core';
import AddBox from '@mui/icons-material/AddBox';
import ArrowUpward from '@mui/icons-material/ArrowUpward';
import Check from '@mui/icons-material/Check';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';
import Clear from '@mui/icons-material/Clear';
import DeleteOutlined from '@mui/icons-material/DeleteOutline';
import Edit from '@mui/icons-material/Edit';
import Delete from '@mui/icons-material/Delete';
import FilterList from '@mui/icons-material/FilterList';
import FirstPage from '@mui/icons-material/FirstPage';
import LastPage from '@mui/icons-material/LastPage';
import Remove from '@mui/icons-material/Remove';
import SaveAlt from '@mui/icons-material/SaveAlt';
import Search from '@mui/icons-material/Search';
import ViewColumn from '@mui/icons-material/ViewColumn';

import PropTypes from 'prop-types';

import { getAreaName } from '../utilities/StringHelper';

import DayPicker from './datepickers/DayPicker';
import WeekPicker from './datepickers/MonthPicker';
import MonthPicker from './datepickers/MonthPicker';

import TopNavigationBar from '../utilities/TopNavigationBar';
import WorkLogsTableList from './WorkLogsTableList';
import WorkLogsTimeLine from './WorkLogsTimeLine';

import { Timeline, TimelineItem } from 'vertical-timeline-component-for-react';

import { Paper, Grid, Button, Divider, Tab, AppBar, Box, Tabs, LinearProgress, Typography } from '@mui/material';

import moment from 'moment';

const tableIcons = {
	Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
	Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
	Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
	Delete: forwardRef((props, ref) => <Delete {...props} ref={ref} />),
	DeleteOutlined: forwardRef((props, ref) => <DeleteOutlined {...props} ref={ref} />),
	DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
	Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
	Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
	Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
	FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
	LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
	NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
	PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
	ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
	Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
	SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref} />),
	ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
	ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'block',
		flexWrap: 'wrap',
		padding: 10
	}
}));

function getDaysArrayByMonth(date) {
	var daysInMonth = moment(date).daysInMonth();
	var arrDays = [];

	var iterate = 1;
	while (iterate <= daysInMonth) {
		var current = moment(date).date(iterate);
		arrDays.push(current);
		iterate++;
	}

	return arrDays;
}

function generateColumnsByMonth(month) {
	let daysArrayInMonth = getDaysArrayByMonth(month);
	var columns = [ { field: 'area', title: 'Terület', width: 200 } ];
	daysArrayInMonth.map((day) => {
		columns.push({
			field: moment(day).format('DD'),
			title: moment(day).format('DD ddd'),
			sorting: false,
			cellStyle: {
				backgroundColor: getCellBackgroundColor(day)
			},
			headerStyle: {
				backgroundColor: getHeaderBackgroundColor(day)
			},
			render: (rowData) =>
				rowData[moment(day).format('DD')] > 0 ? <strong>{rowData[moment(day).format('DD')]}</strong> : '-'
		});
	});
	return columns;
}

function getCellBackgroundColor(date) {
	let temp = moment(date).format('ddd');
	if (moment(date).format('ddd') == 'Sat' || moment(date).format('ddd') == 'Sun') {
		return '#f5eded';
	}
	return '#FFF';
}

function getHeaderBackgroundColor(date) {
	let temp = moment(date).format('ddd');
	if (moment(date).format('ddd') == 'Sat' || moment(date).format('ddd') == 'Sun') {
		return '#f5eded';
	}
	return '#FFF';
}

function isWeekDay(month, day) {}

function getDayByMonthAndDay(month, day) {}

export default function MonthlyWorkLogsOnVenue({ venue, venueId}) {
	const classes = useStyles();
	const [ workLogs, setWorkLogs ] = React.useState([]);
	const [ month, setMonth ] = React.useState(new Date());
	const [ isLoading, setIsLoading ] = React.useState(true);
	const [ columns, setColumns ] = React.useState([]);

	useEffect(() => {
		if (workLogs.length < 1) fetchWorkLogs(month);
		setColumns(generateColumnsByMonth(month));
	}, []);

	const handleMonthChange = (newMonth) => {
		setMonth(newMonth);
		fetchWorkLogs(newMonth);
		setColumns(generateColumnsByMonth(newMonth));
	};

	const fetchWorkLogs = (month) => {
		setIsLoading(true);
		getWorkLogsOnVenueForMonth(getSelectedOrg(), venueId, month)
			.then((result) => {
				var worklogsObject = {};
				result.data.results.forEach(function(worklog, index) {
					//Add to worklogsobject
					if (worklogsObject[worklog.areaId] == null) {
						worklogsObject[worklog.areaId] = { area: worklog.area ? getAreaName(worklog.area) : `Törölt terület (${worklog.areaId})` };
					}
					if (worklogsObject[worklog.areaId][moment(worklog.endTime).format('DD')] == null) {
						worklogsObject[worklog.areaId][moment(worklog.endTime).format('DD')] = 1;
					} else {
						worklogsObject[worklog.areaId][moment(worklog.endTime).format('DD')]++;
					}
				});
				setWorkLogs(Object.values(worklogsObject));
				setIsLoading(false);
			})
			.catch((error) => {
				console.log('Error happened in Venues.js while retriving Venues' + error);
				setIsLoading(false);
			});
	};

	return (
		<div className={classes.root}>

			<MonthPicker month={month} updateMonth={handleMonthChange} />
			{isLoading ? (
				<LinearProgress />
			) : (
				<MaterialTable
					icons={tableIcons}
					options={{
						exportButton: true,
						paging: false,
						filtering: false,
						selection: false,
						padding: 'dense',
						fixedColumns: {
							left: 1,
							right: 0
						},
						showTitle: false,
						height: 600,
						tableLayout: 'fixed'
					}}
					data={workLogs}
					columns={columns}
				/>
			)}
		</div>
	);
}
