import React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function NewVenueDialog({ open, handleClose, handleCreateNewVenue }) {
	const [ name, setName ] = React.useState();

	// const addNewVenue = () => {
	// 	saveVenue(getSelectedOrg(), {
	// 		id: UUID(),
	// 		name: name,
	// 		groups: {},
	// 		isActive: true,
	// 		templatesIdList: [],
	// 		customersCanAccess: []
	// 	})
	// 		.then((result) => {
	// 			handleNewVenueCreated();
	// 		})
	// 		.catch((error) => {
	// 			console.log('Error happened while saving Venue' + error);
	// 		});
	// };

	const handleSubmit = () => {
		handleCreateNewVenue(name);
	};

	const handleChange = (event) => {
		setName(event.target.value);
	};

	return (
		<Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
			<DialogTitle id="form-dialog-title">Új létesítmény hozzáadása</DialogTitle>
			<DialogContent>
				<DialogContentText>Új létesítmény létrehozásához add meg a létesítmény nevét.</DialogContentText>
				<TextField
					color="primary"
					autoFocus
					margin="dense"
					id="name"
					label="Létesítmény neve"
					type="venuename"
					fullWidth
					onChange={handleChange}
				/>
			</DialogContent>
			<DialogActions>
				<Button color="primary" onClick={handleClose}>
					Mégsem
				</Button>
				<Button color="primary" onClick={handleSubmit}>
					Hozzáadás
				</Button>
			</DialogActions>
		</Dialog>
	);
}
