import React from 'react';

import { Avatar, Box, Card, CardContent, Grid, Typography } from '@mui/material';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import HomeWorkIcon from '@mui/icons-material/HomeWork';

export const TotalVenues = ({ venuesCount }) => (
	<Card sx={{ height: '100%' }}>
		<CardContent>
			<Grid container spacing={3} sx={{ justifyContent: 'space-between' }}>
				<Grid item>
					<Typography color="textSecondary" gutterBottom variant="overline">
						Létesítmények
					</Typography>
					<Typography color="textPrimary" variant="h4">
						{venuesCount}
					</Typography>
				</Grid>
				<Grid item>
					<Avatar
						sx={{
							backgroundColor: 'primary.main',
							height: 56,
							width: 56
						}}
					>
						<HomeWorkIcon />
					</Avatar>
				</Grid>
			</Grid>
			{/* <Box
				sx={{
					pt: 2,
					display: 'flex',
					alignItems: 'center'
				}}
			>
				<ArrowDownwardIcon color="error" />
				<Typography
					color="error"
					sx={{
						mr: 1
					}}
					variant="body2"
				>
					12%
				</Typography>
				<Typography color="textSecondary" variant="caption">
					Since last month
				</Typography>
			</Box> */}
		</CardContent>
	</Card>
);
