import React, { forwardRef, useEffect } from 'react';

import { withStyles } from '@mui/styles';
import { Grid, TextField, Box, Typography } from '@mui/material';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

const weekDays = [ 'MO', 'TU', 'WE', 'TH', 'FR', 'SA', 'SU' ];

const translateDayKey = (key) => {
	if (key == 'MO') {
		return 'Hétfő';
	} else if (key == 'TU') {
		return 'Kedd';
	} else if (key == 'WE') {
		return 'Szerda';
	} else if (key == 'TH') {
		return 'Csütörtök';
	} else if (key == 'FR') {
		return 'Péntek';
	} else if (key == 'SA') {
		return 'Szombat';
	} else if (key == 'SU') {
		return 'Vasárnap';
	}
};

export default function PositionSchedule({ position, editable }) {
	if (position) {
		return (
			<Grid container spacing={2} direction="row" justifyContent="flex-start" alignItems="center">
				{/* <Grid container spacing="2" direction="row" justifyContent="flex-start" alignItems="center"> */}
				{weekDays.map((element) => (
					<Grid item key={element}>
						<Grid container direction="column" justifyContent="flex-start" alignItems="center">
							<Grid item>
								<Typography
									variant="body1"
									color={
										position != null &&
										position.schedule != null &&
										position.schedule.hasOwnProperty(element) ? (
											'primary'
										) : (
											'textSecondary'
										)
									}
									align="center"
								>
									{translateDayKey(element)}
								</Typography>
							</Grid>
							{position != null &&
							position.schedule != null &&
							position.schedule.hasOwnProperty(element) ? (
								<Grid item>
									<Typography variant="body2" color="primary" align="center">
										{position.schedule[element].startAt} - {position.schedule[element].finishAt}
									</Typography>
								</Grid>
							) : (
								''
							)}
						</Grid>
					</Grid>
				))}
			</Grid>
		);
	} else {
		return '';
	}
}
