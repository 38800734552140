/* eslint-disable no-console */

import React from 'react';

import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material/styles';

import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import ReportsCombinedChart from './ReportsCombinedChart';
import ReportsStackedChart from './ReportsStackedChart';

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<Typography
			component="div"
			role="tabpanel"
			hidden={value !== index}
			id={`scrollable-auto-tabpanel-${index}`}
			aria-labelledby={`scrollable-auto-tab-${index}`}
			{...other}
		>
			<Box p={3}>{children}</Box>
		</Typography>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired
};

function a11yProps(index) {
	return {
		id: `scrollable-auto-tab-${index}`,
		'aria-controls': `scrollable-auto-tabpanel-${index}`
	};
}

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
		width: '100%',
		backgroundColor: useTheme().palette.background.paper
	}
}));

export default function ReportsGoogleCharts({ data }) {
	const classes = useStyles();
	const [ value, setValue ] = React.useState(0);

	function handleChange(event, newValue) {
		setValue(newValue);
	}

	function getReportChart(data, key) {
		if (key == 'combo') {
			return <ReportsCombinedChart data={data[key]} />;
		} else {
			return <ReportsStackedChart data={data[key]} />;
		}
	}

	const renderAppBar = () => {
		return (
			<AppBar position="static" color="default">
				<Tabs
					value={value}
					onChange={handleChange}
					indicatorColor="primary"
					textColor="primary"
					variant="scrollable"
					scrollButtons="auto"
					aria-label="scrollable auto tabs example"
				>
					{Object.keys(data).map((key, index) => <Tab label={key} {...a11yProps(index)} />)}
				</Tabs>
			</AppBar>
		);
	};

	const renderTabs = () => {
		return Object.keys(data).map((key, index) => (
			<TabPanel value={value} index={index}>
				{getReportChart(data, key)}
			</TabPanel>
		));
	};

	return (
		<div className={classes.root}>
			{data.combo != null && data.combo.rows.length > 0 ? (
				(renderAppBar(), renderTabs())
			) : (
				'Erre az időintervallumra nincs megjeleníthető adat.'
			)}
		</div>
	);
}
