import React from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import VenueSelector from '../utilities/VenueSelector';


export default function Reports() {
	const history = useHistory();

	const handleVenueSelected = (venue) => {
		history.push({
			pathname: `${history.location.pathname}/${venue.name}`,
			search: `?id=${venue.id}`,
			state: { venue: venue }
		});
	};

	return <VenueSelector selectVenue={handleVenueSelected} />;
}
