import React from 'react';
import { makeStyles } from '@mui/styles';

import VenueCard from './venuecard/VenueCard';
import AddNewVenueCard from './venuecard/AddNewVenueCard';

import Grid from '@mui/material/Grid';

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1
	},
	paper: {
		height: 140,
		width: 100
	},
	grid: {
		spacing: 10
	}
}));

export default function VenuesSelectorView({ venues, onCardSelected, showAddNewVenue, onAddNewVenue }) {
	const classes = useStyles();

	const handleClick = (venueId) => {
		onCardSelected(venueId);
	};
	const handleAddNewVenueClick = () => {
		onAddNewVenue();
	};

	return (
		<Grid container className={classes.root}>
			<Grid item xs={12}>
				<Grid container justifyContent="flex-start" spacing={5}>
					{Object.keys(venues).map((key) => (
						<Grid key={key} item>
							<VenueCard venue={{...venues[key],id:key}} onClick={handleClick} />
						</Grid>
					))}
					{showAddNewVenue ? (
						<Grid item>
							<AddNewVenueCard onClick={handleAddNewVenueClick} />
						</Grid>
					) : (
						''
					)}
				</Grid>
			</Grid>
		</Grid>
	);
}
