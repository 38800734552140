import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function SimpleStringInputDialog({
	open,
	setOpen,
	title,
	description,
	inputText,
	inputType,
	saveInput
}) {
	// const [ isOpen, setOpen ] = React.useState(true);
	const [ inputString, setInputString ] = React.useState('');

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleSave = () => {
		setOpen(false);
		saveInput(inputString);
	};

	const textfieldValueChanged = (newString) => {
		setInputString(newString);
	};

	return (
		<div>
			<Dialog open={open} onClose={handleClose}>
				<DialogTitle>{title}</DialogTitle>
				<DialogContent>
					<DialogContentText>{description}</DialogContentText>
					<TextField
						autoFocus
						margin="dense"
						id="name"
						label={inputText}
						type={inputType}
						fullWidth
						variant="standard"
						value={inputString}
						onChange={(event) => textfieldValueChanged(event.target.value)}
					/>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose}>Mégse</Button>
					<Button onClick={handleSave}>Mentés</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
}
