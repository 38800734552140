import React, { useEffect } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import CleaningServicesIcon from '@mui/icons-material/CleaningServices';

import RemoveCircleOutline from '@mui/icons-material/RemoveCircleOutline';

import SelectCleaningActivityComponent from './SelectCleaningActivityComponent';

import { makeStyles } from '@mui/styles';
import {
	Paper,
	Checkbox,
	FormGroup,
	FormControlLabel,
	FormControl,
	Slide,
	Dialog,
	DialogTitle,
	DialogContent,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	IconButton,
	Typography,
	AppBar,
	Toolbar,
	Box,
	Grid,
	Button
} from '@mui/material';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexWrap: 'wrap',
		padding: 10,
		backgroundColor: '#FFFFFF'
	},
	title: {
		flexGrow: 1
	}
}));

function isCleaningActivityContainsText(text, cleaningActivity) {
	var contains = false;
	//if description contains
	if (cleaningActivity.description.toLowerCase().includes(text.toLowerCase())) {
		contains = true;
	}

	//if areas contains
	if (cleaningActivity.areas != null) {
		cleaningActivity.areas.forEach((area) => {
			if (area.toLowerCase().includes(text.toLowerCase())) {
				contains = true;
			}
		});
	}

	//if methods contains
	if (cleaningActivity.methods != null) {
		cleaningActivity.methods.forEach((method) => {
			if (method.toLowerCase().includes(text.toLowerCase())) {
				contains = true;
			}
		});
	}

	return contains;
}

function Transition(props) {
	return <Slide direction="up" {...props} />;
}

const getCleaningActivitiesToSelect = (cleaningActivities, selectedList) => {
	return   Object.fromEntries(Object.entries(cleaningActivities).filter(([k]) => !Object.keys(selectedList).includes(k)));
};

export default function SelectCleaningActivityViewDialog({
	isOpenDialog,
	setIsOpenDialog,
	selectedList,
	cleaningActivities,
	saveSelectedCleaningActivities
}) {
	const classes = useStyles();
	const [ newlySelectedCleaningActivities, setNewlySelectedCleaningActivities ] = React.useState(selectedList);
	const [ filterText, setFilterText ] = React.useState('');

	const [ cleaningActivitiesToSelect, setCleaningActivitiesToSelect ] = React.useState(
		getCleaningActivitiesToSelect(cleaningActivities, selectedList)
	);

	useEffect(() => {
		getCleaningActivitiesToSelect(cleaningActivities, selectedList);
		setNewlySelectedCleaningActivities(selectedList);
	}, []);

	const handleChange = (activityKey, method) => {
		var temp2 = { ...newlySelectedCleaningActivities };

		if (temp2[activityKey].selectedMethods.includes(method)) {
			const index = temp2[activityKey].selectedMethods.indexOf(method);
			if (index > -1) {
				temp2[activityKey].selectedMethods.splice(index, 1);
			}
		} else {
			temp2[activityKey].selectedMethods.push(method);
		}
		setNewlySelectedCleaningActivities(temp2);
		///TODO
	};

	const addNewCleaningActivityToSelectedList = (key) => {
		//remove it from possible activities to add
		var temp = { ...cleaningActivitiesToSelect };
		delete temp[key]
		setCleaningActivitiesToSelect(temp);

		//add to newly selected list
		var temp2 = { ...newlySelectedCleaningActivities };
		temp2[key] = { selectedMethods: [] };
		setNewlySelectedCleaningActivities(temp2);
	};

	const removeNewCleaningActivityToSelectedList = (key) => {
		//remove from newly selected activities list
		var temp2 = { ...newlySelectedCleaningActivities };
		if (temp2[key] != null) {
			delete temp2[key];
		}
		setNewlySelectedCleaningActivities(temp2);

		if (cleaningActivities[key] != null) {
			//Add to list of possible selections
			var temp = { ...cleaningActivitiesToSelect };
            temp[key] = cleaningActivities[key];
            setCleaningActivitiesToSelect(temp);
		}
	};

	const saveChanges = () => {
		saveSelectedCleaningActivities(newlySelectedCleaningActivities);
		setIsOpenDialog(false);
	};

	return (
		<div>
			<Dialog
				open={isOpenDialog}
				fullScreen
				onClose={(event) => setIsOpenDialog(false)}
				TransitionComponent={Transition}
			>
				<AppBar position="static">
					<Toolbar>
						<Typography variant="h5" className={classes.title}>
							Feladatlisták hozzárendelése
						</Typography>
						<IconButton
							aria-label="close"
							color="inherit"
							onClick={() => {
								setIsOpenDialog(false);
							}}
						>
							<CloseIcon />
						</IconButton>
					</Toolbar>
				</AppBar>
				<DialogTitle id="scroll-dialog-title">
					<Box component="span" m={3}>
						<Button
							variant="contained"
							color="primary"
							onClick={() => {
								saveChanges();
							}}
						>
							Mentés
						</Button>
					</Box>
				</DialogTitle>
				<DialogContent>
					<Grid container direction="row" spacing={1}>
						<Grid item xs={6}>
							<Paper>
								<Box m={3}>
									<Typography variant="h6">
										Hozzárendelt feladatlisták ({Object.keys(newlySelectedCleaningActivities).length}):
									</Typography>
									<List dense="true">
										{Object.keys(newlySelectedCleaningActivities).map((element) => (
											<ListItem containerStyle={{ backgroundColor: 'blue' }}>
												<ListItemIcon>
													<IconButton edge="start" aria-label="comments">
														<RemoveCircleOutline
															onClick={() => {
																removeNewCleaningActivityToSelectedList(element);
															}}
														/>
													</IconButton>
												</ListItemIcon>
												<ListItemText
													primary={
														<React.Fragment>
															<Typography
																component="span"
																variant="body1"
																className={classes.inline}
																color="textPrimary"
															>
																{cleaningActivities[element] != null ? (
																	cleaningActivities[element].description
																) : (
																	'Törölt takarítási feladat.'
																)}
															</Typography>
														</React.Fragment>
													}
													secondary={
														<React.Fragment>
															<CleaningServicesIcon />
															<FormControl
																component="fieldset"
																sx={{
																	mx: 2
																}}
															>
																<FormGroup aria-label="position" row>
																	{cleaningActivities[element].methods &&
																		cleaningActivities[
																			element
																		].methods.map((item) => (
																			<FormControlLabel
																				value={item}
																				control={
																					<Checkbox
																						size="small"
																						onChange={() =>
																							handleChange(element, item)}
																						checked={
																							newlySelectedCleaningActivities[
																								element
																							].selectedMethods &&
																							newlySelectedCleaningActivities[
																								element
																							].selectedMethods.includes(
																								item
																							)
																						}
																						name={item}
																						sx={{
																							p: 0
																						}}
																					/>
																				}
																				label={item}
																				labelPlacement="end"
																				sx={{
																					fontSize: 12
																				}}
																			/>
																		))}
																</FormGroup>
															</FormControl>
														</React.Fragment>
													}
												/>
											</ListItem>
										))}
									</List>
								</Box>
							</Paper>
						</Grid>
						<Grid item xs={6}>
							<Paper style={{ maxHeight: '100%', overflow: 'auto' }}>
								{/* <Box m={3}> */}
									<SelectCleaningActivityComponent 
										cleaningActivitySelected={addNewCleaningActivityToSelectedList}
    									cleaningActivitiesToSelect={cleaningActivitiesToSelect}/>
									{/* <Typography variant="h6">Feladatlista:</Typography> */}
									{/* <TextField
										id="date"
										label="Szűrés"
										type="text"
										defaultValue={filterText}
										onChange={(event) => {
											setFilterText(event.target.value);
										}}
										InputLabelProps={{
											shrink: true
										}}
									/> */}
									{/* <List>
										{cleaningActivitiesToSelect.map(
											(element) =>
												((filterText.length > 0 &&
													isCleaningActivityContainsText(
														filterText,
														cleaningActivities[element]
													)) ||
													filterText.length == 0) && (
													<ListItem>
														<ListItemIcon>
															<IconButton edge="start" aria-label="comments">
																<AddCircleOutline
																	onClick={() => {
																		addNewCleaningActivityToSelectedList(element);
																	}}
																/>
															</IconButton>
														</ListItemIcon>
														<ListItemText
															primary={
																<React.Fragment>
																	<Typography
																		component="span"
																		variant="body1"
																		className={classes.inline}
																		color="textPrimary"
																	>
																		{cleaningActivities[element].description}
																	</Typography>
																</React.Fragment>
															}
															secondary={
																<React.Fragment>
																	<Divider />
																	<Grid
																		container
																		direction="column"
																		justifyContent="center"
																		alignItems="flex-start"
																	>
																		<Grid item>
																			<Grid
																				container
																				direction="row"
																				justifyContent="flex-start"
																				alignItems="center"
																			>
																				<Grid item>
																					<CleaningServicesIcon />
																				</Grid>
																				{cleaningActivities[element]
																					.methods &&
																					cleaningActivities[
																						element
																					].methods.map((item) => (
																						<Grid item>
																							<Typography
																								component="span"
																								variant="caption"
																								className={
																									classes.inline
																								}
																								color="#BDC3C7"
																							>
																								{item},
																							</Typography>
																						</Grid>
																					))}
																			</Grid>
																		</Grid>
																		<Grid item>
																			<Grid
																				container
																				direction="row"
																				justifyContent="flex-start"
																				alignItems="center"
																			>
																				<Grid item>
																					<LocationOnIcon />
																				</Grid>
																				{cleaningActivities[element].areas &&
																					cleaningActivities[
																						element
																					].areas.map((item) => (
																						<Grid item>
																							<Typography
																								component="span"
																								variant="caption"
																								className={
																									classes.inline
																								}
																								color="#BDC3C7"
																							>
																								{item},
																							</Typography>
																						</Grid>
																					))}
																			</Grid>
																		</Grid>
																	</Grid>
																</React.Fragment>
															}
														/>
													</ListItem>
												)
										)}
									</List> */}
								{/* </B?ox>? */}
							</Paper>
						</Grid>
					</Grid>
				</DialogContent>
			</Dialog>
		</div>
	);
}
