/* eslint-disable no-console */

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@mui/styles';
import { useTheme } from '@mui/material/styles';

import TableCell from '@mui/material/TableCell';
import TableSortLabel from '@mui/material/TableSortLabel';
import { AutoSizer, Column, SortDirection, Table } from 'react-virtualized';
import moment from 'moment';
// import { ReportsConsumer } from '../admin/Reports';

const styles = (theme) => ({
	table: {
		fontFamily: useTheme().typography.fontFamily
	},
	flexContainer: {
		display: 'flex',
		alignItems: 'center',
		boxSizing: 'border-box'
	},
	tableRow: {
		cursor: 'pointer'
	},
	tableRowHover: {
		'&:hover': {
			backgroundColor: useTheme().palette.grey[200]
		}
	},
	tableCell: {
		flex: 1
	},
	focus: {
		outline: 0
	},
	noClick: {
		cursor: 'initial'
	}
});

class MuiVirtualizedTable extends React.PureComponent {
	getRowClassName = ({ index }) => {
		const { classes, rowClassName, onRowClick } = this.props;

		return classNames(classes.tableRow, classes.flexContainer, rowClassName, {
			[classes.tableRowHover]: index !== -1 && onRowClick != null
		});
	};

	cellRenderer = ({ cellData, columnIndex = null }) => {
		const { columns, classes, rowHeight, onRowClick } = this.props;
		return (
			<TableCell
				component="div"
				className={classNames(classes.tableCell, classes.flexContainer, {
					[classes.noClick]: onRowClick == null
				})}
				variant="body"
				style={{ height: rowHeight }}
				align={(columnIndex != null && columns[columnIndex].numeric) || false ? 'right' : 'left'}
			>
				{cellData}
			</TableCell>
		);
	};

	headerRenderer = ({ label, columnIndex, dataKey, sortBy, sortDirection }) => {
		const { headerHeight, columns, classes, sort } = this.props;
		const direction = {
			[SortDirection.ASC]: 'asc',
			[SortDirection.DESC]: 'desc'
		};

		const inner =
			!columns[columnIndex].disableSort && sort != null ? (
				<TableSortLabel active={dataKey === sortBy} direction={direction[sortDirection]}>
					{label}
				</TableSortLabel>
			) : (
				label
			);

		return (
			<TableCell
				component="div"
				className={classNames(classes.tableCell, classes.flexContainer, classes.noClick)}
				variant="head"
				style={{ height: headerHeight }}
				align={columns[columnIndex].numeric || false ? 'right' : 'left'}
			>
				{inner}
			</TableCell>
		);
	};

	render() {
		const { classes, columns, ...tableProps } = this.props;
		return (
			<AutoSizer>
				{({ height, width }) => (
					<Table
						className={classes.table}
						height={height}
						width={width}
						{...tableProps}
						rowClassName={this.getRowClassName}
					>
						{columns.map(({ cellContentRenderer = null, className, dataKey, ...other }, index) => {
							let renderer;
							if (cellContentRenderer != null) {
								renderer = (cellRendererProps) =>
									this.cellRenderer({
										cellData: cellContentRenderer(cellRendererProps),
										columnIndex: index
									});
							} else {
								renderer = this.cellRenderer;
							}

							return (
								<Column
									key={dataKey}
									headerRenderer={(headerProps) =>
										this.headerRenderer({
											...headerProps,
											columnIndex: index
										})}
									className={classNames(classes.flexContainer, className)}
									cellRenderer={renderer}
									dataKey={dataKey}
									{...other}
								/>
							);
						})}
					</Table>
				)}
			</AutoSizer>
		);
	}
}

MuiVirtualizedTable.propTypes = {
	classes: PropTypes.object.isRequired,
	columns: PropTypes.arrayOf(
		PropTypes.shape({
			cellContentRenderer: PropTypes.func,
			dataKey: PropTypes.string.isRequired,
			width: PropTypes.number.isRequired
		})
	).isRequired,
	headerHeight: PropTypes.number,
	onRowClick: PropTypes.func,
	rowClassName: PropTypes.string,
	rowHeight: PropTypes.oneOfType([ PropTypes.number, PropTypes.func ]),
	sort: PropTypes.func
};

MuiVirtualizedTable.defaultProps = {
	headerHeight: 56,
	rowHeight: 56
};

const WrappedVirtualizedTable = withStyles(styles)(MuiVirtualizedTable);
let id = 0;
function createData(template, location, createdAt, createdBy, scoreTrue, scoreFixed, score) {
	id += 1;
	return { id, template, location, createdAt, createdBy, scoreTrue, scoreFixed, score };
}

function createRows(data, venue) {
	const rows = [];
	if (data != null && data.length > 0) {
		data.forEach((report) => {
			rows.push(
				createData(
					report.name,
					getAreaName(venue.areas[report.location]),
					moment(new Date(report.createdAt)).format('YYYY-MM-DD HH:mm:ss'),
					report.createdBy,
					report.scoreTrue,
					report.scoreFixed,
					report.scoreTrue && report.scoreFixed ? report.scoreTrue + report.scoreFixed : report.score
				)
			);
		});
	}
	return rows;
}

function getAreaName(area) {
	var areaName = '';
	if (area) {
		areaName += area.building ? area.building + ' Épület ' : '';
		areaName += area.floor ? area.floor + '. Emelet ' : '';
		areaName += area.name + ' ' + area.number;
	}
	return areaName;
}

function ReportsTableView({ data, venue, onOpenReportClick }) {
	const rows = createRows(data, venue);
	return rows.length > 0 ? (
		<WrappedVirtualizedTable
			rowCount={rows.length}
			rowGetter={({ index }) => rows[index]}
			onRowClick={(event) => onOpenReportClick(data[event.index])}
			columns={[
				{
					width: 100,
					flexGrow: 1.0,
					label: 'Template',
					dataKey: 'template',
					flexGrow: 1.0
				},
				{
					width: 100,
					label: 'Terület',
					dataKey: 'location',
					flexGrow: 1.0
				},
				{
					width: 100,
					label: 'Létrehozva',
					dataKey: 'createdAt',
					flexGrow: 1.0
				},
				{
					width: 120,
					label: 'Készítő',
					dataKey: 'createdBy',
					numeric: true,
					flexGrow: 1.0
				},
				{
					width: 60,
					label: 'Jó',
					dataKey: 'scoreTrue',
					numeric: true,
					flexGrow: 1.0
				},
				{
					width: 60,
					label: 'Javított',
					dataKey: 'scoreFixed',
					numeric: true,
					flexGrow: 1.0
				},
				{
					width: 60,
					label: 'Összesen',
					dataKey: 'score',
					numeric: true,
					flexGrow: 1.0
				}
			]}
		/>
	) : (
		'Erre az időintervallumra nincs megjeleníthető adat.'
	);
}

export default ReportsTableView;
