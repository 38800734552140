import React from 'react';
import moment from 'moment';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { useTheme } from '@mui/styles';

function ReportsDateRangeFilter({ fromDate, toDate, setFromDate, setToDate, selectFilterAction }) {
	const theme = useTheme();

	return (
		<form>
			<TextField
				id="date"
				label="Mettol"
				type="date"
				defaultValue={fromDate}
				onChange={(event) => {
					setFromDate(event.target.value);
				}}
				InputLabelProps={{
					shrink: true
				}}
			/>
			<TextField
				id="date"
				label="Meddig"
				type="date"
				defaultValue={toDate}
				onChange={(event) => {
					setToDate(event.target.value);
				}}
				InputLabelProps={{
					shrink: true
				}}
			/>
			<Button variant="contained" className={useTheme().button} onClick={selectFilterAction}>
				Szűrés
			</Button>
		</form>
	);
}

export default ReportsDateRangeFilter;
