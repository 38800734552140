import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { useTheme } from '@mui/material/styles';

import Dialog from '@mui/material/Dialog';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import Toolbar from '@mui/material/Toolbar';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import Slide from '@mui/material/Slide';

const styles = (theme) => ({
	appBar: {
		position: 'relative'
	},
	textField: {
		marginLeft: useTheme().spacing.unit,
		marginRight: useTheme().spacing.unit,
		width: '100%'
	},
	flex: {
		flex: 1
	},
	button: {
		backgroundColor: '#09AFEF',
		color: '#FFFFFF'
	},
	deleteButton: {
		backgroundColor: '#E30000',
		color: '#FFFFFF'
	}
});

function Transition(props) {
	return <Slide direction="up" {...props} />;
}

function TemplateViewDialog({
	isOpenDialog,
	closeDialog,
	saveTemplate,
	templateToEdit,
	updateHint,
	updateName,
	addNewFormItem,
	removeFormItem,
	deleteTemplate,
	classes
}) {
	return (
		<Dialog fullScreen open={isOpenDialog} onClose={(event) => closeDialog()} TransitionComponent={Transition}>
			<AppBar className={classes.appBar}>
				<Toolbar>
					<Typography variant="h6" color="inherit" className={classes.flex}>
						{templateToEdit.id ? templateToEdit.name : 'Űrlap létrehozása'}
					</Typography>
					<IconButton color="inherit" onClick={(event) => closeDialog()} aria-label="Close">
						<CloseIcon />
					</IconButton>
				</Toolbar>
			</AppBar>
			{templateToEdit.id ? (
				<Button
					variant="contained"
					className={classes.deleteButton}
					onClick={(event) => deleteTemplate(templateToEdit)}
				>
					Űrlap törlése
				</Button>
			) : null}
			<List>
				<ListItem>
					<TextField
						id="outlined-name"
						label="Űrlap neve"
						className={classes.textField}
						value={templateToEdit.name}
						onChange={(event) => updateName(event.target.value)}
						margin="normal"
						variant="outlined"
					/>
				</ListItem>
				<ListItem>
					<h3>Űrlap:</h3>
				</ListItem>
				{templateToEdit.form_test.map((row, index) => (
					<div>
						<ListItem>
							<TextField
								id="standard-name"
								label={`${index + 1}.Kérdés`}
								className={classes.textField}
								value={row.hint}
								onChange={(event) => updateHint(event.target.value, index)}
								margin="normal"
							/>
							<IconButton
								aria-label="Edit"
								className={classes.margin}
								onClick={(event) => removeFormItem(index)}
							>
								<DeleteIcon />
							</IconButton>
						</ListItem>
						<Divider />
					</div>
				))}
				<ListItem>
					<Button onClick={(event) => addNewFormItem({ type: 'SWITCH', hint: '' })}>
						Új elem hozzáadása
					</Button>
					<Button
						variant="contained"
						className={classes.button}
						onClick={(event) =>
							templateToEdit.name.length > 1
								? saveTemplate(templateToEdit)
								: this.setState({ showError: true })}
					>
						Mentés
					</Button>
				</ListItem>
			</List>
		</Dialog>
	);
}

TemplateViewDialog.propTypes = {
	classes: PropTypes.object.isRequired
};

export default withStyles(styles)(TemplateViewDialog);
