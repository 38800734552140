import React from 'react';

import { makeStyles } from '@mui/styles';
import BackIcon from '@mui/icons-material/ArrowBack';
import { Grid, TextField, IconButton, Typography } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import AdapterDateFns from '@mui/lab/AdapterDateFns';

import { LocalizationProvider, DatePicker } from '@mui/lab';

import moment from 'moment';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexWrap: 'wrap',
		padding: 10
	}
}));

export default function DayPicker({ month, updateMonth }) {
	const classes = useStyles();
	const [ selectedDate, setSelectedDate ] = React.useState(moment(month).toDate());

	const handleNextClick = () => {
		const newDate = moment(month).add(1, 'months').toDate();
		updateMonth(newDate);
		setSelectedDate(newDate);
		console.log('next clicked');
	};
	const handlePreviousClick = () => {
		const newDate = moment(month).add(-1, 'months').toDate();
		updateMonth(newDate);
		setSelectedDate(newDate);
		console.log('previous clicked');
	};

	const handleDateChange = (date) => {
		setSelectedDate(date);
		updateMonth(date);
	};

	return (
		<LocalizationProvider dateAdapter={AdapterDateFns}>
			<Grid container direction="row" justifyContent="flex-start" alignItems="center" className={classes.root}>
				<Grid key="previous-button" item>
					<IconButton aria-label="back" size="small" onClick={handlePreviousClick}>
						<ArrowBackIosIcon fontSize="small" />
					</IconButton>
				</Grid>

				<DatePicker
					views={[ 'year', 'month' ]}
					label="Válasszon hónapot"
					value={selectedDate}
					onChange={handleDateChange}
					renderInput={(params) => <TextField {...params} helperText={null} />}
				/>
				{/* <DatePicker
					views={[ 'year', 'month' ]}
					id="date-picker-inline"
					value={selectedDate}
					onChange={handleDateChange}
				/> */}
				<Grid key="next-button" item>
					<IconButton aria-label="back" size="small" onClick={handleNextClick}>
						<ArrowForwardIosIcon fontSize="small" />
					</IconButton>
				</Grid>
			</Grid>
		</LocalizationProvider>
	);
}
