import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material/styles';

const useStyles = makeStyles(() => ({
	progress: {
		margin: useTheme().spacing(2),
		color: '#FFFFFF'
	},
	dialogPaper: {
		backgroundColor: '#039be5'
	},
	dialogTitle: {
		'& h2': {
			color: 'white'
		}
	}
}));

export default function LoadingDialog({ open, title }) {
	const classes = useStyles();

	return (
		<Dialog classes={{ paper: classes.dialogPaper }} open={open} aria-labelledby="form-dialog-title"  PaperProps={{
			style: {
			  backgroundColor: 'transparent',
			  boxShadow: 'none',
			},
		  }}>
			
			<DialogTitle className={classes.dialogTitle} color="common.white" id="simple-dialog-title">
				
				<Typography variant="h4" color="common.white" noWrap sx={{ flexGrow: 1 }}>
				{title}
					</Typography>
			</DialogTitle>
			<DialogContent align="center">
				<CircularProgress className={classes.progress} size={60}/>
			</DialogContent>
		</Dialog>
	);
}
