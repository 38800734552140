import React from 'react';

import { Grid } from '@mui/material';
import WorksheetCard from './Card/WorksheetCard';

export default function WorksheetsOnArea({ worksheets, positionArea, editable }) {
	const [ temp, setTemp ] = React.useState(0);
	const deleteWorksheetCardOnPositionArea = (worksheetKey) => {
		delete positionArea.assignedWorksheets[worksheetKey];
		var tempInt = temp;
		tempInt++;
		setTemp(tempInt);
	};
	return (
		<Grid container spacing={1} direction="column" justifyContent="flex-start" alignItems="flex-start">
			{positionArea.assignedWorksheets && Object.keys(positionArea.assignedWorksheets).map((key) => (
				<Grid item key={key}>
					{worksheets ? (
						<WorksheetCard
							worksheetOnArea={positionArea.assignedWorksheets[key]}
							editable={editable}
							worksheet={worksheets[key]}
							deleteWorksheetCard={deleteWorksheetCardOnPositionArea}
							worksheetId={key}
						/>
					) : (
						''
					)}
				</Grid>
			))}
		</Grid>
	);
}
