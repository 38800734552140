import React from 'react';
import UUID from 'uuid/v4';
import AddIcon from '@mui/icons-material/Add';
import CategoriesExpansionView from './CategoriesExpansionView';
import { deleteCategoryOnVenueForOrg, updateOrCreateCategoriesOnOrgAndVenue } from '../../../api/manager';
import { getSelectedOrg } from '../../../firebase/auth';

import {
	Dialog,
	DialogTitle,
	DialogActions,
	DialogContent,
	DialogContentText,
	Grid,
	Button,
	TextField,
	IconButton,
	Typography
} from '@mui/material';

function ManageCategories({ venue, templatesMap, updateBrowserHistory }) {
	const [ openNewCategoryDialog, setOpenNewCategoryDialog ] = React.useState();
	const [ openDeleteCategoryDialog, setOpenDeleteCategoryDialog ] = React.useState();
	const [ categoryToDelete, setCategoryToDelete ] = React.useState();

	const [ name, setName ] = React.useState();

	const updateVenueWithCategory = () => {
		var categories = name.split(';');
		categories.map((categoryName) => {
			venue.categories[UUID()] = { name: categoryName, templates: [] };
		});
		setOpenNewCategoryDialog(false);
		updateOrCreateCategoriesOnOrgAndVenue(getSelectedOrg(), venue.id, { categories: venue.categories });
		updateBrowserHistory();
	};

	const deleteCategory = (categoryKey, confirmed) => {
		if (confirmed != true) {
			setOpenDeleteCategoryDialog(true);
			setCategoryToDelete(categoryKey);
		} else {
			delete venue.categories[categoryKey];
			//TODO manage delete categories from areas where is it already set
			deleteCategoryOnVenueForOrg(getSelectedOrg(), venue.id, categoryKey);
			setOpenDeleteCategoryDialog(false);
			updateBrowserHistory();
		}
	};

	const handleChange = (event) => {
		setName(event.target.value);
	};

	return (
		<Grid container alignItems="flex-start" justifyContent="flex-end">
			<Dialog
				onClose={() => setOpenDeleteCategoryDialog(false)}
				aria-labelledby="scroll-dialog-title"
				open={openDeleteCategoryDialog}
			>
				<DialogTitle id="scroll-dialog-title">Biztosan törölni szeretné ezt a kategóriát?</DialogTitle>
				<DialogActions>
					<Button onClick={() => setOpenDeleteCategoryDialog(false)} color="primary">
						Mégsem
					</Button>
					<Button onClick={() => deleteCategory(categoryToDelete, true)} color="secondary">
						Törlés
					</Button>
				</DialogActions>
			</Dialog>
			<Dialog
				onClose={() => setOpenNewCategoryDialog(false)}
				aria-labelledby="scroll-dialog-title"
				open={openNewCategoryDialog}
			>
				<DialogTitle id="scroll-dialog-title">Új kategoria hozzáadása</DialogTitle>
				<DialogContent>
					<DialogContentText>Új kategoria létrehozásához add meg a kategoria nevét.</DialogContentText>
					<TextField
						autoFocus
						margin="dense"
						id="name"
						label="Csoport neve"
						type="areaname"
						fullWidth
						onChange={handleChange}
					/>
					<DialogContentText>
						Egyszerre több hozzáadása ; elválasztásával lehetséges! Pl: elso;masodik
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={updateVenueWithCategory} color="primary">
						Mentes
					</Button>
				</DialogActions>
			</Dialog>
			<Grid item xs={11}>
				<Typography align="left" variant="h6">
					Kategoriak
				</Typography>
			</Grid>
			<Grid item xs={1}>
				<IconButton
					onClick={() => {
						setOpenNewCategoryDialog(true);
					}}
					aria-label="edit"
				>
					<AddIcon />
				</IconButton>
			</Grid>
			<Grid item xs={12}>
				{Object.keys(venue.categories).map((key) => (
					<CategoriesExpansionView
						categoryKey={key}
						venue={venue}
						templatesMap={templatesMap}
						updateBrowserHistory={updateBrowserHistory}
						deleteCategory={deleteCategory}
					/>
				))}
			</Grid>

			<Grid item xs={12} />
		</Grid>
	);
}

export default ManageCategories;
