import React, { useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { Box, Container, Grid } from '@mui/material';
import { TotalVenues } from '../dashboard/elements/TotalVenues';
import { ReportsTrend } from '../dashboard/elements/ReportsTrend';
import { TotalAreas } from '../dashboard/elements/TotalAreas';
import { TotalReports } from '../dashboard/elements/TotalReports';
import { TotalWorkLogs } from '../dashboard/elements/TotalWorkLogs';
import { getMetricsForOrg } from '../api/manager';
import { getSelectedOrg } from '../firebase/auth';
import Skeleton from '@mui/material/Skeleton';

import {store} from '../configure-store'

import { WorklogsTrend } from '../dashboard/elements/WorklogsTrend';

const useStyles = makeStyles((theme) => ({
	fit: {
		maxWidth: '99%',
		maxHeight: '99%'
	}
}));

function HomePage() {
	const classes = useStyles();
	var isMounted = true;
	const [ metrics, setMetrics ] = React.useState({});

	useEffect(() => {
		isMounted = true;
		if (Object.keys(metrics).length == 0) fetchMetrics();
		return () => {
			isMounted = false;
			store.dispatch({ type: 'TURN-OFF-PROGRESS-BAR' })
		};
	}, []);

	const fetchMetrics = () => {
		store.dispatch({ type: 'TURN-ON-PROGRESS-BAR' })
		getMetricsForOrg(getSelectedOrg())
			.then((result) => {
				if (isMounted) {
					setMetrics(result);
					store.dispatch({ type: 'TURN-OFF-PROGRESS-BAR' })
				}
			})
			.catch((error) => {
				store.dispatch({ type: 'TURN-OFF-PROGRESS-BAR' })
				console.log('Error happened in Venues.js while retriving templates' + error);
			});
	};

	return (
		<div>
		
			<Box
				component="main"
				sx={{
					flexGrow: 1,
					py: 8
				}}
			>
				<Container maxWidth={false}>
					<Grid container spacing={3}>
						<Grid item lg={3} sm={6} xl={3} xs={12}>
							{metrics.venues ? <TotalVenues venuesCount={ metrics.venues.count} /> : 
							<Skeleton 	animation="wave"
								variant="rect"
								width="100%"
								height={100}
								style={{ marginBottom: 15 }}/>}
						</Grid>
						<Grid item xl={3} lg={3} sm={6} xs={12}>
						{metrics.venues ? <TotalAreas areasCount={metrics.areas.count} /> : 
							<Skeleton 	animation="wave"
								variant="rect"
								width="100%"
								height={100}
								style={{ marginBottom: 15 }}/>}
						
						</Grid>
						<Grid item xl={3} lg={3} sm={6} xs={12}>
						{metrics.venues ? <TotalReports
								countInLast7Days={metrics.reports.countInLast7Days}
								countInLast7to14Days={metrics.reports.countInLast7to14Days}
							/> : 
							<Skeleton 	animation="wave"
								variant="rect"
								width="100%"
								height={100}
								style={{ marginBottom: 15 }}/>}
						</Grid>
						<Grid item xl={3} lg={3} sm={6} xs={12}>
						{metrics.venues ? <TotalWorkLogs
								countInLast7Days={metrics.worklogs.countInLast7Days}
								countInLast7to14Days={metrics.worklogs.countInLast7to14Days}
							/> : 
							<Skeleton 	animation="wave"
								variant="rect"
								width="100%"
								height={100}
								style={{ marginBottom: 15 }}/>}
						</Grid>
						<Grid item lg={8} md={8} xl={6} xs={12}>
						{metrics.venues ? <ReportsTrend weeklyMetricsLast7Weeks={metrics.weeklyMetricsLast7Weeks}/> : 
							<Skeleton 	animation="wave"
								variant="rect"
								width="100%"
								height={200}
								style={{ marginBottom: 15 }}/>}
						</Grid>
						<Grid item lg={8} md={8} xl={6} xs={12}>
						{metrics.venues ? <WorklogsTrend weeklyMetricsLast7Weeks={metrics.weeklyMetricsLast7Weeks}/> : 
							<Skeleton 	animation="wave"
								variant="rect"
								width="100%"
								height={200}
								style={{ marginBottom: 15 }}/>}
							
						</Grid>
					{/* 
					<Grid item lg={4} md={6} xl={3} xs={12}>
						<TotalVenues />
					</Grid>
					<Grid item lg={4} md={6} xl={3} xs={12}>
						<TotalVenues />
					</Grid>
					<Grid item lg={8} md={12} xl={9} xs={12}>
						<TotalVenues />
					</Grid> */}
					</Grid>
				</Container>
			</Box>
		</div>
	);
}

export default HomePage;
