import React from 'react';
import { makeStyles } from '@mui/styles';
import { getAreaNameWithoutBuilding } from '../utilities/StringHelper';

import moment from 'moment';

import { Timeline, TimelineItem } from 'vertical-timeline-component-for-react';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Select from '@mui/material/Select';

const useStyles = makeStyles((theme) => ({
	paper: {
		display: 'flex',
		padding: 10
	}
}));


export default function WorkLogsTimeLine({ data, venueSettings }) {
	const classes = useStyles();
	const [ worker, setWorker ] = React.useState(Object.keys(data)[0]);

	const handleChange = (event) => {
		setWorker(event.target.value);
	};

	const getDateTextBasedOnVenueSettings = (venueSettings,worklog) => {
		var returndatetext = worklog.startTime
		if(venueSettings != null && venueSettings.trackCloseTimeOnWorkLogs == true){
			if(worklog.endTime){
				returndatetext += " - " + worklog.endTime + "  (" + worklog.duration +" perc)"
			} else {
				returndatetext += " -  ??  (" + worklog.duration +")"
			}
		}
		return returndatetext
	}

	return data[worker] != null ? (
		<Paper className={classes.paper}>
			<Grid container direction="column" justifyContent="space-evenly" alignItems="flex-start">
				<div>
					<InputLabel id="select-label">Takarító</InputLabel>
					<Select labelId="select-label" id="elect" value={worker} onChange={handleChange}>
						{Object.keys(data).map((key) => (
							<MenuItem value={key}>
								{data[key].user.lastName} {data[key].user.firstName}
							</MenuItem>
						))}
					</Select>
				</div>
				<Timeline lineColor={'#ddd'} animate={false}>
					{data[worker].workLogs.map((log) => (
						<TimelineItem
							key="001"
							dateText={getDateTextBasedOnVenueSettings(venueSettings,log)}
							style={{ color: '#e86971' }}
						>
							{log.area != null && log.area.building != null ? <h4>{log.area.building} Épület</h4> : '-'}
							<h5>{log.area && getAreaNameWithoutBuilding(log.area)}</h5>
							{/* <p>Munka hossza: {log.duration} perc</p> */}
						</TimelineItem>
					))}
				</Timeline>
			</Grid>
		</Paper>
	) : (
		'Nincs bejelentkezés erre a napra'
	);
}
