import React, { useEffect } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CleaningServicesIcon from '@mui/icons-material/CleaningServices';

import AddCircleOutline from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutline from '@mui/icons-material/RemoveCircleOutline';

import { makeStyles } from '@mui/styles';
import {
	Paper,
	TextField,
	Checkbox,
	FormGroup,
	FormControlLabel,
	FormControl,
	Slide,
	Dialog,
	DialogTitle,
	DialogContent,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	IconButton,
	Typography,
	Divider,
	AppBar,
	Toolbar,
	Box,
	Grid,
	Button
} from '@mui/material';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexWrap: 'wrap',
		padding: 10,
		backgroundColor: '#FFFFFF'
	},
	title: {
		flexGrow: 1
	}
}));

function isCleaningActivityContainsText(text, cleaningActivity) {
	var contains = false;
	//if description contains
	if (cleaningActivity.description.toLowerCase().includes(text.toLowerCase())) {
		contains = true;
	}

	//if areas contains
	if (cleaningActivity.areas != null) {
		cleaningActivity.areas.forEach((area) => {
			if (area.toLowerCase().includes(text.toLowerCase())) {
				contains = true;
			}
		});
	}

	//if methods contains
	if (cleaningActivity.methods != null) {
		cleaningActivity.methods.forEach((method) => {
			if (method.toLowerCase().includes(text.toLowerCase())) {
				contains = true;
			}
		});
	}

	return contains;
}

export default function SelectCleaningActivityViewDialog({
	cleaningActivitySelected,
    cleaningActivitiesToSelect
}) {


    const classes = useStyles();
	const [ filterText, setFilterText ] = React.useState('');

    return (
        <Box m={3}>
									<Typography variant="h6">Feladatlista:</Typography>
									<TextField
										id="date"
										label="Szűrés"
										type="text"
										defaultValue={filterText}
										onChange={(event) => {
											setFilterText(event.target.value);
										}}
										InputLabelProps={{
											shrink: true
										}}
									/>
									<List>
										{Object.keys(cleaningActivitiesToSelect).map(
											(element) =>
												((filterText.length > 0 &&
													isCleaningActivityContainsText(
														filterText,
														cleaningActivitiesToSelect[element]
													)) ||
													filterText.length == 0) && (
													<ListItem>
														<ListItemIcon>
															<IconButton edge="start" aria-label="comments">
																<AddCircleOutline
																	onClick={() => {
																		cleaningActivitySelected(element);
																	}}
																/>
															</IconButton>
														</ListItemIcon>
														<ListItemText
															primary={
																<React.Fragment>
																	<Typography
																		component="span"
																		variant="body1"
																		className={classes.inline}
																		color="textPrimary"
																	>
																		{cleaningActivitiesToSelect[element].description}
																	</Typography>
																</React.Fragment>
															}
															secondary={
																<React.Fragment>
																	<Divider />
																	<Grid
																		container
																		direction="column"
																		justifyContent="center"
																		alignItems="flex-start"
																	>
																		<Grid item>
																			<Grid
																				container
																				direction="row"
																				justifyContent="flex-start"
																				alignItems="center"
																			>
																				<Grid item>
																					<CleaningServicesIcon />
																				</Grid>
																				{cleaningActivitiesToSelect[element]
																					.methods &&
																					cleaningActivitiesToSelect[
																						element
																					].methods.map((item) => (
																						<Grid item>
																							<Typography
																								component="span"
																								variant="caption"
																								className={
																									classes.inline
																								}
																								color="#BDC3C7"
																							>
																								{item},
																							</Typography>
																						</Grid>
																					))}
																			</Grid>
																		</Grid>
																		<Grid item>
																			<Grid
																				container
																				direction="row"
																				justifyContent="flex-start"
																				alignItems="center"
																			>
																				<Grid item>
																					<LocationOnIcon />
																				</Grid>
																				{cleaningActivitiesToSelect[element].areas &&
																					cleaningActivitiesToSelect[
																						element
																					].areas.map((item) => (
																						<Grid item>
																							<Typography
																								component="span"
																								variant="caption"
																								className={
																									classes.inline
																								}
																								color="#BDC3C7"
																							>
																								{item},
																							</Typography>
																						</Grid>
																					))}
																			</Grid>
																		</Grid>
																	</Grid>
																</React.Fragment>
															}
														/>
													</ListItem>
												)
										)}
									</List>
								</Box>
    )

}


