import React, { useCallback } from 'react';
import { makeStyles } from '@mui/styles';
import SettingsIcon from '@mui/icons-material/Settings';

import {
	Avatar,
	Typography,
	Dialog,
	DialogTitle,
	DialogActions,
	DialogContent,
	ListItemText,
	ListItem,
	ListItemIcon,
	List,
	Grid,
	Button,
	Card,
	CardHeader,
	Box,
	Chip,
	Checkbox,
	IconButton
} from '@mui/material';

function ManageShare({ venue, updateVenue, users }) {
	const [ scroll, setScroll ] = React.useState('paper');
	const [ openCustomersSelector, setOpenCustomersSelector ] = React.useState(false);
	const [ checked, setChecked ] = React.useState(venue.customersCanAccess);

	const handleToggle = (value) => () => {
		const currentIndex = checked.indexOf(value);
		const newChecked = [ ...checked ];
		if (currentIndex === -1) {
			newChecked.push(value);
		} else {
			newChecked.splice(currentIndex, 1);
		}
		setChecked(newChecked);
	};

	const handleOpenCustomersSelector = () => {
		setChecked(venue.customersCanAccess);
		setOpenCustomersSelector(true);
	};

	const updateVenueWithCustomers = () => {
		venue.customersCanAccess = checked;
		setOpenCustomersSelector(false);
		updateVenue({ id: venue.id, customersCanAccess: venue.customersCanAccess });
		return;
	};

	const userProfileCard = (user) => {
		return (
			<Grid item>
				<Card key={user.id}>
					<CardHeader
						style={{ padding: '8px' }}
						avatar={<Avatar>{user.lastName.charAt(0) + user.firstName.charAt(0)}</Avatar>}
						title={user.firstName + ' ' + user.lastName}
						subheader={user.email}
					/>
				</Card>
			</Grid>
		);
	};

	return (
		<Grid container alignItems="flex-start" justifyContent="flex-end">
			<Dialog
				onClose={() => setOpenCustomersSelector(false)}
				scroll="paper"
				aria-labelledby="scroll-dialog-title"
				open={openCustomersSelector}
			>
				<DialogTitle id="scroll-dialog-title">Felhasznalo kiválasztása</DialogTitle>
				<DialogContent dividers={scroll === 'paper'}>
					<List>
						{Object.keys(users).map(function(key, index) {
							const labelId = `checkbox-list-label-${key}`;

							return (
								<ListItem key={key} role={undefined} dense button onClick={handleToggle(key)}>
									<ListItemText
										id={labelId}
										primary={
											<React.Fragment>
												<Typography
													sx={{ display: 'inline' }}
													component="span"
													variant="body2"
													color="text.primary"
												>
													Név - Email:
												</Typography>
												{' ' +
													users[key].firstName +
													' ' +
													users[key].lastName +
													' - ' +
													users[key].email}
											</React.Fragment>
										}
										secondary={Object.keys(users[key].roles).toString()}
									/>
									<ListItemIcon>
										<Checkbox
											edge="start"
											checked={checked.indexOf(key) !== -1}
											tabIndex={-1}
											disableRipple
											inputProps={{ 'aria-labelledby': labelId }}
										/>
									</ListItemIcon>
								</ListItem>
							);
						})}
					</List>
				</DialogContent>
				<DialogActions>
					<Button onClick={updateVenueWithCustomers} color="primary">
						Mentes
					</Button>
				</DialogActions>
			</Dialog>
			<Grid item xs={11}>
				<Typography align="left" variant="h6">
					Megosztás
				</Typography>
			</Grid>
			<Grid item xs={1}>
				<IconButton onClick={handleOpenCustomersSelector} aria-label="edit">
					<SettingsIcon />
				</IconButton>
			</Grid>
			<Grid item xs={12} />

			<Grid item xs={12}>
				<Box m={1}>
					<Typography align="left" variant="subtitle1">
						Admin
					</Typography>
					<Grid container spacing={1}>
						{venue.customersCanAccess.map(
							(customerId, index) =>
								users[customerId] && users[customerId].roles.admin && userProfileCard(users[customerId])
						)}
					</Grid>
				</Box>
			</Grid>
			<Grid item xs={12}>
				<Box m={1}>
					<Typography align="left" variant="subtitle1">
						Agent
					</Typography>
					<Grid container spacing={1}>
						{venue.customersCanAccess.map(
							(customerId, index) =>
								users[customerId] && users[customerId].roles.agent && userProfileCard(users[customerId])
						)}
					</Grid>
				</Box>
			</Grid>
			<Grid item xs={12}>
				<Box m={1}>
					<Typography align="left" variant="subtitle1">
						Operative
					</Typography>
					<Grid container spacing={1}>
						{venue.customersCanAccess.map(
							(customerId, index) =>
								users[customerId] &&
								users[customerId].roles.operative &&
								userProfileCard(users[customerId])
						)}
					</Grid>
				</Box>
			</Grid>
			<Grid item xs={12}>
				<Box m={1}>
					<Typography align="left" variant="subtitle1">
						Customer
					</Typography>
					<Grid container spacing={1}>
						{venue.customersCanAccess.map(
							(customerId, index) =>
								users[customerId] &&
								users[customerId].roles.customer &&
								userProfileCard(users[customerId])
						)}
					</Grid>
				</Box>
			</Grid>
		</Grid>
	);
}

export default ManageShare;
