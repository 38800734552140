import React from 'react';
import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material/styles';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import CleaningActivityCard from '../Card/CleaningActivityCard';
import SelectCleaningActivityComponent from '../../worksheets/Edit/SelectCleaningActivityComponent';

import {
	Button,
	Grid,
	Box,
	Dialog,
	DialogContent,
	DialogTitle,
	DialogActions,
	Stepper,
	Step,
	StepLabel,
	Typography
} from '@mui/material';

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
		// maxWidth: 360,
		backgroundColor: useTheme().palette.background.paper
	}
}));

const steps = [ 'Egyedi takarítási feladat kiválasztása', 'Ütemezés kiválasztása' ];
export default function SetUniqueCleaningActivityOnSelectedAreasDialog({ open, cleaningActivities, setAreasWithSelectedUniqueCleaningActivity, setOpen }) {
	const [ activeStep, setActiveStep ] = React.useState(0);
	const [ skipped, setSkipped ] = React.useState(new Set());
	const classes = useStyles();
	const [selectedCleaningActivity, setSelectedCleaningActivity] = React.useState({});



	const handleCancel = () => {
		console.log('handle cancel');
		setOpen(false);
	};
	const handleOk = () => {
		console.log('handle ok');
		setAreasWithSelectedUniqueCleaningActivity(selectedCleaningActivity);
		setOpen(false);
		handleReset();
	};

	const isStepSkipped = (step) => {
		return skipped.has(step);
	};

	const handleNext = () => {
		let newSkipped = skipped;
		if (isStepSkipped(activeStep)) {
			newSkipped = new Set(newSkipped.values());
			newSkipped.delete(activeStep);
		}

		setActiveStep((prevActiveStep) => prevActiveStep + 1);
		setSkipped(newSkipped);
	};

	const handleBack = () => {
		setActiveStep((prevActiveStep) => prevActiveStep - 1);
	};

	const handleSkip = () => {
		setActiveStep((prevActiveStep) => prevActiveStep + 1);
		setSkipped((prevSkipped) => {
			const newSkipped = new Set(prevSkipped.values());
			newSkipped.add(activeStep);
			return newSkipped;
		});
	};

	const handleReset = () => {
		setActiveStep(0);
		setSelectedCleaningActivity({});
	};

	const cleaningActivitySelected = (value) => {
		const temp = { ...selectedCleaningActivity };
		if (!temp.hasOwnProperty(value)) {
			temp[value] = {};
		} else {
			delete temp[value];
		}
		setSelectedCleaningActivity(temp);
		handleNext();
	};

	return (
		<Dialog  aria-labelledby="confirmation-dialog-title" open={open}>
			<DialogTitle id="confirmation-dialog-title">
				{' '}
				<Stepper
					activeStep={activeStep}
					alternativeLabel
					sx={{
						padding: 5
					}}
				>
					{steps.map((label, index) => {
						const stepProps = {};
						const labelProps = {};
						return (
							<Step key={label} {...stepProps}>
								<StepLabel {...labelProps}>{label}</StepLabel>
							</Step>
						);
					})}
				</Stepper>
			</DialogTitle>
			<DialogContent dividers>
				{activeStep === 0 ? (
					<SelectCleaningActivityComponent 
						cleaningActivitySelected={cleaningActivitySelected}
						cleaningActivitiesToSelect={cleaningActivities}/>
					// <List className={classes.root}>
					// 	{Object.keys(worksheets).map((key) => {
					// 		const labelId = `checkbox-list-label-${worksheets[key].name}`;

					// 		return (
					// 			<ListItem key={key} role={undefined} dense button onClick={handleToggle(key)}>
					// 				<ListItemIcon>
					// 					<Checkbox
					// 						edge="start"
					// 						checked={Object.keys(checked).indexOf(key) !== -1}
					// 						tabIndex={-1}
					// 						disableRipple
					// 						inputProps={{ 'aria-labelledby': labelId }}
					// 					/>
					// 				</ListItemIcon>
					// 				<ListItemText
					// 					id={labelId}
					// 					primary={worksheets[key].name}
					// 					secondary={worksheets[key].description}
					// 				/>
					// 				{/* <ListItemSecondaryAction>
					// 				<IconButton edge="end" aria-label="comments">
					// 					<CommentIcon />
					// 				</IconButton>
					// 			</ListItemSecondaryAction> */}
					// 			</ListItem>
					// 		);
					// 	})}
					// </List>
					// ''
				) : (
					''
				)}
				{activeStep === 1 ? (
					//selectedCleaningActivity.length > 0 && <CleaningActivityCard cleaningActivityOnArea={cleaningActivities[selectedCleaningActivity]} cleaningActivity={cleaningActivities[selectedCleaningActivity]} />
					Object.keys(selectedCleaningActivity).map((key) => (
						<Box key={key} m={1} width="400px">
							<CleaningActivityCard cleaningActivityOnArea={selectedCleaningActivity[key]} cleaningActivity={cleaningActivities[key]} editable={true} />
							{/* <WorksheetCard worksheetOnArea={checked[key]} worksheet={worksheets[key]} editable={true} /> */}
						</Box>
					))
				) : (
					''
				)}
			</DialogContent>
			<DialogActions>
				<Button autoFocus onClick={handleCancel} color="secondary">
					Mégse
				</Button>
				{activeStep === 0 ? (
					// <Button onClick={handleNext} color="primary">
					// 	Következő
					// </Button>
					""
				) : (
					<Button onClick={handleOk} color="primary">
						Kész
					</Button>
				)}
			</DialogActions>
		</Dialog>
	);
}
