import React, { useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

import { useSelector, useDispatch, connect } from 'react-redux'

import TopNavigationBar from '../utilities/TopNavigationBar';
import { getSubmittedReportsByVenueForOrg_GroupedByAreagroups, getVenuesWithAreas } from '../api/manager';
import { getLoggedInUserEmail, getSelectedOrg } from '../firebase/auth';
import {getAdHocTasksByVenueId, submitNewAdHocTask} from '../actions/adHocTasksActions'
import AddIcon from '@mui/icons-material/Add';
import { Skeleton, TextField,Dialog, DialogTitle, DialogContent, DialogActions,Paper, Grid, IconButton, Divider, Tab, AppBar, Box, Tabs, InputBase,LinearProgress, Typography,Checkbox, FormGroup, FormControlLabel, Button } from '@mui/material';
import ToDoAdHocTasks from './ad-hoc-tasks tables/ToDoAdHocTasks';
import AllAdhocTasks from './ad-hoc-tasks tables/AllAdHocTasksTable';
import Autocomplete from '@mui/material/Autocomplete';
import UUID from 'uuid/v4';

import RefreshIcon from '@mui/icons-material/Refresh';

import { getAreaName } from '../utilities/StringHelper';
import bytesToUuid from 'uuid/lib/bytesToUuid';
import moment from 'moment';


	function parseTask(task,venues){
		return {
			description: task.description,
			state: task.status,
			id: task.id,
			createdAt: moment(task.createdBy.createdAt).format('YYYY-MM-DD HH:mm'),
			completedAt: moment(task.completedAt).format('YYYY-MM-DD HH:mm'),
			createdBy: task.createdBy,
			duration: task.completedAt &&
			moment(task.completedAt)
								.diff(moment(task.createdAt), 'minutes', true)
								.toFixed(1),
			assignedTo: task.assignedTo,
			areaObject: venues && venues.areas && venues.areas[task.areaId],
			area: venues && venues.areas && venues.areas[task.areaId] && `${venues.areas[task.areaId].building && venues.areas[task.areaId].building +' épület'} ${venues.areas[task.areaId].floor && venues.areas[task.areaId].floor +'. szint'} ${venues.areas[task.areaId].name} ${venues.areas[task.areaId].number}`
		}
	}

function ManageAdHocTasksOnVenue({adhocTasks}) {
	const [ venue, setVenue ] = React.useState({});
	const [ doneSince, setDoneSince ] = React.useState(10);
	const [ openAddNewAdHocTask, setOpenAddNewAdHocTask ] = React.useState(false);
	const [newTaskAreaId, setNewTaskAreaId] = React.useState("")
	const [newTaskDescription, setNewTaskDescription] = React.useState("")

	const dispatch = useDispatch()

	// const [adhoctasks, setAdHocTasks] = React.useState({})

	const history = useHistory();
	const locationQuery = new URLSearchParams(useLocation().search);
	const location = useLocation();

	const venues = useSelector((state) => state.venues[locationQuery.get('id')])


	useEffect(() => {
		if (venue.id != locationQuery.get('id')) {
			if (location.state) {
				setVenue(location.state.venue);
				refreshAdHocTasksByVenue(location.state.venue)
			} else {
				getVenuesWithAreas(getSelectedOrg())
					.then((result) => {
						const venueId = locationQuery.get('id');
						const tempVenue = result.data.venues[venueId];
						tempVenue.id = venueId;
						setVenue(tempVenue);
						refreshAdHocTasksByVenue(tempVenue)
					})
					.catch((error) => {
						console.log('Error happened in Venues.js while retriving Venues' + error);
					});
			}
		}
	});

	const generateDataFromTasks = (adHocTasks,venueId) => {
		var rows = [];
		if(adhocTasks && adhocTasks[venueId]){
		Object.values(adHocTasks[venueId]).map(function(tasks) {
			tasks.map(function(task) {
			rows.push(parseTask(task,venues));
			})
		});
		// allAdHocTasks.inProgress.map(function(task) {
		// 	rows.push(parseTask(task));
		// });
		// allAdHocTasks.done.map(function(task) {
		// 	rows.push(parseTask(task));
		// });
		return rows;
	}
	};

	const refreshAdHocTasksByVenue = (venue) => {
		dispatch(getAdHocTasksByVenueId(getSelectedOrg(),venue.id))
	}

	const geAreasToSelect = () => {
		var areasArray = []
		Object.keys(venue.areas).forEach((key) => {
			areasArray.push({label:getAreaName(venue.areas[key]), id:key})
		})
		return areasArray
	}

	const submitNewAdHocTaskAction = () => {
		 setOpenAddNewAdHocTask(false)
		dispatch(submitNewAdHocTask(getSelectedOrg(),{
			description:newTaskDescription,
			imageAttached:false,
			id:UUID(),
			createdAt:Date(),
			areaId:newTaskAreaId,
			createdBy:getLoggedInUserEmail(),
			status:"todo"
		},venue.id))

	}

	const handleChange = (event) => {
		setNewTaskDescription(event.target.value)
	};

	const renderDialog = () => {
		 return (<Dialog
			onClose={() => setOpenAddNewAdHocTask(false)}
			scroll="paper"
			aria-labelledby="scroll-dialog-title"
			open={openAddNewAdHocTask}
		>
			<DialogTitle id="scroll-dialog-title">Új feladat hozzáadása!</DialogTitle>
			<DialogContent >
				{venue.areas && <Autocomplete
					disablePortal
					onChange={(event, value) => setNewTaskAreaId(value.id)} // prints the selected value
					id="combo-box-demo"
					options={geAreasToSelect()}
					isOptionEqualToValue={(option, value) => option.id === value.id}
					getOptionLabel={(option) => option.label}
					// value={value => value.id}
					sx={{ width: 300 }}
					renderInput={(params) => <TextField {...params} label="Terület*" />}
					/>}
				<TextField
					id="standard-multiline-static"
					fullWidth
					label="Feladat leírása*:"
					// value={newTaskDescription}
					onChange={handleChange} // prints the selected value
					multiline
					rows={6}
					variant="standard"
				/>
			</DialogContent>
			<DialogActions>
				<Button onClick={submitNewAdHocTaskAction} color="primary">
					Mentes
				</Button>
			</DialogActions>
		</Dialog>)
	}


	return (<div>
	<TopNavigationBar title={venue ? venue.name : ''} />
	{renderDialog()}
	{venue.id == null ? <Skeleton variant="rectangular" height={300}/> :
		<Box m={1}>
			<h3>Eseti Feladatok</h3>
			<Grid key={venue.name} container alignItems="flex-start" justifyContent="flex-end">
					<Grid item xs={11}>
					<FormGroup row>
						{/* 
							<IconButton color="primary" aria-label="refresh" component="span" size="large">
								<RefreshIcon />
							</IconButton>
							<FormControlLabel control={<Checkbox defaultChecked />} label="Új" />
							<FormControlLabel control={<Checkbox defaultChecked/>} label="Folyamatban" />
							<Paper
								component="form"
								sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 165 }}
								>
								<FormControlLabel control={<Checkbox defaultChecked/>} label="Kész" />
								<InputBase
									sx={{ ml: 1, flex: 1 }}
									value = {doneSince}
									inputProps={{ 'aria-label': 'search google maps' }}
								/>
								napja
							</Paper>
						*/}
						</FormGroup> 
					</Grid>
					<Grid xs={1}>
						<Button variant="contained" aria-label="add" onClick={() => {setOpenAddNewAdHocTask(true)}}>
							Új feladat
						</Button>
					</Grid>
				</Grid>
			<AllAdhocTasks allAdHocTasksRows={generateDataFromTasks(adhocTasks,venue.id)} />
		</Box>
	}
</div> );
}


const mapStateToProps = function(state) {
	return {
	  adhocTasks: state.adhocTasks
	}
  }
export default connect(mapStateToProps)(ManageAdHocTasksOnVenue)