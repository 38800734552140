import React, { useEffect } from 'react';
import { makeStyles } from '@mui/styles';

import DefaultBackgroundImage from './defaultVenueIcon.png';
import { Avatar, Grid, Typography, ButtonBase } from '@mui/material';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexWrap: 'wrap',
		minWidth: 300,
		width: '100%'
	},
	base: {
		border: '1px solid #32a1ce',
		borderRadius: 25,
		height: 180,
		backgroundColor: 'common.white',
		'&:hover, &$focusVisible': {
			border: '1px solid',
			opacity: 0.9
		}
	},

	focusVisible: {}
}));

export default function VenueCard({ venue, onClick }) {
	const classes = useStyles();

	return (
		<ButtonBase
			focusRipple
			key={venue.id}
			onClick={() => onClick(venue)}
			className={classes.base}
			focusVisibleClassName={classes.focusVisible}
			style={{
				width: 150
			}}
		>
			<Grid container spacing={2} direction="column" justifyContent="space-between" alignItems="center">
				<Grid item>
					<Avatar
						variant="square"
						alt={venue.name}
						src={venue.iconUrl ? venue.iconUrl : DefaultBackgroundImage}
						sx={{ width: 56, height: 56 }}
					/>
				</Grid>
				<Grid item>
					<Typography component="span" variant="button" color="primary">
						{venue.name}
					</Typography>
				</Grid>
			</Grid>
		</ButtonBase>
	);
}
