import React, { useEffect, forwardRef, useRef } from 'react';
import MaterialTable, { MTableToolbar } from '@material-table/core';
import Check from '@mui/icons-material/Check';
import Clear from '@mui/icons-material/Clear';

import AddBox from '@mui/icons-material/AddBox';
import ArrowUpward from '@mui/icons-material/ArrowUpward';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';
import DeleteOutlined from '@mui/icons-material/DeleteOutline';
import Edit from '@mui/icons-material/Edit';
import Delete from '@mui/icons-material/Delete';
import FilterList from '@mui/icons-material/FilterList';
import FirstPage from '@mui/icons-material/FirstPage';
import LastPage from '@mui/icons-material/LastPage';
import Remove from '@mui/icons-material/Remove';
import SaveAlt from '@mui/icons-material/SaveAlt';
import Search from '@mui/icons-material/Search';
import ViewColumn from '@mui/icons-material/ViewColumn';
import { Button, Grid, Box, Dialog, Typography } from '@mui/material';

import SetWorksheetsOnSelectedAreasDialog from './SetWorksheetsOnSelectedAreasDialog';
import SetUniqueCleaningActivityOnSelectedAreasDialog from './SetUniqueCleaningActivityOnSelectedAreasDialog';

import WorksheetsOnArea from '../WorksheetsOnArea';
import UniqueCleaningActivitiesOnArea from '../UniqueCleaningActivitiesOnArea';

const columns = [
	{
		title: 'Terület',
		field: 'name',
		width: 200
	},
	{
		title: 'Kategória',
		field: 'category',
		width: 100
	},
	{
		field: 'worksheets',
		title: 'Hozzárendelt Feladatlisták:',
		render: (rowData) => (
			<WorksheetsOnArea worksheets={rowData.worksheets} positionArea={rowData.positionArea} editable={true} />
		),
		cellStyle: {
			width: 600,
			minWidth: 600
		},
		headerStyle: {
			width: 600,
			minWidth: 600
		}
	},
	{
		field: 'worksheets',
		title: 'Hozzárendelt Egyedi Feladatok:',
		render: (rowData) => (
			<UniqueCleaningActivitiesOnArea cleaningActivities={rowData.cleaningActivities} positionArea={rowData.positionArea} editable={true}/>
			// <WorksheetsOnArea worksheets={rowData.worksheets} positionArea={rowData.positionArea} editable={true} />
		),
		cellStyle: {
			width: 600,
			minWidth: 600
		},
		headerStyle: {
			width: 600,
			minWidth: 600
		}
	}
];

const tableIcons = {
	Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
	Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
	Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
	Delete: forwardRef((props, ref) => <Delete {...props} ref={ref} />),
	DeleteOutlined: forwardRef((props, ref) => <DeleteOutlined {...props} ref={ref} />),
	DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
	Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
	Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
	Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
	FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
	LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
	NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
	PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
	ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
	Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
	SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref} />),
	ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
	ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

const generateAreasWithActivities = (selectedAreas, position) => {
	var tempObject = {};
	selectedAreas.forEach(function(element) {
		if (Object.keys(position.areas).includes(element)) {
			tempObject[element] = position.areas[element];
		} else {
			tempObject[element] = {};
		}
	});
	// setAreasWithActivities(tempObject);
	return tempObject;
};

export default function AddActivitiesToArea({ position, venue, selectedAreas, setActiveStep, doFinish, worksheets, cleaningActivities }) {
	const [ rows, setRows ] = React.useState([]);
	const [ areasWithActivities, setAreasWithActivities ] = React.useState(
		generateAreasWithActivities(selectedAreas, position)
	);
	const [ selectedRows, setSelectedRows ] = React.useState([]);
	const [ openActivitiesSelector, setOpenActivitiesSelector ] = React.useState(false);
	const [ openUniqueCleaningActivitySelector, setOpenUniqueCleaningActivitySelector ] = React.useState(false);

	
	const tableRef = useRef();

	useEffect(() => {
		// generateAreasWithActivities();
		generateDataFromVenue();
	}, []);

	const clearSelection = () => {
		// Should check that the element is available to prevent errors
		if (tableRef.current) {
			tableRef.current.onAllSelected(false);
		}
		setSelectedRows([])
	};

	const setAreasWithSelectedWorksheets = (worksheets) => {
		var tempObject = { ...areasWithActivities };
		selectedRows.map((row, rowIndex) => {
			Object.keys(worksheets).map((key) => {
				if(tempObject[row.id].assignedWorksheets==null){
					tempObject[row.id]["assignedWorksheets"] = {}
				}
				tempObject[row.id].assignedWorksheets[key] = { ...worksheets[key] };
			});
		});
		setAreasWithActivities(tempObject);
		generateDataFromVenue();
		clearSelection();
	};
	const 	setAreasWithSelectedUniqueCleaningActivity = (uniqueCleaningActivities) => {
		var tempObject = { ...areasWithActivities };
		selectedRows.map((row, rowIndex) => {
			Object.keys(uniqueCleaningActivities).map((key) => {
				if(tempObject[row.id].assignedUniqueCleaningActivities==null){
					tempObject[row.id]["assignedUniqueCleaningActivities"] = {};
				}
				tempObject[row.id].assignedUniqueCleaningActivities[key] = { ...uniqueCleaningActivities[key] };
			});
		});
		setAreasWithActivities(tempObject);
		generateDataFromVenue();
		clearSelection();
	}

	const addCleaningActivites = () => {
		setOpenActivitiesSelector(true);
		console.log('add');
	};
	const addUniqueCleaningActivity = () => {
		setOpenUniqueCleaningActivitySelector(true);
		console.log('add');
	};


	const generateDataFromVenue = () => {
		var rows = [];
		Object.keys(areasWithActivities).forEach(function(key) {
			let area = venue.areas[key];
			if(area != null){
				rows.push({
					name: `${area.building ? area.building + ' épület' : ''} ${area.floor
						? area.floor + '. emelet'
						: ''} ${area.name} ${area.number}`,
					category: venue.categories[area.category] ? venue.categories[area.category].name : '',
					worksheets: worksheets,
					cleaningActivities: cleaningActivities,
					positionArea: areasWithActivities[key],
					id: key
				});
			}
		});
		setRows(rows);
	};

	return (
		<div style={{ height: '100%', width: '100%' }}>
			<SetWorksheetsOnSelectedAreasDialog
				open={openActivitiesSelector}
				worksheets={worksheets}
				setOpen={setOpenActivitiesSelector}
				setAreasWithSelectedWorksheets={setAreasWithSelectedWorksheets}
			/>
			<SetUniqueCleaningActivityOnSelectedAreasDialog
				open={openUniqueCleaningActivitySelector}
				cleaningActivities={cleaningActivities}
				setOpen={setOpenUniqueCleaningActivitySelector}
				setAreasWithSelectedUniqueCleaningActivity={setAreasWithSelectedUniqueCleaningActivity}
			/>
			openUniqueCleaningActivitySelector
			<Grid container alignItems="center" justifyContent="space-between">
				<Grid item>
					<Box component="span" m={3}>
						<Button
							variant="contained"
							color="primary"
							onClick={() => {
								setActiveStep(0);
							}}
						>
							Vissza
						</Button>
					</Box>
				</Grid>
				<Grid item>
					<Box component="span" m={3}>
						<Button
							variant="contained"
							color="primary"
							onClick={() => {
								doFinish(areasWithActivities);
							}}
						>
							Kész
						</Button>
					</Box>
				</Grid>
			</Grid>
			<Box m="2">
				Feladatlista hozzáadásához jelöljön ki egy vagy több területet, majd kattintson a "Feladatlista
				hozzárendelése" gombra.
			</Box>
			<Box component="span" m={3}>
				<Button />
			</Box>

			<MaterialTable
				tableRef={tableRef}
				icons={tableIcons}
				columns={columns}
				data={rows}
				options={{
					// cellStyle: { padding: '0.3em' },
					// headerStyle: { padding: '0.3em' },
					showTitle: false,
					filtering: true,
					sorting: true,
					search: false,
					paging: false,
					padding: 'dense',
					selection: true
				}}
				onSelectionChange={(rows) => setSelectedRows(rows)}
				components={{
					Toolbar: (props) => (
						<div>
							<MTableToolbar {...props} />
							<div style={{ padding: '0px 5px' }}>
								{selectedRows.length > 0 ? (
									<Box m={1}>
										<Button
											sx={{m:1}}
											variant="contained"
											color="secondary"
											onClick={() => {
												addCleaningActivites();
											}}
										>
											Feladatlista hozzárendelése
										</Button>
										<Button
											sx={{m:1}}
											variant="contained"
											color="secondary"
											onClick={() => {
												addUniqueCleaningActivity();
											}}
										>
											Egyedi Feladat hozzárendelése
										</Button>
									</Box>
									
								) : (
									''
								)}
							</div>
						</div>
					)
				}}
			/>
		</div>
	);
}
