import { firebaseAuth } from '../firebase/firebase';
import axios from 'axios';
import {store} from '../configure-store'


const firebaseAuthKey = 'firebaseAuthInProgress';

const baseURL =
	process.env.NODE_ENV === 'production'
		? 'https://europe-west2-cleanspect-prod.cloudfunctions.net'
		: 'https://us-central1-cleanspect-stage.cloudfunctions.net';


//synchronous action creator
const fetchVenuesSuccess = venues => ({
    type: 'FETCH_VENUES_SUCCESS',
    payload: { venues }
})

const saveVenueSuccess = venue => ({
    type: 'SAVE_VENUE',
    venue: venue
})

const deleteVenueSuccess = venueId => ({
    type: 'DELETE_VENUE',
    venueId: venueId
})

const settingsChangeSuccess = (venueId,settings) => ({
    type: 'SETTINGS_CHANGE',
    venueId: venueId,
	settings: settings
})

const updateAreasOnVenueSuccess = (venueId,areas) => ({
    type: 'UPDATE_AREAS_ON_VENUE',
    venueId: venueId,
    areas: areas
})

const deleteAreasOnVenueSuccess = (venueId,areas) => ({
    type: 'DELETE_AREAS_ON_VENUE',
    venueId: venueId,
    areas: areas
})



const instance = axios.create({
    baseURL: baseURL,
    timeout: 100000
});

export function saveVenue(org, venue) {
    store.dispatch({ type: 'TURN-ON-PROGRESS-BAR' })
    return dispatch => {
        firebaseAuth().onAuthStateChanged((user) => {
			if (user) {
				return user
					.getIdToken()
					.then((result) => {
						return instance.post(`/saveVenueForOrg?org=${org}`, venue, {
							headers: { Authorization: 'Bearer ' + result }
						});
					})
					.then((response) => {
                        dispatch(saveVenueSuccess(venue)); 
                        store.dispatch({ type: 'TURN-OFF-PROGRESS-BAR' })
					})
					.catch((error) => {
						console.log('Error at getIdToken: ' + error);
					});
				// User is signed in.
			} else {
				localStorage.removeItem(firebaseAuthKey);
				console.log('User not logged in');
			}
		});
	};
}

export const getVenuesWithAreas = org => {
    store.dispatch({ type: 'TURN-ON-PROGRESS-BAR' })
    return dispatch => {
        firebaseAuth().onAuthStateChanged((user) => {
            if (user) {
                return user
                    .getIdToken()
                    .then((result) => {
                        return instance.get(`/getVenuesForOrg?org=${org}&includeAreas=${true}`, {
                            headers: { Authorization: 'Bearer ' + result }
                        });
                    })
                    .then((response) => {
                        dispatch(fetchVenuesSuccess(response.data.venues)); //store first five posts
                        store.dispatch({ type: 'TURN-OFF-PROGRESS-BAR' })
                    })
                    .catch((error) => {
                        console.log('Error at getIdToken: ' + error);
                    });
                // User is signed in.
            } else {
                localStorage.removeItem(firebaseAuthKey);
                console.log('User not logged in');
            }
        });
    };
}


export function deleteVenue(org, venue) {
    store.dispatch({ type: 'TURN-ON-PROGRESS-BAR' })
	return dispatch => {
		firebaseAuth().onAuthStateChanged((user) => {
			if (user) {
				return user
					.getIdToken()
                    .then((result) => {
						return instance.post(`/saveVenueForOrg?org=${org}`, venue, {
							headers: { Authorization: 'Bearer ' + result }
						});
					})
					.then((response) => {
                        dispatch(deleteVenueSuccess(venue.id)); 
                        store.dispatch({ type: 'TURN-OFF-PROGRESS-BAR' })
					})
					.catch((error) => {
						console.log('Error at getIdToken: ' + error);
						// reject(error);
					});
				// User is signed in.
			} else {
				localStorage.removeItem(firebaseAuthKey);
				console.log('User not logged in');
			}
		});
	};
}
export function saveVenueSettingsChange(org, venueId,settings) {
    store.dispatch({ type: 'TURN-ON-PROGRESS-BAR' })
	return dispatch => {
		firebaseAuth().onAuthStateChanged((user) => {
			if (user) {
				return user
					.getIdToken()
                    .then((result) => {
						return instance.post(`/saveVenueSettingsChange?org=${org}&venueId=${venueId}`, settings, {
							headers: { Authorization: 'Bearer ' + result }
						});
					})
					.then((response) => {
                        dispatch(settingsChangeSuccess(venueId,settings)); 
                        store.dispatch({ type: 'TURN-OFF-PROGRESS-BAR' })
					})
					.catch((error) => {
						console.log('Error at getIdToken: ' + error);
						// reject(error);
					});
				// User is signed in.
			} else {
				localStorage.removeItem(firebaseAuthKey);
				console.log('User not logged in');
			}
		});
	};
}

export function updateOrCreateAreasOnOrgAndVenue(org, venueId, areas) {
    store.dispatch({ type: 'TURN-ON-PROGRESS-BAR' })
	return dispatch => {
		firebaseAuth().onAuthStateChanged((user) => {
			if (user) {
				return user
					.getIdToken()
					.then((result) => {
						console.log('Result of getIdToken: ' + result);
						return instance.post(`/updateOrCreateAreasOnOrgAndVenue?org=${org}&venueId=${venueId}`, {areas: areas}, {
							headers: { Authorization: 'Bearer ' + result }
						});
					})
					.then((response) => {
                        dispatch(updateAreasOnVenueSuccess(venueId, areas)); 
                        store.dispatch({ type: 'TURN-OFF-PROGRESS-BAR' })
					})
					.catch((error) => {
						console.log('Error at getIdToken: ' + error);
					});
				// User is signed in.
			} else {
				localStorage.removeItem(firebaseAuthKey);
				console.log('User not logged in');
			}
		});
	};
}

export function deleteAreasOnVenueForOrg(org, venueId, areas) {
    store.dispatch({ type: 'TURN-ON-PROGRESS-BAR' })
	return dispatch => {
		firebaseAuth().onAuthStateChanged((user) => {
			if (user) {
				return user
					.getIdToken()
					.then((result) => {
						var query = `org=${org}&venueId=${venueId}`;
						return instance.post(
							`/deleteAreasOnVenueForOrg?${query}`,
							{ areasToDelete: areas },
							{
								headers: { Authorization: 'Bearer ' + result }
							}
						);
					})
					.then((response) => {
                        dispatch(deleteAreasOnVenueSuccess(venueId, areas))
                        store.dispatch({ type: 'TURN-OFF-PROGRESS-BAR' })
					})
					.catch((error) => {
						console.log('Error at getIdToken: ' + error);
					});
				// User is signed in.
			} else {
				localStorage.removeItem(firebaseAuthKey);
				console.log('User not logged in');
			}
		});
	};
}

    