import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
// import * as serviceWorker from './serviceWorker';
import App from './components/App';

// const muiTheme = getMuiTheme({
//     appBar: {
//         color: "#37517E",
//         height: 50
//     },
// });

// injectTapEventPlugin();

// const customHistory = createBrowserHistory();
// const Root = () => (
//     <MuiThemeProvider muiTheme={muiTheme}>
//         <Router history={customHistory}>
//             <div>
//                 <Route path="/login" component={Login}/>
//                 <Route path="/app/home" component={Home}/>
//                 <Redirect from="/" to="/login"/>
//             </div>
//         </Router>
//     </MuiThemeProvider>
// );

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// serviceWorker.register();
