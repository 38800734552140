import React, { useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import VenuesSelectorView from '../utilities/VenueSelectorView';
import { getSelectedOrg } from '../firebase/auth';

import NewVenueDialog from './NewVenueDialog';
import { useSelector, useDispatch } from 'react-redux'

import { store } from '../configure-store';
import {saveVenue, getVenuesWithAreas} from '../actions/venuesActions'

import UUID from 'uuid/v4';

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
		padding: 10
	}
}));

function VenueSelector({ selectVenue, showAddNewVenue }) {
	const classes = useStyles();
	const venues = useSelector((state) => state.venues)
	const dispatch = useDispatch()

	const [ openNewVenueDialog, setOpenNewVenueDialog ] = React.useState(false);

	var isMounted = true;

	useEffect(() => {
		if(venues.length == 0){
			dispatch(getVenuesWithAreas(getSelectedOrg()))
		}
		isMounted = true;
		return () => {
			isMounted = false;
		};
	}, []);

	const handleCloseNewVenueDialog = () => {
		setOpenNewVenueDialog(false);
	};

	const handleNewVenue = () => {
		setOpenNewVenueDialog(true);
	};

	const addNewVenue = (name) => {
		setOpenNewVenueDialog(false);
		dispatch({ type: 'TURN-ON-PROGRESS-BAR' })
		var newVenue = {
			id: UUID(),
			name: name,
			categories: {},
			areas: {},
			groups: {},
			isActive: true,
			templatesIdList: [],
			customersCanAccess: []
		};
		dispatch(saveVenue(getSelectedOrg(),newVenue))
	};

	return (
		<div>
			<NewVenueDialog
				open={openNewVenueDialog}
				handleClose={handleCloseNewVenueDialog}
				handleCreateNewVenue={addNewVenue}
			/>
			<div className={classes.root}>
				{Object.keys(venues).length > 0 &&
					<VenuesSelectorView
						venues={venues}
						onCardSelected={selectVenue}
						showAddNewVenue={showAddNewVenue}
						onAddNewVenue={handleNewVenue}
					/>
				}
			</div>
		</div>
	);
}

export default VenueSelector