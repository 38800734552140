import { createStore, applyMiddleware, compose} from 'redux';
import thunk from 'redux-thunk'

import allReducer from './reducers'
import devToolsEnhancer from 'remote-redux-devtools';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// +  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()

// export const store = createStore(allReducer, composeEnhancers(
//     applyMiddleware(thunk)
// ));



export const store = createStore(
    allReducer,
    compose(
      applyMiddleware(thunk),
      window.__REDUX_DEVTOOLS_EXTENSION__
      ? window.__REDUX_DEVTOOLS_EXTENSION__()
      : f => f

    )
  );
