import React, { useEffect } from 'react';
import AreaAndActivities from './AreaAndActivities';
import { Grid, Typography, Divider } from '@mui/material';

export default function AreasAndActivityList({ venue, worksheets,cleaningActivities, positionAreas }) {
	return (
		<div style={{ height: '100%', width: '100%' }}>
			<Grid container alignItems="flex-start" justifyContent="flex-start" sx={{ borderBottom: '1px solid grey' }}>
				<Grid item xs={2}>
					<Typography variant="h6" gutterBottom>Terület:</Typography>
				</Grid>
				<Grid item xs={5}>
					<Typography variant="h6" gutterBottom>Hozzárendelt Feladatlisták:</Typography>
				</Grid>
				<Grid item xs={5}>
					<Typography variant="h6" gutterBottom>Hozzárendelt Egyedi Feladatok:</Typography>
				</Grid>
			</Grid>
			{/* <div key={venue.name}> */}
				{Object.keys(positionAreas).map((areaId) => (
					// <div key={areaId}>
						<AreaAndActivities
							area={venue.areas[areaId]}
							venue={venue}
							worksheets={worksheets}
							cleaningActivities={cleaningActivities}
							positionAreas={positionAreas}
						/>
					// </div>
				))}
			{/* </div> */}
		</div>
	);
}
