import React, { useEffect } from 'react';

import { MenuItem, Box, Typography, Button } from '@mui/material';
import WeeklyRRulePicker from './RRulePicker/WeeklyRRulePicker';
import DailyRRulePicker from './RRulePicker/DailyRRulePicker';

import Select from '@mui/material/Select';
import MonthlyRRulePicker from './RRulePicker/MonthlyRRulePicker';

const frequencyArray = [ 'DAILY', 'WEEKLY', 'MONTHLY' ];

const translateFreqKey = (key) => {
	if (key == 'WEEKLY') {
		return 'Heti';
	} else if (key == 'DAILY') {
		return 'Napi';
	} else if (key == 'MONTHLY') {
		return 'Havi';
	} else if (key == 'YEARLY') {
		return 'Évi';
	}
};

function getFreqIndexFromCleaningActivityOnArea(cleaningActivityOnArea) {
	if (cleaningActivityOnArea && cleaningActivityOnArea.freq) {
		return frequencyArray.indexOf(cleaningActivityOnArea.freq);
	}
	return -1;
}

export default function CleaningActivityCard({ cleaningActivityOnArea, cleaningActivity, editable, deleteUniqueCleaningActivityCard, cleaningActivityId }) {
	const [ freqIndex, setFreqIndex ] = React.useState(getFreqIndexFromCleaningActivityOnArea(cleaningActivityOnArea));

	useEffect(() => {
		// generateAreasWithActivities();
		if (freqIndex != null && frequencyArray[freqIndex] != cleaningActivityOnArea.freq) {
			setFreqIndex(getFreqIndexFromCleaningActivityOnArea(cleaningActivityOnArea));
		}
	});

	const handleFreqChange = (event) => {
		setFreqIndex(event.target.value);
		cleaningActivityOnArea.freq = frequencyArray[event.target.value];
	};

	const renderFreq = () => {
		if (frequencyArray[freqIndex] == 'DAILY') {
			return <DailyRRulePicker worksheet={cleaningActivityOnArea} editable={editable} />;
		}
		if (frequencyArray[freqIndex] == 'WEEKLY') {
			return <WeeklyRRulePicker worksheet={cleaningActivityOnArea} editable={editable} />;
		}
		if (frequencyArray[freqIndex] == 'MONTHLY') {
			return <MonthlyRRulePicker worksheet={cleaningActivityOnArea} editable={editable} />;
		}
		return '';
	};

	return (
		<Box component="section" sx={{ p: 0.5, border: '1px dashed grey' }}>
			<Typography variant="h6" gutterBottom sx={{ display: 'block', m:0, p:0}}>{cleaningActivity ? cleaningActivity.description : 'Törölt feladat'}</Typography>
			<Typography variant="body2" sx={{ display: 'inline', m:0 }}  gutterBottom>Gyakoriság: </Typography>
			<Select 
				sx={{
					'& .MuiSelect-select': {
					   paddingRight: 1,
					   paddingLeft: 0.5,
					   paddingTop: 0.5,
					   paddingBottom: 0.5					}
				  }}
				disabled={!editable}
				labelId="frequency-select-label"
				id="frequency-select"
				value={freqIndex}
				onChange={handleFreqChange}
			>
				{frequencyArray.map((element, index) => (
					<MenuItem key={index} value={index}>
						{translateFreqKey(element)}
					</MenuItem>
				))}
			</Select>
			{cleaningActivityOnArea && renderFreq()}
			{deleteUniqueCleaningActivityCard && editable ? (
					<Button size="small" color="secondary" onClick={() => deleteUniqueCleaningActivityCard(cleaningActivityId)}>
						Törlés
					</Button>
			) : (
				''
			)}

		</Box>
	);
}
