import React from 'react';
import UUID from 'uuid/v4';
import AddIcon from '@mui/icons-material/Add';
import GroupExpansionView from './GroupExpansionView';
import { deleteGroupOnVenueForOrg, updateOrCreateGroupsOnOrgAndVenue } from '../../../api/manager';
import { getSelectedOrg } from '../../../firebase/auth';

import {
	Dialog,
	DialogTitle,
	DialogActions,
	DialogContent,
	DialogContentText,
	Grid,
	Button,
	TextField,
	IconButton,
	Typography
} from '@mui/material';

function ManageGroups({ venue, updateBrowserHistory }) {
	const [ openNewGroupDialog, setOpenNewGroupDialog ] = React.useState();
	const [ openDeleteGroupDialog, setOpenDeleteGroupDialog ] = React.useState();
	const [ groupToDelete, setGroupToDelete ] = React.useState();

	const [ name, setName ] = React.useState();

	const createNewGroupOnVenue = () => {
		var groups = name.split(';');
		groups.map((groupname) => {
			venue.groups[UUID()] = { name: groupname, areas: [] };
		});
		setOpenNewGroupDialog(false);
		updateVenueWithGroup();
	};
	const updateVenueWithGroup = () => {
		updateOrCreateGroupsOnOrgAndVenue(getSelectedOrg(), venue.id, { groups: venue.groups });
		updateBrowserHistory();
	};

	const deleteGroup = (groupKey, confirmed) => {
		if (confirmed != true) {
			setOpenDeleteGroupDialog(true);
			setGroupToDelete(groupKey);
		} else {
			delete venue.groups[groupKey];
			deleteGroupOnVenueForOrg(getSelectedOrg(), venue.id, groupKey);
			updateBrowserHistory();
			setOpenDeleteGroupDialog(false);
		}
	};

	const handleChange = (event) => {
		setName(event.target.value);
	};

	return (
		<Grid container alignItems="flex-start" justifyContent="flex-end">
			<Dialog
				onClose={() => setOpenDeleteGroupDialog(false)}
				aria-labelledby="scroll-dialog-title"
				open={openDeleteGroupDialog}
			>
				<DialogTitle id="scroll-dialog-title">Biztosan törölni szeretné ezt a kategóriát?</DialogTitle>
				<DialogActions>
					<Button onClick={() => setOpenDeleteGroupDialog(false)} color="primary">
						Mégsem
					</Button>
					<Button onClick={() => deleteGroup(groupToDelete, true)} color="secondary">
						Törlés
					</Button>
				</DialogActions>
			</Dialog>
			<Dialog
				onClose={() => setOpenNewGroupDialog(false)}
				aria-labelledby="scroll-dialog-title"
				open={openNewGroupDialog}
			>
				<DialogTitle id="scroll-dialog-title">Új csoport hozzáadása</DialogTitle>
				<DialogContent>
					<DialogContentText>Új csoport létrehozásához add meg a csoport nevét.</DialogContentText>
					<TextField
						autoFocus
						margin="dense"
						id="name"
						label="Csoport neve"
						type="areaname"
						fullWidth
						onChange={handleChange}
					/>
					<DialogContentText>
						Egyszerre több hozzáadása ; elválasztásával lehetséges! Pl: elso;masodik
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={createNewGroupOnVenue} color="primary">
						Mentes
					</Button>
				</DialogActions>
			</Dialog>
			<Grid item xs={11}>
				<Typography align="left" variant="h6">
					Csoportok
				</Typography>
			</Grid>
			<Grid item xs={1}>
				<IconButton
					onClick={() => {
						setOpenNewGroupDialog(true);
					}}
					aria-label="edit"
				>
					<AddIcon />
				</IconButton>
			</Grid>
			<Grid item xs={12}>
				{Object.keys(venue.groups).map((key) => (
					<GroupExpansionView
						groupkey={key}
						venue={venue}
						updateVenue={updateVenueWithGroup}
						deleteGroup={deleteGroup}
					/>
				))}
			</Grid>

			<Grid item xs={12} />
		</Grid>
	);
}

export default ManageGroups;
