import React from 'react';
import CssBaseline from '@mui/material/CssBaseline';

import {
	Snackbar,
	TextField,
	Avatar,
	Paper,
	Box,
	Link,
	Button,
	Typography,
	DialogContentText,
	Grid,
	TextareaAutosize
} from '@mui/material';

import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material/styles';

import { saveLoggedInUserData, isLoggedIn, loginWithEmailAndPassword } from '../firebase/auth';
import { getMe } from '../api/manager';
import MuiAlert from '@mui/material/Alert';

import LoadingDialog from '../utilities/LoadingDialog';

import bgImage from './auth_stock_image.jpeg';

const Alert = React.forwardRef(function Alert(props, ref) {
	return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const useStyles = makeStyles((theme) => ({
	root: {
		height: '100vh'
	},
	image: {
		backgroundImage: `url(${bgImage})`
		// backgroundRepeat: 'no-repeat',
		// backgroundColor: theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
		// backgroundSize: 'cover',
		// backgroundPosition: 'center'
	},
	paper: {
		margin: useTheme().spacing(8, 4),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center'
	},
	avatar: {
		margin: useTheme().spacing(1),
		backgroundColor: useTheme().palette.secondary.main
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: useTheme().spacing(1)
	},
	submit: {
		margin: useTheme().spacing(3, 0, 2)
	},
	title: {
		marginTop: 20,
		marginBottom: 0,
		marginLeft: 25,
		color: '#FFFFFF',
		fontSize: 60,
		lineHeight: 1
	},
	subTitle: {
		marginTop: 0,
		marginBottom: 5,
		marginLeft: 25,
		color: '#FFFFFF',
		fontSize: 40,
		lineHeight: 1
	}
}));

function Copyright() {
	return (
		<Typography variant="body2" color="textSecondary" align="center">
			{'Copyright © '}
			<Link color="inherit" href="https://cleanspect.net">
				CleanSpect
			</Link>{' '}
			{new Date().getFullYear()}
			{'.'}
		</Typography>
	);
}

export default function Login(props) {
	const classes = useStyles();
	const [ values, setValues ] = React.useState({
		email: '',
		password: ''
	});
	const [ snackBar, setSnackBar ] = React.useState({
		snackOpen: false,
		snackMessage: '',
		severity: 'error'
	});
	const [ openLoading, setOpenLoading ] = React.useState(false);

	const onSubmit = (event) => {
		showSignInProgressBar();
		loginWithEmailAndPassword(values.email, values.password)
			.then((result) => {
				return getMe();
			})
			.then((result) => {
				saveLoggedInUserData(result);
				onEnter();
				console.log('Orgs: ' + result.data.org);
				hideSignInProgressBar();
			})
			.catch((error) => {
				setSnackBar({
					snackOpen: true,
					snackMessage: error.message,
					severity: 'error'
				});
				console.log('Error: ' + error);
				hideSignInProgressBar();
			});
	};
	const onEnter = (event) => {
		props.history.push('/home');
	};
	const handleEmailChange = (email) => (event) => {
		setValues({ ...values, [email]: event.target.value });
	};
	const handlePasswordChange = (password) => (event) => {
		setValues({ ...values, [password]: event.target.value });
	};

	const showSignInProgressBar = () => {
		setOpenLoading(true);
	};
	const hideSignInProgressBar = () => {
		setOpenLoading(false);
	};

	const renderSnackBar = () => {
		return (
			<Snackbar
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'center'
				}}
				open={snackBar.snackOpen}
				autoHideDuration={6000}
				onClose={handleClose}
			>
				<Alert onClose={handleClose} severity={snackBar.severity}>
					{snackBar.snackMessage}
				</Alert>
			</Snackbar>
		);
	};

	const handleClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}

		setSnackBar({ snackOpen: false });
	};

	// onChange = (event) => {
	// 	this.setState({ [event.target.name]: event.target.value });
	// };

	return (
		<Grid container component="main" className={classes.root}>
			<LoadingDialog open={openLoading} title="Bejelentkezés folyamatban" />
			<CssBaseline />
			<Grid item xs={false} sm={4} md={7} className={classes.image}>
				<h1 className={classes.title}>CleanSpect</h1>
				<h2 className={classes.subTitle}>portal</h2>
			</Grid>
			<Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
				<div className={classes.paper}>
					<Avatar className={classes.avatar}>
						<LockOutlinedIcon />
					</Avatar>
					<Typography component="h1" variant="h5">
						Sign in
					</Typography>
					<form className={classes.form} noValidate>
						<TextField
							variant="outlined"
							margin="normal"
							required
							fullWidth
							id="email"
							label="Email Address"
							value={values.email}
							name="email"
							autoComplete="email"
							onChange={handleEmailChange('email')}
							autoFocus
						/>
						<TextField
							variant="outlined"
							margin="normal"
							required
							fullWidth
							value={values.password}
							name="password"
							label="Password"
							type="password"
							id="password"
							autoComplete="current-password"
							onChange={handlePasswordChange('password')}
						/>
						{/* <FormControlLabel
								control={<Checkbox value="remember" color="primary" />}
								label="Remember me"
							/> */}
						<Button
							fullWidth
							variant="contained"
							color="primary"
							className={classes.submit}
							onClick={() => {
								onSubmit();
							}}
						>
							Sign In
						</Button>
						<Grid container>
							<Grid item xs>
								<Link href="#" variant="body2">
									Forgot password?
								</Link>
							</Grid>
							{/* <Grid item>
									<Link href="#" variant="body2">
										{"Don't have an account? Sign Up"}
									</Link>
								</Grid> */}
						</Grid>
						<Box mt={5}>
							<Copyright />
						</Box>
						{renderSnackBar()}
					</form>
				</div>
			</Grid>
		</Grid>
		// 	<React.Fragment>
		// 	<CssBaseline />
		// 	<Container maxWidth="sm">
		// 	  <Typography component="div" style={{ backgroundColor: '#cfe8fc', height: '100vh' }} />
		// 	</Container>
		//   </React.Fragment>
		// <div
		// 	style={{
		// 		backgroundColor: '#039be5',
		// 		height: '100vh',
		// 		width: '100%'
		// 	}}
		// >
		// 	<form onSubmit={this.onSubmit}>
		// 		<input
		// 			name="email"
		// 			value={email}
		// 			onChange={this.onChange}
		// 			type="text"
		// 			placeholder="Email Address"
		// 		/>
		// 		<input
		// 			name="password"
		// 			value={password}
		// 			onChange={this.onChange}
		// 			type="password"
		// 			placeholder="Password"
		// 			autoComplete="current-password"
		// 		/>
		// 		<button disabled={isInvalid} type="submit">
		// 			Sign In
		// 		</button>

		// 		{error && <p>{error.message}</p>}
		// 	</form>
		// </div>
	);
}
