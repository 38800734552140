import { firebaseAuth } from '../firebase/firebase';
import axios from 'axios';
import {store} from '../configure-store'


const firebaseAuthKey = 'firebaseAuthInProgress';

const baseURL =
	process.env.NODE_ENV === 'production'
		? 'https://europe-west2-cleanspect-prod.cloudfunctions.net'
		: 'https://us-central1-cleanspect-stage.cloudfunctions.net';


        const instance = axios.create({
            baseURL: baseURL,
            timeout: 100000
        });

//synchronous action creator
const fetchAllAdHocTasksByVenueIdSuccess = (allAdHocTasksByVenueId, venueId) => ({
    type: 'FETCH_ALL_ADHOCTASKS_BY_VENUE_ID_SUCCESS',
    allAdHocTasksByVenueId:  allAdHocTasksByVenueId,
    venueId: venueId
})

const submitAdHocTaskSuccess = (adHocTask,venueId) => ({
    type: 'SUBMIT_ADHOCTASK',
    adHocTask: adHocTask,
    venueId: venueId
})


export function getAdHocTasksByVenueId(org, venueId) {
    store.dispatch({ type: 'TURN-ON-PROGRESS-BAR' })
    return dispatch => {
        firebaseAuth().onAuthStateChanged((user) => {
            if (user) {
                return user
                    .getIdToken()
                    .then((result) => {
                        var query = `org=${org}&venueId=${venueId}`;
                        return instance.get(`/getAllTasksForOrgAtVenueInObject?${query}`, {
                            headers: { Authorization: 'Bearer ' + result }
                        });
                    })
                    .then((response) => {
                            dispatch(fetchAllAdHocTasksByVenueIdSuccess(response.data.tasks,venueId)); //store first five posts
                            store.dispatch({ type: 'TURN-OFF-PROGRESS-BAR' })
                        
                    })
                    .catch((error) => {
                        console.log('Error at getIdToken: ' + error);
                    });
                // User is signed in.
            } else {
                localStorage.removeItem(firebaseAuthKey);
                console.log('User not logged in');
            }
        });
    };
}

export function submitNewAdHocTask(org, adHocTask, venueId) {
    store.dispatch({ type: 'TURN-ON-PROGRESS-BAR' })
    return dispatch => {
        firebaseAuth().onAuthStateChanged((user) => {
			if (user) {
				return user
					.getIdToken()
					.then((result) => {
						return instance.post(`/submitTaskForOrgAtVenue?org=${org}&venueId=${venueId}`, adHocTask, {
							headers: { Authorization: 'Bearer ' + result }
						});
					})
					.then((response) => {
                        dispatch(submitAdHocTaskSuccess(adHocTask,venueId)); 
                        store.dispatch({ type: 'TURN-OFF-PROGRESS-BAR' })
					})
					.catch((error) => {
						console.log('Error at getIdToken: ' + error);
					});
				// User is signed in.
			} else {
				localStorage.removeItem(firebaseAuthKey);
				console.log('User not logged in');
			}
		});
	};
}