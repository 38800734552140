import venuesReducer from './venues'
import adhocTasksReducer from './adhocTasks'

import progressBarReducer from './progressBarReducer'
import {combineReducers} from 'redux'
const allReducers = combineReducers({
    venues: venuesReducer,
    adhocTasks: adhocTasksReducer,
    progressBar: progressBarReducer
})
export default allReducers