import React, { forwardRef, useEffect } from 'react';

import { withStyles } from '@mui/styles';
import { Grid, TextField, Box, Typography } from '@mui/material';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

const weekDays = [ 'MO', 'TU', 'WE', 'TH', 'FR', 'SA', 'SU' ];

function getDaysByWorksheet(worksheet) {
	if (worksheet.freq == 'WEEKLY') {
		return worksheet.byday;
	}
	return [];
}

function getIntervalByWorksheet(worksheet) {
	if (worksheet.freq == 'WEEKLY') {
		return worksheet.interval;
	}
	return null;
}

export default function WeeklyRRulePicker({ worksheet, editable }) {
	const [ days, setDays ] = React.useState(getDaysByWorksheet(worksheet));
	const [ interval, setInterval ] = React.useState(getIntervalByWorksheet(worksheet));

	const handleDays = (event, newDays) => {
		if (newDays.length) {
			setDays(newDays);
			worksheet.byday = newDays;
		}
	};

	const setStyleOnToggleButton = (editable, element) => {
		if (days) {
			if (editable && days.indexOf(element) >= 0) {
				return { backgroundColor: 'rgba(3, 154, 229, 1)', color: 'rgba(255, 255, 255, 1)' };
			}
			if (!editable && days.indexOf(element) >= 0) {
				return { backgroundColor: 'rgba(3, 154, 229, 0.7)', color: 'rgba(255, 255, 255, 0.7)' };
			}
		}
		return {};
	};

	const handleIntervalChange = (e) => {
		setInterval(e.target.value);
		worksheet.interval = e.target.value;
	};

	const translateScheduleKey = (key) => {
		if (key == 'MO') {
			return 'Hé';
		} else if (key == 'TU') {
			return 'Ke';
		} else if (key == 'WE') {
			return 'Sze';
		} else if (key == 'TH') {
			return 'Csü';
		} else if (key == 'FR') {
			return 'Pé';
		} else if (key == 'SA') {
			return 'Szo';
		} else if (key == 'SU') {
			return 'Va';
		}
	};

	return (
				<ToggleButtonGroup sx = {{paddingLeft: 0.5}} size="small" value={days} onChange={handleDays} aria-label="device">
					{weekDays.map((element, index) => (
						editable || (days && days.indexOf(element)>= 0 ) ? 
							(<ToggleButton
								sx = {{p:0.3}}
								display="inline"
								key={index}
								style={setStyleOnToggleButton(editable, element)}
								disabled={!editable}
								value={element}
								aria-label={element}
							>
								{translateScheduleKey(element)}
							</ToggleButton>) : "false"
					))}
				</ToggleButtonGroup>
	);
}
