import React from 'react';

import moment from 'moment';

import huLocale from 'date-fns/locale/hu';

import { Button, Grid, Box, Typography, TextField, Checkbox } from '@mui/material';
import DesktopTimePicker from '@mui/lab/DesktopTimePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

const weekDays = [ 'MO', 'TU', 'WE', 'TH', 'FR', 'SA', 'SU' ];

const translateDayKey = (key) => {
	if (key == 'MO') {
		return 'Hétfő';
	} else if (key == 'TU') {
		return 'Kedd';
	} else if (key == 'WE') {
		return 'Szerda';
	} else if (key == 'TH') {
		return 'Csütörtök';
	} else if (key == 'FR') {
		return 'Péntek';
	} else if (key == 'SA') {
		return 'Szombat';
	} else if (key == 'SU') {
		return 'Vasárnap';
	}
};

export default function EditPositionMetaData({ position, setActiveStep }) {
	const [ positionName, setPositionName ] = React.useState(position.name);
	const [ schedule, setSchedule ] = React.useState(position.schedule);
	const [ value, setValue ] = React.useState(new Date('2018-01-01T00:00:00.000Z'));

	const handlePositionNameChange = (e) => {
		setPositionName(e.target.value);
		position.name = e.target.value;
	};

	const setStartAt = (e) => {
		// setPositionName(e.target.value);
		// position.name = e.target.value;
	};

	const setFinishAt = (e) => {
		// setPositionName(e.target.value);
		// position.name = e.target.value;
	};

	const updateScheduleWithStartTimeAndFinishTime = (key, value) => {
		var tempSchedule = { ...schedule };
		tempSchedule[key] = value;
		setSchedule(tempSchedule);
	};
	return (
		<div style={{ height: '100%', width: '100%' }}>
			<Grid container alignItems="center" justifyContent="space-between">
				<Grid item>
					<Box component="span" m={1}>
						<Typography variant="h5">Pozíció meta adatok:</Typography>
					</Box>
				</Grid>

				<Grid item>
					<Box component="span" m={1}>
						<Button
							variant="contained"
							color="primary"
							onClick={() => {
								position.schedule = schedule;
								setActiveStep(1);
							}}
						>
							Következő
						</Button>
					</Box>
				</Grid>
			</Grid>
			<Grid container direction="column" alignItems="flex-start" justifyContent="space-between">
				<Grid item>
					<Box mt={3}>
						<Typography color="primary" variant="h6">
							Pozíció neve:
						</Typography>
					</Box>
				</Grid>
				<Grid item>
					<Box component="span" m={1}>
						<TextField
							id="standard-text"
							value={positionName}
							onChange={handlePositionNameChange}
							type="text"
							size="small"
						/>{' '}
					</Box>
				</Grid>
				<Grid item>
					<Box component="span" mt={3}>
						<Typography color="primary" variant="h6">
							Beosztás:
						</Typography>
					</Box>
				</Grid>
				<Grid item>
					<Box component="span" m={1}>
						<Grid
							container
							spacing={2}
							direction="column"
							alignItems="flex-start"
							justifyContent="space-between"
						>
							{weekDays.map((element) => (
								<Grid item key={element}>
									<Grid
										container
										spacing={1}
										direction="row"
										alignItems="center"
										justifyContent="flex-start"
									>
										<Grid item>
											<Checkbox
												color="primary"
												checked={schedule.hasOwnProperty(element)}
												onChange={(event) => {
													var tempSchedule = { ...schedule };
													if (event.target.checked) {
														tempSchedule[element] = { startAt: '00:00', finishAt: '00:00' };
													} else {
														delete tempSchedule[element];
													}
													setSchedule(tempSchedule);
												}}
											/>

											<Typography
												color={schedule.hasOwnProperty(element) ? 'primary' : 'textSecondary'}
												variant="body1"
											>
												{' '}
												{translateDayKey(element)}
											</Typography>
										</Grid>
										<LocalizationProvider dateAdapter={AdapterDateFns} locale={huLocale}>
											<Grid item>
												{schedule.hasOwnProperty(element) ? (
													<DesktopTimePicker
														label="Kezdés"
														value={moment(schedule[element].startAt, 'HH:mm')}
														disabled={!schedule.hasOwnProperty(element)}
														onChange={(newValue) => {
															setValue(
																updateScheduleWithStartTimeAndFinishTime(element, {
																	startAt: moment(newValue).format('HH:mm'),
																	finishAt: schedule[element].finishAt
																})
															);
														}}
														renderInput={(params) => <TextField {...params} />}
													/>
												) : (
													''
												)}
											</Grid>
											<Grid item>
												{schedule.hasOwnProperty(element) ? (
													<DesktopTimePicker
														label="Vége"
														value={moment(schedule[element].finishAt, 'HH:mm')}
														disabled={!schedule.hasOwnProperty(element)}
														onChange={(newValue) => {
															setValue(
																updateScheduleWithStartTimeAndFinishTime(element, {
																	startAt: schedule[element].startAt,
																	finishAt: moment(newValue).format('HH:mm')
																})
															);
														}}
														renderInput={(params) => <TextField {...params} />}
													/>
												) : (
													''
												)}
											</Grid>
										</LocalizationProvider>
									</Grid>
								</Grid>
							))}
							<Box component="span" m={1} />
						</Grid>
					</Box>
				</Grid>
			</Grid>
		</div>
	);
}
