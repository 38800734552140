import React from 'react';
import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material/styles';

import clsx from 'clsx';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionActions from '@mui/material/AccordionActions';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Chip from '@mui/material/Chip';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import AddAreaToGroupDialog from './AddAreaToGroupDialog';

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
		padding: 10
	},
	heading: {
		fontSize: useTheme().typography.pxToRem(15)
	},
	secondaryHeading: {
		fontSize: useTheme().typography.pxToRem(15),
		color: useTheme().palette.text.secondary
	},
	icon: {
		verticalAlign: 'bottom',
		height: 20,
		width: 20
	},
	details: {
		alignItems: 'center'
	},
	helper: {
		borderLeft: `2px solid ${useTheme().palette.divider}`,
		padding: useTheme().spacing(1, 2)
	},
	link: {
		color: useTheme().palette.primary.main,
		textDecoration: 'none',
		'&:hover': {
			textDecoration: 'underline'
		}
	}
}));

export default function GroupExpansionView({ venue, groupkey, updateVenue, deleteGroup }) {
	const classes = useStyles();
	const [ modified, setModified ] = React.useState(false);
	const [ openAreasSettingsOnGroup, setOpenAreasSettingsOnGroup ] = React.useState();

	const handleCloseDialog = () => {
		setOpenAreasSettingsOnGroup(false);
	};

	return (
		<div className={classes.root}>
			<AddAreaToGroupDialog
				onClose={handleCloseDialog}
				open={openAreasSettingsOnGroup}
				venue={venue}
				groupkey={groupkey}
				updateVenue={updateVenue}
			/>

			<Accordion defaultExpanded>
				<AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1c-content" id="panel1c-header">
					<div className={classes.column}>
						<Typography align="left" className={classes.heading}>
							{venue.groups[groupkey].name}
						</Typography>
					</div>
				</AccordionSummary>
				<Divider />
				<AccordionDetails className={classes.details}>
					<div>
						{venue.groups[groupkey].areas.map(
							(id) => (venue.areas[id] ? <Chip label={venue.areas[id].name} onDelete={() => {}} /> : '')
						)}
					</div>
				</AccordionDetails>
				<Divider />
				<AccordionActions>
					<Button
						onClick={() => {
							setOpenAreasSettingsOnGroup(true);
						}}
						size="small"
						color="primary"
					>
						Módosít
					</Button>
					<Button
						onClick={() => {
							deleteGroup(groupkey);
						}}
						size="small"
						color="primary"
					>
						Torol
					</Button>
				</AccordionActions>
			</Accordion>
		</div>
	);
}
