import React, { useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { styled, useTheme } from '@mui/material/styles';

import DashboardIcon from '@mui/icons-material/Dashboard';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import MenuIcon from '@mui/icons-material/Menu';
import MuiAppBar from '@mui/material/AppBar';
import MuiDrawer from '@mui/material/Drawer';
import { HeaderProgressBarContainer } from './header-progress-bar/HeaderProgressBarContainer';

import {
	Menu,
	Toolbar,
	CssBaseline,
	IconButton,
	Typography,
	List,
	Divider,
	ListSubheader,
	ListItem,
	ListItemIcon,
	ListItemText,
	MenuItem,
	Select,
	Tooltip,
	Box
} from '@mui/material';

import SettingsIcon from '@mui/icons-material/SettingsApplications';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import AssessmentIcon from '@mui/icons-material/Assessment';
import TaskIcon from '@mui/icons-material/Task';
import BarChartIcon from '@mui/icons-material/BarChart';
import Assignment from '@mui/icons-material/Assignment';
import HomeWorkIcon from '@mui/icons-material/HomeWork';
import { AccountCircle } from '@mui/icons-material';

import {
	logout,
	getLoggedInUserEmail,
	getLoggedInUserRoles,
	getSelectedOrg,
	setSelectedOrg,
	getLoggedInUserOrgs
} from '../firebase/auth';

import * as routes from '../constants/routes';

const drawerWidth = 240;

const openedMixin = (theme) => ({
	width: drawerWidth,
	transition: theme.transitions.create('width', {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.enteringScreen
	}),
	overflowX: 'hidden'
});

const closedMixin = (theme) => ({
	transition: theme.transitions.create('width', {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen
	}),
	overflowX: 'hidden',
	width: `calc(${theme.spacing(7)} + 1px)`,
	[theme.breakpoints.up('sm')]: {
		width: `calc(${theme.spacing(9)} + 1px)`
	}
});

const DrawerHeader = styled('div')(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'flex-end',
	padding: theme.spacing(0, 1),
	// necessary for content to be below app bar
	...theme.mixins.toolbar
}));

const AppBar = styled(MuiAppBar, {
	shouldForwardProp: (prop) => prop !== 'open'
})(({ theme, open }) => ({
	zIndex: theme.zIndex.drawer + 1,
	transition: theme.transitions.create([ 'width', 'margin' ], {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen
	}),
	...(open && {
		marginLeft: drawerWidth,
		width: `calc(100% - ${drawerWidth}px)`,
		transition: theme.transitions.create([ 'width', 'margin' ], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen
		})
	})
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
	width: drawerWidth,
	flexShrink: 0,
	whiteSpace: 'nowrap',
	boxSizing: 'border-box',
	...(open && {
		...openedMixin(theme),
		'& .MuiDrawer-paper': openedMixin(theme)
	}),
	...(!open && {
		...closedMixin(theme),
		'& .MuiDrawer-paper': closedMixin(theme)
	})
}));

export default function NavigationBar({ children }) {
	const theme = useTheme();
	const [ open, setOpen ] = useState(false);
	const [ anchorEl, setAnchorEl ] = useState(false);
	const [ redirectToHome, setRedirectToHome ] = useState(false);
	const [ org, setOrg ] = React.useState(getSelectedOrg());
	const [ roles, setRoles ] = useState(getLoggedInUserRoles);

	const [ selectedIndex, setSelectedIndex ] = React.useState(0);

	const handleListItemClick = (event, index) => {
		setSelectedIndex(index);
	};

	const handleSelectChange = (event) => {
		setSelectedOrg(event.target.value);
		setOrg(event.target.value);
		refreshPage();
	};

	const refreshPage = () => {
		window.parent.location = window.parent.location.href;
	};

	const handleMenu = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleDrawerOpen = () => {
		setOpen(true);
	};

	const handleDrawerClose = () => {
		setOpen(false);
	};

	const handleLogOut = () => {
		handleClose();
		logout();
		setRedirectToHome(true);
	};

	return (
		<Box sx={{ display: 'flex' }}>
			<CssBaseline />
			<AppBar position="fixed" open={open}>
				{redirectToHome ? <Redirect to="/" /> : ''}
				<Toolbar>
					<IconButton
						style={{ color: 'white' }}
						aria-label="open drawer"
						onClick={handleDrawerOpen}
						edge="start"
						sx={{
							...(open && { display: 'none' })
						}}
					>
						<MenuIcon />
					</IconButton>
					<img src={require('./favicon.png')} height="42" width="42" marginleft="10" />

					<Typography variant="h5" color="common.white" noWrap sx={{ flexGrow: 1 }}>
						CleanSpect
					</Typography>
					{getLoggedInUserOrgs().length > 1 && (
						<Select
							value={org}
							name={org}
							style={{ color: 'white' }}
							size="small"
							onChange={handleSelectChange}
						>
							{getLoggedInUserOrgs().map((org) => (
								<MenuItem key={org} value={org}>
									{org}
								</MenuItem>
							))}
						</Select>
					)}
					<IconButton
						aria-owns={open ? 'menu-appbar' : undefined}
						aria-haspopup="true"
						onClick={handleMenu}
						style={{ color: 'white' }}
					>
						<AccountCircle />
					</IconButton>
					<Menu
						id="menu-appbar"
						anchorEl={anchorEl}
						anchorOrigin={{
							vertical: 'top',
							horizontal: 'right'
						}}
						transformOrigin={{
							vertical: 'top',
							horizontal: 'right'
						}}
						open={Boolean(anchorEl)}
						onClose={handleClose}
					>
						<MenuItem disabled="true">{getLoggedInUserEmail()}</MenuItem>
						<MenuItem onClick={handleClose}>Profil</MenuItem>
						<Divider />
						<MenuItem onClick={handleLogOut}>Kijelentkezés</MenuItem>
					</Menu>
				</Toolbar>
				<HeaderProgressBarContainer/>
				<HeaderProgressBarContainer/>

			</AppBar>
			<Drawer variant="permanent" open={open}>
				<DrawerHeader>
					<IconButton onClick={handleDrawerClose}>
						{theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
					</IconButton>
				</DrawerHeader>

				<Divider />
				<List
					subheader={
						<ListSubheader component="div" id="nested-list-subheader">
							Ügyfél
						</ListSubheader>
					}
				>
					<ListItem
						button
						selected={selectedIndex === 0}
						onClick={(event) => handleListItemClick(event, 0)}
						key="Home"
						component={Link}
						to={routes.HOME}
					>
						<Tooltip title="Asztal" disableHoverListener={open} disableInteractive>
							<ListItemIcon>
								<DashboardIcon />
							</ListItemIcon>
						</Tooltip>
						<ListItemText primary="Asztal" />
					</ListItem>
					<ListItem
							button
							selected={selectedIndex === 1}
							onClick={(event) => handleListItemClick(event, 1)}
							key="AdHocTasks"
							component={Link}
							to={routes.ADHOCTASKS}
						>
							<Tooltip title="Eseti feladatok" disableHoverListener={open} disableInteractive>
								<ListItemIcon>
									<TaskIcon />
								</ListItemIcon>
							</Tooltip>
							<ListItemText primary="Eseti feladatok" />
						</ListItem>
					<ListItem
							button
							selected={selectedIndex === 2}
							onClick={(event) => handleListItemClick(event, 2)}
							key="WorkLogs"
							component={Link}
							to={routes.WORKLOGS}
						>
							<Tooltip title="Napló" disableHoverListener={open} disableInteractive>
								<ListItemIcon>
									<PlaylistAddCheckIcon />
								</ListItemIcon>
							</Tooltip>
							<ListItemText primary="Napló" />
						</ListItem>
					<ListItem
						button
						selected={selectedIndex === 3}
						onClick={(event) => handleListItemClick(event, 3)}
						key="Reports"
						component={Link}
						to={routes.CUSTOMERREPORTS}
					>
						<Tooltip title="Riportok" disableHoverListener={open} disableInteractive>
							<ListItemIcon>
								<BarChartIcon />
							</ListItemIcon>
						</Tooltip>
						<ListItemText primary="Riportok" />
					</ListItem>
				</List>
				{roles['admin'] || roles['agent'] ? (
					<List
						subheader={
							<ListSubheader component="div" id="nested-list-subheader">
								Admin
							</ListSubheader>
						}
					>
						<ListItem
							button
							selected={selectedIndex === 3}
							onClick={(event) => handleListItemClick(event, 3)}
							key="Reports"
							component={Link}
							to={routes.REPORTS}
						>
							<Tooltip title="Riportok" disableHoverListener={open} disableInteractive>
								<ListItemIcon>
									<AssessmentIcon />
								</ListItemIcon>
							</Tooltip>
							<ListItemText primary="Riportok" />
						</ListItem>
						<ListItem
							button
							selected={selectedIndex === 4}
							onClick={(event) => handleListItemClick(event, 4)}
							key="Templates"
							component={Link}
							to={routes.TEMPLATES}
						>
							<Tooltip title="Űrlapok" disableHoverListener={open} disableInteractive>
								<ListItemIcon>
									<Assignment />
								</ListItemIcon>
							</Tooltip>
							<ListItemText primary="Űrlapok" />
						</ListItem>
						<ListItem
							button
							selected={selectedIndex === 5}
							onClick={(event) => handleListItemClick(event, 5)}
							key="Venues"
							component={Link}
							to={routes.VENUES}
						>
							<Tooltip title="Létesítmények" disableHoverListener={open} disableInteractive>
								<ListItemIcon>
									<HomeWorkIcon />
								</ListItemIcon>
							</Tooltip>
							<ListItemText primary="Létesítmények" />
						</ListItem>
						<ListItem
							button
							selected={selectedIndex === 6}
							onClick={(event) => handleListItemClick(event, 6)}
							key="MasterData"
							component={Link}
							to={routes.MASTERDATA}
						>
							<Tooltip title="Törzsadatok" disableHoverListener={open} disableInteractive>
								<ListItemIcon>
									<SettingsIcon />
								</ListItemIcon>
							</Tooltip>
							<ListItemText primary="Törzsadatok" />
						</ListItem>
					</List>
				) : (
					''
				)}
			</Drawer>
			<Box component="main" sx={{ flexGrow: 1 }}>
				<DrawerHeader />
				{children}
			</Box>
			{/* <main className={classes.content}>
				<div className={classes.toolbar} />
				{children}
			</main> */}
		</Box>
	);
}
