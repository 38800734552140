import React from 'react';
import PropTypes from 'prop-types';

import MD5 from 'md5';
import UUID from 'uuid/v4';

import { withStyles } from '@mui/styles';
import { useTheme } from '@mui/material/styles';
import {store} from '../../configure-store'

import { getVenues, saveVenue, saveTemplate, getTemplates, deleteTemplate } from '../../api/manager';
import { getSelectedOrg } from '../../firebase/auth';


import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Chip from '@mui/material/Chip';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import LoadingDialog from '../../utilities/LoadingDialog';

import Grid from '@mui/material/Grid';

import TemplateViewDialog from './TemplateViewDialog';
import { Button } from '@mui/material';

const Context = React.createContext();

const styles = (theme) => ({
	root: {
		backgroundColor: useTheme().palette.background.paper,
		overflow: 'auto'
	},
	paper: {
		padding: useTheme().spacing.unit,
		textAlign: 'center'
	},
	divider: {
		margin: `${useTheme().spacing(2)}px 0`
	},
	listSection: {
		backgroundColor: 'inherit'
	},
	ul: {
		backgroundColor: 'inherit',
		padding: 0
	},
	contentShift: {
		transition: useTheme().transitions.create('margin', {
			easing: useTheme().transitions.easing.easeOut,
			duration: useTheme().transitions.duration.enteringScreen
		}),
		marginleft: 0
	},
	grow: {
		flexGrow: 1
	},
	wrapper: {
		flexGrow: 1,
		overflow: 'hidden',
		padding: 10
	},
	colorPrimary: {
		backgroundColor: '#09AFEF'
	},
	barColorPrimary: {
		backgroundColor: '#b1deef'
	},
	button: {
		backgroundColor: '#09AFEF',
		color: '#FFFFFF'
	},
	chip: {
		margin: useTheme().spacing.unit
	}
});

class Templates extends React.Component {
	state = {
		openLoadingDialog: false,
		selectedIndex: -1,
		templates: [],
		venues: [],
		templateOnVenues: {},
		isOpenDialog: false,
		closeDialog: () => {
			this.setState({ isOpenDialog: false });
		},
		template: { form_test: [] },
		openDialogWithReport: (value) => {
			this.setState({ template: value, isOpenDialog: true });
		},
		saveTemplate: (value) => {
			this.saveTemplate(value);
		},
		templateToEdit: {},
		updateHint: (value, index) => {
			this.updateHint(value, index);
		},
		updateName: (value) => {
			this.updateName(value);
		},
		addNewFormItem: (value) => {
			this.addNewFormItem(value);
		},
		removeFormItem: (index) => {
			this.removeFormItem(index);
		},
		deleteTemplate: (value) => {
			this.deleteTemplate(value);
		}
	};

	componentDidMount() {
		getVenues(getSelectedOrg())
			.then((result) => {
				this.refreshTemplateOnVenues(result.data.results);
				this.setState({ venues: result.data.results });
				// console.log("templateOnVenues>>>"+tempTemplateOnVenues.toString());
				this.refreshTemplatesList();
			})
			.catch((error) => {
				console.log('Error happened in Templates while retriving venues' + error);
			});
	}

	refreshVenues() {
		getVenues(getSelectedOrg())
			.then((result) => {
				this.refreshTemplateOnVenues(result.data.results);
				this.setState({ venues: result.data.results });
				// console.log("templateOnVenues>>>"+tempTemplateOnVenues.toString());
			})
			.catch((error) => {
				console.log('Error happened in Templates while retriving venues' + error);
			});
	}

	refreshTemplateOnVenues(venues) {
		this.state.templateOnVenues = {};
		venues.forEach((venue) => {
			venue.templatesIdList.forEach((templateId) => {
				if (templateId in this.state.templateOnVenues) {
					this.state.templateOnVenues[templateId].push(venue);
				} else {
					this.state.templateOnVenues[templateId] = [ venue ];
				}
			});
		});
	}

	refreshTemplatesList() {
		store.dispatch({ type: 'TURN-ON-PROGRESS-BAR' })

		getTemplates(getSelectedOrg())
			.then((result) => {
				this.setState({ templates: result });
				store.dispatch({ type: 'TURN-OFF-PROGRESS-BAR' })
				// this.render();
			})
			.catch((error) => {
				store.dispatch({ type: 'TURN-OFF-PROGRESS-BAR' })
				console.log('Error happened in Templates while retriving templates' + error);
			});
	}

	updateVenue(venue) {
		saveVenue(getSelectedOrg(), venue)
			.then((result) => {
				this.setState({ showVenueChangeLoading: false });
			})
			.catch((error) => {
				console.log('Error happened in Templates while saving Template' + error);
			});
	}

	saveTemplate(template) {
		this.setState({ isOpenDialog: false, openLoadingDialog: true });
		if (!template.id) {
			template.id = UUID();
		}
		saveTemplate(getSelectedOrg(), template)
			.then((result) => {
				console.log('Save template result: ' + result.data.results);
				this.setState({ openLoadingDialog: false });
				this.refreshTemplatesList();
			})
			.catch((error) => {
				this.setState({ openLoadingDialog: false });
				console.log('Error happened in Templates while saving templates' + error);
			});
	}

	//TemplateToEdit methods
	updateHint(value, index) {
		var templateToEditTemp = this.state.templateToEdit;
		templateToEditTemp.form_test[index].hint = value;
		this.setState({ templateToEdit: templateToEditTemp });
	}
	updateName(value) {
		var templateToEditTemp = this.state.templateToEdit;
		templateToEditTemp.name = value;
		this.setState({ templateToEdit: templateToEditTemp });
	}
	addNewFormItem(item) {
		var tempTemplateToEdit = this.state.templateToEdit;
		tempTemplateToEdit.form_test.push(item);
		this.setState({ templateToEdit: tempTemplateToEdit });
	}
	removeFormItem(index) {
		var tempTemplateToEdit = this.state.templateToEdit;
		tempTemplateToEdit.form_test.splice(index, 1);
		this.setState({ templateToEdit: tempTemplateToEdit });
	}

	deleteTemplateOnVenue(venue, templateId) {
		const { venues } = this.state;
		const tempvenue = venues[venues.indexOf(venue)];
		var index = tempvenue.templatesIdList.indexOf(templateId);
		if (index > -1) {
			tempvenue.templatesIdList.splice(index, 1);
		}
		this.refreshTemplateOnVenues(this.state.venues);
		this.updateVenue(tempvenue);
	}

	deleteTemplate(template) {
		this.setState({ isOpenDialog: false, openLoadingDialog: true });
		const { venues } = this.state;
		venues.map((venue) => {
			var index = venue.templatesIdList.indexOf(template.id);
			if (index > -1) {
				venue.templatesIdList.splice(index, 1);
				this.updateVenue(venue);
			}
		});
		this.refreshTemplateOnVenues(this.state.venues);
		deleteTemplate(getSelectedOrg(), template.id)
			.then((result) => {
				console.log('Save template result: ' + result.data.results);
				this.setState({ openLoadingDialog: false });
				this.refreshTemplatesList();
			})
			.catch((error) => {
				this.setState({ openLoadingDialog: false });
				console.log('Error happened in Templates while deleting templates' + error);
			});
	}

	renderTemplatesTable() {
		if (this.state.templates.length < 1) {
			return '';
		} else {
			const { classes } = this.props;
			return (
				<Table className={classes.table} size="small">
					<colgroup>
						<col style={{ width: '1%' }} />
						<col style={{ width: '30%' }} />
						<col style={{ width: '30%' }} />
						<col style={{ width: '30%' }} />
					</colgroup>
					<TableHead>
						<TableRow>
							<TableCell padding="none" />
							<TableCell>Űrlap</TableCell>
							<TableCell align="left">Azonosító</TableCell>
							<TableCell align="left">Létesítmények</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{this.state.templates.map((template, index) => (
							<TableRow>
								<TableCell key={index} padding="checkbox" component="th" scope="row">
									<IconButton
										aria-label="Edit"
										className={classes.margin}
										onClick={(event) =>
											this.setState({
												isOpenDialog: true,
												templateToEdit: this.state.templates[index]
											})}
									>
										<EditIcon />
									</IconButton>
								</TableCell>
								<TableCell padding="checkbox" component="th" scope="row">
									{template.name}
								</TableCell>
								<TableCell align="left">{template.id}</TableCell>
								<TableCell align="left">
									{template.id in this.state.templateOnVenues ? (
										this.state.templateOnVenues[template.id].map((venue, index) => (
											<Chip label={venue.name} className={classes.chip} />
										))
									) : (
										''
									)}
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			);
		}
	}

	render() {
		const { classes } = this.props;

		return (
			<Context.Provider value={this.state}>
				{this.state.isOpenDialog ? (
					<TemplateViewDialog
						isOpenDialog={this.state.isOpenDialog}
						closeDialog={this.state.closeDialog}
						saveTemplate={this.state.saveTemplate}
						templateToEdit={this.state.templateToEdit}
						updateHint={this.state.updateHint}
						updateName={this.state.updateName}
						addNewFormItem={this.state.addNewFormItem}
						removeFormItem={this.state.removeFormItem}
						deleteTemplate={this.state.deleteTemplate}
					/>
				) : null}
				<LoadingDialog open={this.state.openLoadingDialog} title="Töltés folyamatban..." />
				<div>
					<div className={classes.wrapper}>
						<Grid
							justifyContent="space-between" // Add it here :)
							container
							spacing={24}
						>
							<Grid item>
								<h2>Űrlapok</h2>
							</Grid>
							<Grid item>
								<Button
									variant="contained"
									className={classes.button}
									onClick={(event) =>
										this.setState({
											isOpenDialog: true,
											templateToEdit: { name: '', form_test: [] }
										})}
								>
									Új Űrlap
								</Button>
							</Grid>
						</Grid>
						<div className={classes.wrapper}>
							<Grid item>
								<Paper className={classes.root}>
									{this.renderTemplatesTable()}
								</Paper>
							</Grid>
						</div>
					</div>
				</div>
			</Context.Provider>
		);
	}
}

Templates.propTypes = {
	classes: PropTypes.object.isRequired,
	theme: PropTypes.object.isRequired
};

export default withStyles(styles, { withTheme: true })(Templates);
export const TemplatesConsumer = Context.Consumer;
