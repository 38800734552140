import firebase from 'firebase/app';
import 'firebase/performance';
import 'firebase/auth';
// import firebaseFunctions from 'firebase/functions'

const stageConfig = {
	apiKey: 'AIzaSyDELuatOwM7vB_2U6uoVjfJ6-YNgJN1Ghc',
	authDomain: 'cleanspect-stage.firebaseapp.com',
	databaseURL: '',
	projectId: 'cleanspect-stage',
	storageBucket: '',
	messagingSenderId: '',
	messagingSenderId: '405480951926',
	appId: '1:405480951926:web:3bbf832c8dea0761d4cf57'
};

const prodConfig = {
	apiKey: 'AIzaSyCjudQ8zsDEMTQP55WhMgRTfzRlFBKQrvw',
	authDomain: 'cleanspect-prod.firebaseapp.com',
	databaseURL: '',
	projectId: 'cleanspect-prod',
	storageBucket: '',
	messagingSenderId: ''
};

if (!firebase.apps.length) {
	firebase.initializeApp(process.env.NODE_ENV === 'production' ? prodConfig : stageConfig);
}
const perf = firebase.performance();
const firebaseAuth = firebase.auth;
const googleProvider = new firebase.auth.GoogleAuthProvider();

export {
	// firebaseFunctions,
	firebaseAuth,
	googleProvider
};
