import React, { useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { getSelectedOrg } from '../../firebase/auth';
import { getSubmittedReportsByVenueForOrg_GroupedByAreagroups, getVenuesWithAreas, getCustomerVenues } from '../../api/manager';

import { getReportsDataArrayToChartJSSeries } from '../services/reportsService';

import TopNavigationBar from '../../utilities/TopNavigationBar';

import { Paper, Grid, LinearProgress } from '@mui/material';

import ReportsDataTableView from '../components/ReportsDataTableView';
import ReportsGoogleCharts from '../components/ReportsGoogleCharts';
import ReportsDateRangeFilter from '../components/ReportsDateRangeFilter';

import moment from 'moment';

const useStyles = makeStyles((theme) => ({
	root: {
		padding: 10
	},
	colorPrimary: {
		backgroundColor: '#09AFEF'
	},
	barColorPrimary: {
		backgroundColor: '#b1deef'
	},
	wrapper: {
		padding: 10
	}
}));

export default function AdminReportsOnVenue() {
	const classes = useStyles();
	const history = useHistory();
	const locationQuery = new URLSearchParams(useLocation().search);
	const location = useLocation();

	const [ venue, setVenue ] = React.useState({});
	const [ isLoading, setIsLoading ] = React.useState(true);
	const [ fromDate, setFromDate ] = React.useState(
		locationQuery.get('fromDate')
			? locationQuery.get('fromDate')
			: moment(new Date() - 24 * 60 * 60 * 1000 * 7).format('YYYY-MM-DD')
	);
	const [ toDate, setToDate ] = React.useState(
		locationQuery.get('toDate')
			? locationQuery.get('toDate')
			: moment(new Date()).add(1, 'day').format('YYYY-MM-DD')
	);
	const [ reports, setReports ] = React.useState({});
	const [ googleChartSeries, setGoogleChartSeries ] = React.useState({});

	const refreshReportsByVenue = (venue) => {
		setIsLoading(true);
		getSubmittedReportsByVenueForOrg_GroupedByAreagroups(
			getSelectedOrg(),
			venue.id,
			fromDate + ' 00:00:00',
			toDate + ' 23:59:59'
		)
			.then((result) => {
				setIsLoading(false);
				setReports(result.reports);
				console.log('reports for series: ' + getReportsDataArrayToChartJSSeries(result.reports));
				setGoogleChartSeries(getReportsDataArrayToChartJSSeries(result.reports));
				console.log('reports for series: ' + JSON.stringify(googleChartSeries));
			})
			.catch((error) => {
				console.log('getReportsByVenueId error: ' + error);
			});
	};

	useEffect(() => {
		if (venue.id != locationQuery.get('id')) {
			if (location.state) {
				setVenue(location.state.venue);
				refreshReportsByVenue(location.state.venue);
			} else {
				getCustomerVenues(getSelectedOrg())
					.then((result) => {
						const venueId = locationQuery.get('id');
						const tempVenue = result.data.venues[venueId];
						tempVenue.id = venueId;
						setVenue(tempVenue);
						refreshReportsByVenue(tempVenue);
					})
					.catch((error) => {
						console.log('Error happened in Venues.js while retriving Venues' + error);
						setIsLoading(false);
					});
			}
		}
	});

	const handleOpenReportClick = (rawReport) => {
		rawReport.area = venue.areas[rawReport.location];
		history.push({
			pathname: `${history.location.pathname}/report/${rawReport.id}`,
			search: `?id=${venue.id}`,
			state: { report: rawReport }
		});
	};
	const handleFilterButtonClickAction = () => {
		refreshReportsByVenue();
	};

	const setFromDateAction = (value) => {
		history.push({
			pathname: `${history.location.pathname}`,
			search: `?id=${venue.id}&fromDate=${value}&toDate=${toDate}`,
			state: { venue: venue }
		});
		setFromDate(value);
	};

	const setToDateAction = (value) => {
		history.push({
			pathname: `${history.location.pathname}`,
			search: `?id=${venue.id}&fromDate=${fromDate}&toDate=${value}`,
			state: { venue: venue }
		});
		setToDate(value);
	};

	return (
		<div>
			<TopNavigationBar title={venue.name} />
			{isLoading ? (
				<LinearProgress
					classes={{
						colorPrimary: classes.colorPrimary,
						barColorPrimary: classes.barColorPrimary
					}}
				/>
			) : (
				<div className={classes.wrapper}>
					<Grid justifyContent="space-between" container spacing={24}>
						<Grid item>
							<h2>Riportok</h2>
						</Grid>
						<Grid item>
							<ReportsDateRangeFilter
								fromDate={fromDate}
								toDate={toDate}
								setFromDate={setFromDateAction}
								setToDate={setToDateAction}
								selectFilterAction={handleFilterButtonClickAction}
							/>
						</Grid>
					</Grid>
					<br />
					<Paper className={classes.root}>
						<h3>Grafikon</h3>
						<ReportsGoogleCharts data={googleChartSeries} />
					</Paper>
					<br />
					<Paper className={classes.root}>
						<h3>Táblázat</h3>
						<ReportsDataTableView data={reports.combined} venue={venue} selectRow={handleOpenReportClick} />
					</Paper>
				</div>
			)}
		</div>
	);
}
